import axios
	from "axios"
import store
	from "./../globals"
import _
	from "lodash"

import {removeRecords}
	from "./../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/paymentMethods";

class PaymentMethodsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async getRecords(accountId?: number)
	{
		let params = [];

		if(accountId)
		{
			params.push("accountId=" + accountId)
		}

		return axios.get
		(
			RESOURCE_URL + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async getPaymentMethodsByCountry(countryId?: number)
	{
		return axios.get
		(
			RESOURCE_URL + "/_country/" + countryId,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		).then
		(
			response=>
			{
				return response;
			}
		)
	}

	saveRecord(entity : any)
	{
		const saveFunction = entity.id == null ? this.registerRecord : this.updateRecord

		return saveFunction
		(
			{
				name                          : entity.name + "",
				boolPaymentDateUnmodifiable   : +entity.bool_payment_date_unmodifiable,
				boolPaymentReferenceNullified : +entity.bool_payment_reference_nullified,
				boolPaymentAttachmentRequire  : +entity.bool_payment_attachment_require,
				accountIds                    : entity.accountIds
			},
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			entity.id
		)
	}

	async registerRecord(registry : any, options : any)
	{
		return axios.post(RESOURCE_URL, registry, options)
	}

	async updateRecord(registry : any, options : any, id : number)
	{
		return axios.put(RESOURCE_URL + "/" + id, registry, options)
	}

	async removeRecords(recordIds : any)
	{
		return removeRecords(RESOURCE_URL, recordIds)
	}
}

export default new PaymentMethodsService()