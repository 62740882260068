import axios
	from "axios"
import store
	from "./../../globals"
import {isNotEmpty, toAcceptableDate}
	from "./../../common"
import _
	from "lodash"

const API_URL = process.env.REACT_APP_API_URL + "/admin/campus/";

class CalendarService
{
	sessionToken = store.getState().sessionToken.value
	campusId     = store.getState().currentUserCampusId.value

	constructor()
	{
		store.subscribe
		(
			()=>
			{
				this.sessionToken = store.getState().sessionToken.value
				this.campusId     = store.getState().currentUserCampusId.value
			}
		)
	}

	async getView(intervalCode : string, date? : any, search? : any, rangeFilterRef? : any)
	{
		let params = [];

		if(search && rangeFilterRef)
		{
			let courseIds    : any = []
			let levelIds     : any = []
			let buildingIds  : any = []
			let classroomIds : any = []

			if(isNotEmpty(search.mentors))
			{
				params.push("mentorIds=" + _.map(search.mentors, "id").join(","))
			}

			if(search.period && search.period.id)
			{
				params.push("periodId=" + search.period.id)
			}

			if(search.onlyPendingTuitions)
			{
				params.push("pendingTuitions")
			}

			if(search.onlyPendingKits)
			{
				params.push("pendingKits")
			}

			if(isNotEmpty(search.courses))
			{
				courseIds = search.courses.filter
				(
					(course : any)=>
					{
						let hasLevels : boolean = course.levels.length > 0

						if(hasLevels)
						{
							levelIds = [...levelIds, _.map(course.levels, "id")]
						}

						return !hasLevels
					}
				).map((course : any)=>(course.id))

				if(courseIds.length)
				{
					params.push("courseIds=" + courseIds.join(","))
				}

				if(levelIds.length)
				{
					params.push("levelIds=" + levelIds.join(","))
				}
			}

			if(isNotEmpty(search.buildings))
			{
				buildingIds = search.buildings.filter
				(
					(building : any)=>
					{
						let hasClassrooms : boolean = building.classrooms.length > 0

						if(hasClassrooms)
						{
							classroomIds = [...classroomIds, _.map(building.classrooms, "id")]
						}

						return !hasClassrooms
					}
				).map((building : any)=>(building.id))

				if(buildingIds.length > 0)
				{
					params.push("buildingIds=" + buildingIds.join(","))
				}

				if(classroomIds.length > 0)
				{
					params.push("classroomIds=" + classroomIds.join(","))
				}
			}

			Object.keys(rangeFilterRef).map
			(
				(key : string)=>
				{
					const andMore : number = rangeFilterRef[key].limit + 1
					const handleA : number = search[key][0]
					const handleB : number = search[key][1]

					if(Math.abs(handleA - handleB) != andMore)
					{
						if(handleA == handleB)
						{
							params.push(key + "=" + ((handleA == andMore ? rangeFilterRef[key].limit + "^" : handleA)))
						}
						else
						{
							let arrangedData : any = [handleA, handleB].sort((a : number, b : number)=>(a - b))

							params.push
							(
								key + "=" + 
								(
									arrangedData[0] +
										(arrangedData[1] == andMore ? "^" : "," + arrangedData[1])
								)
							)
						}
					}
				}
			)
		}

		return axios.get
		(
			API_URL + this.campusId + "/calendar/" + intervalCode +
			(
				date
					?
				(date == "today" ? "/" + date : "/" + toAcceptableDate(date))
					: ""
			) +
			(params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response
			}
		)
	}

	async getDailyView(date? : any, search? : any, rangeFilterRef? : any)
	{
		return this.getView("dailyView", date, search, rangeFilterRef)
	}

	async getWeeklyView(date? : any, search? : any, rangeFilterRef? : any)
	{
		return this.getView("weeklyView", date, search, rangeFilterRef)
	}

	async getMonthlyView(date? : any, search? : any, rangeFilterRef? : any)
	{
		return this.getView("monthlyView", date, search, rangeFilterRef)
	}
}

export default new CalendarService()