import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { default as SelectComponent } from "react-select";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import Multiselect from "multiselect-react-dropdown";
import { FILTER_TYPES, INPUT_TYPES } from "../../constants";
import { FilterType } from "../../types";
import "./style.css";
import AmountRangeComponent from "../amountRange/amountRange";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputColumnFilterComponent from "../inputColumnFilter/inputColumnFilter";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MultiValueInput from "../multiValueInput/multiValueInput";

registerLocale("es", es); // Registra el idioma español
setDefaultLocale("es"); // Establece el español como idioma predeterminado

export interface FilterOption {
	id: string;
	label: string;
	value: string;
}

export interface Column {
	/**
	 * Id to identify the Column
	 */
	id: string;
	/**
	 * Label to show in the Column
	 */
	header: string;
	/**
	 * Object Property to access data
	 */
	accessor: string;
	/**
	 * Optional render ?, can be HTMLElements such as Buttons, Checkboxes, etc.
	 */
	render?: (data: any) => React.ReactNode;
	/**
	 * Optional render ?, can be HTMLElements such as Buttons, Checkboxes, etc.
	 */
	renderSubRow?: (data: any) => React.ReactNode;
	/**
	 * Optional function to apply custom styles
	 */
	overrideCellStyle?: (data: any) => React.CSSProperties;
	/**
	 * Optional function to apply custom styles in table headers
	 */
	overrideHeaderStyle?: (data: any) => React.CSSProperties;
	/**
	 * Type of filter: "dropdown", "input", etc.
	 */
	filterType?: FilterType;
	/**
	 * Determine if filter: is active, if is active show controls otherwise hide it.
	 */
	filterEnabled?: boolean;
	/**
	 * Array of filter options for dropdowns
	 */
	filterOptions?: FilterOption[];
	/**
	 * Default options for filters
	 */
	defaultFilterValues?: FilterOption[];
	/**
	 * Manage date picker controller
	 */
	datePickerState?: {
		date: Date | null;
		setDate: (date: Date | null) => void
	};
	/**
	 * Manage dateRange controllers
	 */
	dateRangeState?: {
		start: Date | null;
		end: Date | null;
		setStart: (date: Date | null) => void;
		setEnd: (date: Date | null) => void
	};
	/**
	 * Manage quantity range controller
	 */
	quantityRangeState?: {
		option: string;
		setOption: (option: string) => void;
		quantity: number;
		setQuantity: (quantity: number) => void;
	};
}

export interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
	/**
	 * Columns to show in the Table
	 */
	columns: Column[];
	/**
	 * Data to show in the Table
	 */
	data: any[];
	/**
	 * Optional function for row click event
	 */
	onRowClick?: (data: any) => void;
	/**
	 * Filters to apply in the Table columns
	 */
	filters?: any;
	/**
	 * Optional function search data
	 */
	onFilterChange?: (columnId: string, value: any) => void;
	/**
	 * Optional flag to clear filters
	 */
	onClearFilters?: () => void;
}

/**
 * Common Table component for user interaction
 */
const CommonTable: React.FC<TableProps> = ({
	className,
	columns,
	data,
	onRowClick,
	filters,
	onFilterChange = (columnId: string, value: any) => { },
	onClearFilters = () => { }
}) => {

	const tableClass = classNames("tableComponent", className);
	const [filterStates, setFilterStates] = useState(
		columns.reduce((acc: any, column: Column) => {
			acc[column.id] = column.filterEnabled || false;
			return acc;
		}, {})
	);

	const isAnyFilterActive = () => {
		return Object.values(filterStates).some(value => value === true);
	};

	const handleClearFilterColumn = (columnId: string) => {
		setFilterStates((prevStates: any) => ({
			...prevStates,
			[columnId]: !prevStates[columnId],
		}));
		onFilterChange(columnId, null);
	};

	const handleClearAllFilterColumn = () => {
		setFilterStates((prevStates: any) => {
			// new object with all filters in status false
			const newStates = Object.keys(prevStates).reduce((acc: any, columnId: any) => {
				acc[columnId] = false;
				return acc;
			}, {});

			return newStates; // return new object
		});
		onClearFilters();
	};

	const renderFilterType = (
		filterType: FilterType,
		column: Column,
		filters: any,
		onFilterChange: (columnId: string, value: any) => void
	) => {
		switch (filterType) {
			case FILTER_TYPES.INPUT_TEXT: // Input TEXT filter
				const handleInputFilterChange = (value: string) => {
					onFilterChange(column.id, value);
				};
				return (
					<div style={{
						display: "flex",
						gap: "10px",
						width: "100%",
						justifyContent: "center",
						alignItems: "center"
					}}>
						<InputColumnFilterComponent
							onFilterChange={(value: string) => handleInputFilterChange(value)}
							type={INPUT_TYPES.INPUT_TEXT}
							placeholder={column.header}
						/>
					</div>
				);
			case FILTER_TYPES.INPUT_NUMBER: // Input NUMBER filter
				// return (<MultiValueInput />);
				const handleInputNumberFilterChange = (value: string) => {
					onFilterChange(column.id, value);
				};
				return (
					<div style={{
						display: "flex",
						gap: "10px",
						width: "100%",
						justifyContent: "center",
						alignItems: "center"
					}}>
						<InputColumnFilterComponent
							onFilterChange={(value: string) => handleInputNumberFilterChange(value)}
							type={INPUT_TYPES.INPUT_NUMBER}
							placeholder={column.header}
						/>
					</div>
				);
			case FILTER_TYPES.DROPDOWN: // Dropdown filter
				return (
					column.filterOptions && (
						<SelectComponent
							id={column.id}
							options={column.filterOptions}
							getOptionLabel={(option) => option.label}
							getOptionValue={(option) => option.value}
							onChange={(selectedOption) => {
								const value = selectedOption ? selectedOption.value : "";
								onFilterChange(column.id, value);
							}}
							styles={{
								menu: (provided) => ({
									...provided,
									width: "100%", // Asegura que el contenedor del select sea 100%
								}),
								container: (provided) => ({
									...provided,
									width: "100%", // Asegura que el contenedor del select sea 100%
								}),
								control: (provided) => ({
									...provided,
									width: "100%", // Asegura que el control del select sea 100%
								}),
								option: (provided, state) => ({
									...provided,
									display: "flex", // Para permitir el uso de flexbox
									alignItems: "center",
									justifyContent: "center", // Centrar el contenido
									textAlign: "center" // Centrar el texto
								}),
								singleValue: (provided) => ({
									...provided,
									textAlign: "center", // Centrar el valor seleccionado
								}),
							}}
							placeholder={`Select ${column.header}`}
						/>
					)
				);
			case FILTER_TYPES.MULTISELECT: // Input text multiselect
				return (
					column.filterOptions && (
						<Multiselect
							options={column.filterOptions}
							displayValue="label"
							emptyRecordMsg="No hay más opciones"
							onSelect={(selectedOptions) => {
								const selectedValues = selectedOptions.map((option: any) => option.value);
								onFilterChange(column.id, selectedValues);
							}}
							onRemove={(selectedOptions) => {
								const selectedValues = selectedOptions.map((option: any) => option.value);
								onFilterChange(column.id, selectedValues);
							}}
							selectedValues={
								filters[column.id]?.map((value: any) =>
									column.filterOptions?.find(option => option.value === value)
								) || (column.defaultFilterValues?.map((item: any) =>
									column.filterOptions?.find(option => option.value === item.value)
								).filter(Boolean) || [])
							}
						/>
					)
				);
			case FILTER_TYPES.DATE_PICKER: // Date picker filter
				return (
					<DatePicker
						selected={column.datePickerState?.date || null}
						onChange={(date) => {
							column.datePickerState?.setDate(date);
							onFilterChange(column.id, date ? date.toISOString().split("T")[0] : null);
						}}
						dateFormat="dd/MM/yyyy"
						placeholderText={`Select ${column.header}`}
						showYearDropdown // Muestra un desplegable para seleccionar el año
						showMonthDropdown // Muestra un desplegable para seleccionar el mes
						dropdownMode="select" // Usa un modo desplegable para la selección
						locale="es" // Establece el idioma aquí
					/>
				);
			case FILTER_TYPES.DATE_RANGE: // Date range filter
				return (
					<div style={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						marginBottom: "5px"
					}}>
						<DatePicker
							className="filter-datepicker"
							selected={column.dateRangeState?.start || null}
							onChange={(date) => {
								column.dateRangeState?.setStart(date);
								const endDate = column.dateRangeState?.end ? column.dateRangeState.end.toISOString().split("T")[0] : null;

								if (!endDate || (date && date <= (column.dateRangeState?.end || new Date()))) {
									onFilterChange(column.id, { start: date ? date.toISOString().split("T")[0] : null, end: endDate });
								}
							}}
							dateFormat="dd/MM/yyyy"
							placeholderText={`Inicio`}
							showYearDropdown
							showMonthDropdown
							dropdownMode="select"
							locale="es"
						/>
						<DatePicker
							className="filter-datepicker"
							selected={column.dateRangeState?.end || null}
							onChange={(date) => {
								column.dateRangeState?.setEnd(date);
								const startDate = column.dateRangeState?.start ? column.dateRangeState.start.toISOString().split("T")[0] : null;

								if (!startDate || (date && date >= (column.dateRangeState?.start || new Date()))) {
									onFilterChange(column.id, { start: startDate, end: date ? date.toISOString().split("T")[0] : null });
								}
							}}
							dateFormat="dd/MM/yyyy"
							placeholderText={`Fin`}
							showYearDropdown
							showMonthDropdown
							dropdownMode="select"
							locale="es"
							disabled={!column.dateRangeState?.start} // Deshabilita si no hay fecha de inicio
						/>
					</div>
				);
			case FILTER_TYPES.AMOUNT_RANGE:
				const selectedOption = column.quantityRangeState?.option || ""; // Cambiado a una sola opción
				const quantity = column.quantityRangeState?.quantity || 0;

				const handleFilterChange = () => {
					if (selectedOption) {
						onFilterChange(column.id, { option: selectedOption, quantity });
					}
				};

				return (
					<AmountRangeComponent
						selectedOption={selectedOption} // Se pasa solo una opción
						onOptionChange={(value: string) => {
							column.quantityRangeState?.setOption(value);
						}}
						quantity={quantity}
						filterOptions={column.filterOptions}
						onQuantityChange={(value: number) => {
							column.quantityRangeState?.setQuantity(value);
						}}
						onFilterChange={handleFilterChange}
					/>
				);
			default: // No filter
				return null;
		}
	}

	return (
		<div className="table-wrapper">
			<table className={tableClass}>
				<thead>
					{isAnyFilterActive() ?
						<OverlayTrigger
							overlay={
								<Tooltip id="clear-all-filter">
									Limpiar todos los filtros
								</Tooltip>
							}>
							<button
								onClick={handleClearAllFilterColumn}
								style={{
									color: "#8F91DA",
									padding: "10px",
									background: "#F3F8FF",
									height: "44px"
								}}>
								<FontAwesomeIcon
									icon={solid("times")}
									size="xl"
								/>
							</button>
						</OverlayTrigger>
						: <React.Fragment />
					}
					<tr key="commonRow"
						style={{ color: "#8F91D8" }}>
						{columns.map((column, index) => {
							const headerStyle = column.overrideHeaderStyle ? column.overrideHeaderStyle(data) : {};
							return (
								<th
									key={index}
									style={{
										borderBottom: column.header === "" ? 0 : "1px solid #C7C7C7",
										...headerStyle
									}}>
									<div style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										width: "100%"
									}}>
										<div style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											gap: "10px",
											width: "100%"
										}}>
											{column.filterType &&
												<OverlayTrigger
													overlay={
														<Tooltip id={`${column.id}-filter`}>
															{filterStates[column.id]
																? "Limpiar"
																: `Filtrar por ${column.header}`
															}
														</Tooltip>
													}>
													<button
														onClick={() => handleClearFilterColumn(column.id)}
														style={{
															color: "#8F91DA",
															padding: "10px",
															background: "#F3F8FF",
															height: "44px"
														}}>
														<FontAwesomeIcon
															icon={filterStates[column.id] ? solid("times") : solid("filter")}
														/>
													</button>
												</OverlayTrigger>
											}
											{column.header}
										</div>
										{filterStates[column.id] && renderFilterType(
											column.filterType ?? 0,
											column,
											filters,
											onFilterChange
										)}
									</div>
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{data && data.length > 0
						? data.map((rowData, rowIndex) => (
							<React.Fragment key={`rowFragment${rowIndex}`}>
								<tr
									key={`commonTableRow${rowIndex}`}
									onClick={() => onRowClick && onRowClick(rowData)}
									style={{ cursor: onRowClick ? "pointer" : "default" }}
								>
									{columns.map((column, colIndex) => {
										const cellStyle = column.overrideCellStyle ? column.overrideCellStyle(rowData) : {};
										return (
											<td
												style={{ textAlign: "left", whiteSpace: "nowrap", ...cellStyle }}
												key={colIndex}
											>
												{column.render ? column.render(rowData) : rowData[column.accessor]}
											</td>
										);
									})}
								</tr>
								{/* Render if column has renderSubRow property*/}
								{columns.map((column, colIndex) =>
									column.renderSubRow && column.renderSubRow(rowData) ? (
										<tr key={`subRow${rowIndex}-${colIndex}`}>
											<td colSpan={columns.length} style={{ padding: 0, border: 0 }}>
												<div style={{ padding: "8px" }}>
													{column.renderSubRow(rowData)}
												</div>
											</td>
										</tr>
									) : null
								)}
							</React.Fragment>
						))
						: <React.Fragment />
					}
				</tbody>
			</table>
			{data && data.length === 0
				? <div
					style={{
						width: "100%",
						padding: "10px 10px 0px 10px",
						textAlign: "center",
						boxSizing: "border-box"
					}}>
					<h3 style={{
						width: "100%",
						padding: "10px 0",
						textAlign: "center"
					}}>
						No se encontraron registros.
					</h3>
				</div>
				: <React.Fragment />
			}
		</div>
	);
};

export default CommonTable;
