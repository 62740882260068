import React,
{
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import Constants,
{
    articleType
} from "../../constants";
import CommonTable,
{
    Column
} from "../commonTable";
import Card from "../card";
import { RootState } from "../../globals";
import { styles } from "./style";

export interface CheckoutArticleProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Columns to show in the Table
     */
    columns: Column[];
    /**
     * Data to show in the Table
     */
    data: any;
}

const CheckoutArticleItemComponent: React.FC<CheckoutArticleProps> = (props: CheckoutArticleProps) => {
    const { columns, data } = props;
    const [kits, setkits] = useState<any[]>([]);
    const [others, setOthers] = useState<any[]>([]);

    useEffect(() => {
        if (data.length > 0) {
            setkits(data.filter((item: any) => item.typeId === articleType.STANDARD_KIT));
            setOthers(data.filter((item: any) => item.typeId !== articleType.STANDARD_KIT));
        }
    }, [data]);

    return (data ?
        <div>
            {kits.length > 0 ?
                <div>
                    <section style={{ ...styles.header }}>
                        <div style={{ ...styles.subtitleContainer }}>
                            <b style={{ color: "#5154AE" }}>
                                {Constants.Common.kits}
                            </b>
                        </div>
                    </section>
                    <CommonTable
                        className="not-hover"
                        columns={columns}
                        data={kits}
                    />
                </div>
                : <React.Fragment></React.Fragment>
            }
            {others.length > 0 ?
                <div>
                    <section style={{ ...styles.header }}>
                        <div style={{ ...styles.subtitleContainer }}>
                            <b style={{
                                paddingBottom: "15px",
                                color: "#5154AE",
                                textTransform: "uppercase",
                                fontWeight: 900
                            }}>
                                {Constants.Common.others}
                            </b>
                        </div>
                    </section>
                    <CommonTable
                        className="not-hover"
                        columns={columns}
                        data={others}
                    />
                </div>
                : <React.Fragment></React.Fragment>
            }
        </div>
        : <React.Fragment></React.Fragment>
    )
}

export default CheckoutArticleItemComponent;
