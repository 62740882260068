import { CSSProperties } from 'react';
import Constants
    from "./../../constants"

const container: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}

const truncatedText: CSSProperties = {
    width: "100%"
}

const chevron: CSSProperties = {
    color: Constants.Styles.text.colors.darkBlue,
    cursor: "pointer",
    marginLeft: "5px"
}

export const styles =
{
    container,
    truncatedText,
    chevron
}