import { axiosInstance } from "./axiosInstance";

export class EnrollmentService {

    async updateStudentEnrollment(
        campusId: number,
        studentId: number,
        enrollmentId: number,
        payload: any
    ) {
        const URL = `/admin/
            campus/${campusId}/
            students/${studentId}/
            enrollments/${enrollmentId}/_update`.replace(/\s+/g, '');
        const response = await axiosInstance.put(URL, payload);
        return response;
    }
}