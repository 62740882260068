import {useState, useRef, useReducer, useEffect, FormEvent}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import Multiselect
	from "multiselect-react-dropdown"
import {useDispatch, useSelector}
	from "react-redux"
import {Popover}
	from "react-tiny-popover"
import _
	from "lodash"

import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch
}
	from "./../globals"
import {UIState, isEmpty, validateField}
	from "./../common"
import {tunning}
	from "./../constants"
import CustomIcon
	from "./customIcon"
import {DataDisplayModal}
	from "./sectionContainer"
import UnderlineHeading
	from "./underlineHeading"
import ClassroomsService
	from "./../services/campus/classrooms.service"

import ".//dropdown/style.css"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action   = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled":
			return {...state, isButtonDisabled : action.payload}
		break;
	}
}

const ClassroomPrompt = (props : any)=>
{
	const {selectedClassroom, allowed, closeFn, buildings, classroomStatuses, categories, resetFn} = props

	const dispatch : any                                  = useDispatch()
	const [state, trigger]                                = useReducer(reducer, initialState)
	const idleLocked        : boolean                     = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null               = useSelector((state : RootState) => state.sessionToken.value)
	const campusRef         : any                         = useSelector((state : RootState) => state.campusRef.value)
	const currentUserCampus : any                         = useSelector((state : RootState) => state.currentUserCampus.value)
	const [editableClassroom, setEditableClassroom]       = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                   = useState<boolean>(false)
	const [saveError, setSaveError]                       = useState<any>(null)
	const [UIStatus, setUIStatus]                         = useState<number>(UIState.NORMAL)
	const [noCategoriesProvided, setNoCategoriesProvided] = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]             = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]           = useState<boolean>(false)
	let   formRef                                         = useRef<HTMLFormElement>(null)
	let   nameRef                                         = useRef<HTMLInputElement>(null)
	let   descriptionRef                                  = useRef<HTMLTextAreaElement>(null)
	let   buildingSelRef                                  = useRef<HTMLSelectElement>(null)
	let   statusSelRef                                    = useRef<HTMLSelectElement>(null)
	let   studLimitRef                                    = useRef<HTMLInputElement>(null)

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableClassroom({...editableClassroom, [e.target.name] : e.target.value})
		}
	}

	const saveRecord = ()=>
	{
		if(formRef && formRef.current)
		{
			if(!formRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					formRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				if(editableClassroom && editableClassroom.categories.length < 1)
				{
					if(!unfilledFields && !noCategoriesProvided)
					{
						setUnfilledFields(true)
						setNoCategoriesProvided(true)

						setTimeout
						(
							() =>
							{
								setUnfilledFields(false)
								setNoCategoriesProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(saveError != null)
					{
						setSaveError(null)
					}

					setUIStatus(UIState.LOCKED)
					dispatch(enableIdleLockSwitch())

					ClassroomsService.saveClassroom(campusRef[currentUserCampus].id, editableClassroom).then
					(
						()=>
						{
							setSaveSuccess(true)
							setUIStatus(UIState.SUCCESS)

							setTimeout
							(
								() => resetFn(),
								tunning.MODAL_DISMISS_DELAY
							)
						},
						(error : any)=>
						{
							console.log(error)
							setUIStatus(UIState.ERROR)
							dispatch(disableIdleLockSwitch())
							setSaveError
							(
								error.response.status == 409
										?
									"Hay conflictos en la información proporcionada (nombre). " +
										"Revise que los valores sean únicos en comparacióncon los registros existentes"
										:
									"La información no pudo ser guardada"
							)
						}
					)
				}
			}
		}
	}

	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			if(selectedClassroom && selectedClassroom.id == null)
			{
				setEditableClassroom(selectedClassroom)
			}
		},
		[selectedClassroom]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	return <DataDisplayModal uneditFn={() => setEditableClassroom(null)} promptCloseFn={closeFn}
	  saveSuccess={saveSuccess} unchangedRecord={unchangedRecord} form={formRef} editableRecord={editableClassroom}
	  saveDataFn={saveRecord} processingError={saveError} UIStatus={UIStatus} saveLock={state.isButtonDisabled}
	  headIcon={<FontAwesomeIcon icon={solid("chalkboard")} />} unfilledFields={unfilledFields} entityName="Aula"
	  allowedUpdate={allowed && allowed.update} dataEditInit=
	  {
		()=>
		{
			setEditableClassroom
			({
				...selectedClassroom, campus_id : selectedClassroom.building.campus_id,
				building_id                     : selectedClassroom.building_id,
			})
		}
	  }
	><form ref={formRef} className="form" onSubmit=
	  {
		(event : FormEvent)=>
		{
			event.preventDefault()
			saveRecord()
		}
	  }
	><div className="container">
		<div className="row">
			<div className="col-md-6"><div className="inputContainer">
				<label htmlFor="name">
					{editableClassroom == null ? "" : "*"}
					Nombre
				</label>
				{
					editableClassroom == null
						?
					<div className="roView">
						<br />
						{selectedClassroom.name}
					</div>
						:
					<input value={editableClassroom.name} placeholder="Nombre" disabled={idleLocked} maxLength={50}
					  onChange={handleRegistryChange} style={{width : "100%"}} ref={nameRef}
					  onBlur={validateField} id="name" type="text" name="name"
					  onFocus={validateField} required
					/>
				}
			</div></div>
			<div className="col-md-6"><div className="selectContainer">
				<label htmlFor="campus_id">
					Edificio
				</label>
				{
					editableClassroom == null || buildings == null
						?
					<div className="roView">
						<br />
						{(selectedClassroom.building && selectedClassroom.building.name) || "-"}
					</div>
						:
					<>
						<select onChange={handleRegistryChange} id="building_id" name="building_id"
						  value={editableClassroom.building_id} disabled={idleLocked} className="btn btn-primary text-start"
						  style={{height : "30px"}} ref={buildingSelRef}
						>{
							buildings.map
								(
									(record : any, i : any)=><option key={i} value={record.id}>
										{record.name}
									</option>
								)
						}</select>
						<div className="iconRight" style={{pointerEvents : "none"}}>
							<CustomIcon name={"rowDown"} />
						</div>
					</>
				}
			</div></div>
			<div className="col-md-6"><div className="selectContainer">
				<label htmlFor="status_id">
					Estatus
				</label>
				{
					editableClassroom == null || classroomStatuses == null
						?
					<div className="roView">
						<br />
						{selectedClassroom.status?.name}
					</div>
						:
					<>
						<select onChange={handleRegistryChange} id="status_id" disabled={idleLocked}
						  className="btn btn-primary text-start" value={editableClassroom.status_id} style={{height : "30px"}}
						  name="status_id" ref={statusSelRef}
						>{
							classroomStatuses.map
							(
								(record : any, i : any) => <option key={i} value={record.id}>
									{record.name}
								</option>
							)
						}</select>
						<div className="iconRight" style={{pointerEvents : "none"}}>
							<CustomIcon name={"rowDown"} />
						</div>
					</>
				}
			</div></div>
			<div className="col-md-6"><div className="inputContainer">
				<label htmlFor="students_limit">
					{editableClassroom == null ? "" : "*"}
					Cupo máximo de estudiantes
				</label>
				{
					editableClassroom == null
						?
					<div className="roView">
						<br />
						{selectedClassroom.students_limit}
					</div>
						:
					<input value={editableClassroom.students_limit} placeholder="Cupo máximo" ref={studLimitRef} min="0"
					  onChange={handleRegistryChange} name="students_limit" id="students_limit"
					  onFocus={validateField} disabled={idleLocked} type="number"
					  onBlur={validateField} style={{width : "100%"}} step="1"
					  required
					/>
				}
			</div></div>
			<div className="col-md-6"><div className="inputContainer"><div className="session_min_hours">
				<label htmlFor="name">
					{editableClassroom == null ? "" : "*"}
					Horas mínimas / sesión
				</label>
				{
					editableClassroom == null
						?
					<div className="roView">
						<br />
						{selectedClassroom.session_min_hours}
					</div>
						:
					<input value={editableClassroom.session_min_hours} style={{width : "100%"}} id="session_min_hours"
					  onChange={handleRegistryChange} step="1" min="1" max="15" disabled={idleLocked}
					  onFocus={validateField} name="session_min_hours" type="number"
					  onBlur={validateField} placeholder="Hrs. min. / sesión"
					  ref={studLimitRef} required
					/>
				}
			</div></div></div>
		</div>
		<br />
		<div className="card card-default" style={{padding : "15px"}}>
			<div className="underlineHeadingContainer" style={{marginBottom : "unset"}}><Popover positions={["bottom"]}
			   isOpen={noCategoriesProvided} content=
			  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
				<div className="requirementAlert">
					<FontAwesomeIcon icon={solid("exclamation")}/>
				</div>
				&nbsp;
				Requerido: Elija al menos una opción
			  </div>}
			><div>
				{editableClassroom == null ? "" : "*"}
				Categorías
			</div></Popover></div>
			{
				editableClassroom == null || categories == null
					?
				<div style={{textAlign : "left", display : "flex", flexFlow : "wrap"}}>{
					(selectedClassroom.categories.length < 1)
						?
					<>
						&emsp;
						&lt;Ninguna&gt;
					</>
						:
					selectedClassroom.categories.map
					(
						(record : any, index: number) => <div style={{display : "inline-block", padding : "5px", margin : "5px"}}
						  key={"cr_" + record.id} className="badge rounded-pill bg-primary prompt"
						>
							{record.name}
						</div>
					)
				}</div>
					:
				<>
					<label htmlFor="categories" style={{position : "unset"}}>
						Actualice
					</label>
					<Multiselect options={categories} selectedValues={editableClassroom.categories} placeholder="Seleccione"
					  onSelect={(categories) => {setEditableClassroom({...editableClassroom, categories : categories})}}
					  onRemove={(categories)=>{setEditableClassroom({...editableClassroom, categories : categories})}}
					  displayValue="name"emptyRecordMsg="No hay más opciones"
					/>
				</>
			}
		</div>
		{(editableClassroom == null && isEmpty(selectedClassroom.description)) ? "" : <>
			<UnderlineHeading name={"Descripción"} />
			<div style={{paddingTop : "3px"}}>{
				editableClassroom == null ? selectedClassroom.description : <textarea className="notesField form-control"
				  onChange={handleRegistryChange} disabled={idleLocked} name="description" maxLength={255} id="description"
				  onBlur={validateField} value={editableClassroom.description} ref={descriptionRef}
				/>
			}</div>
		</>}
	</div></form></DataDisplayModal>
}

export default ClassroomPrompt