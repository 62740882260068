import axios, { AxiosRequestConfig } from "axios";

const RESOURCE_URL_V2 = process.env.REACT_APP_API_URL_V2 + "/admin/campus/";

export class FileService {
    config = (sessionToken: string | null) => {
        return {
            headers:
            {
                Accept: "application/pdf",
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`
            }
        };
    }

    configPdfFile = (sessionToken: string | null): AxiosRequestConfig => {
        return {
            responseType: "blob",   // Set the responseType to 'blob' to receive a binary file
            headers:
            {
                Accept: "application/pdf",
                Authorization: `Bearer ${sessionToken}`
            }
        };
    }

    async getInvoicePDF(sessionToken: string | null, campusId: number, studentId: number, invoiceId: number) {
        const response = await axios.get(
            `${RESOURCE_URL_V2}${campusId}/students/${studentId}/invoices/${invoiceId}/_pdf`,
            this.configPdfFile(sessionToken)
        );
        return response;
    }

    async getPaymentPDF(sessionToken: string | null, campusId: number, paymentId: number) {
        const response = await axios.get(
            `${RESOURCE_URL_V2}${campusId}/payments/${paymentId}/_pdf`,
            this.configPdfFile(sessionToken)
        );
        return response;
    }

    async getPaymentAttachment(sessionToken: string | null, campusId: number, paymentId: number, documentId: number) {
        const uri = `${RESOURCE_URL_V2}${campusId}/payments/${paymentId}/documents/${documentId}/_file`;
        const response = await axios.get(uri,
            {
                responseType: 'blob', // Importante para manejar archivos
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                },
            }
        );
        return response;
    }

}
