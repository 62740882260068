import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
    useEffect,
    useState
} from "react";
import Modal from "react-modal";

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    showModal: boolean;
    onClose: () => void;
}

const ModalComponent: React.FC<ModalProps> = ({ title, showModal, onClose, children }) => {
    const [isOpen, setIsOpen] = useState(showModal);

    useEffect(() => {
        setIsOpen(showModal);
    }, [showModal]);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <React.Fragment>
            <Modal
                appElement={document.getElementById('root') as HTMLElement}
                style={{
                    overlay: { zIndex: 100 },
                    content: {
                        height: 'min-content',
                        background: 'var(--main-bg)',
                        borderRadius: '15px'
                    }
                }}
                isOpen={isOpen}
                onRequestClose={closeModal}
            >
                <div style={{
                    padding: "12px 15px 7px 15px",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div style={{
                        display: "flex"
                    }}>
                        <div style={{
                            display: "flex",
                            color: "var(--txt-color)",
                            width: "100%",
                            paddingTop: "5px"
                        }}>
                            <div>
                                <span className="d-none d-sm-inline-block"
                                    style={{ paddingLeft: "5px" }}>
                                    {title}
                                </span>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            verticalAlign: "top"
                        }}>
                            <button
                                className="button btn btn-default"
                                type="button"
                                onClick={onClose}
                                style={{
                                    color: "var(--txt-color)",
                                    background: "var(--main-bg)",
                                    borderRadius: "50%",
                                    width: "40px",
                                    border: "1px solid #CCCCCC",
                                    padding: "2px 0px 0px 2px"
                                }}>
                                <FontAwesomeIcon icon={solid("times")} size="2x" />
                            </button>
                        </div>
                    </div>
                    <div style={{
                        width: "100%",
                        height: "70vh",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        {children}
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}


export default ModalComponent;