import React,
{
    useEffect,
    useState
} from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";
import Constants from "../../../../constants";
import { UserState } from "../../../../redux/reducers/sessionReducer";
import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../../globals";
import HttpManager from "../../../../services/HttpManager";
import { getErrorMessage } from "../../../../common";
import { styles } from "./style";
import { StudentDTO } from "../../../../models/StudentDTO";


export interface ApplyCreditProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The student data to show.
     */
    student: StudentDTO;
    /**
     * Content to render inside the card.
     */
    invoice: any;
    /**
     * items in invoice.
     */
    itemsInInvoice: any;
    /**
     * total to paid.
     */
    totalToPaid: number;
    /**
     * Is partial payment
     */
    isPartialPayment: boolean;
    /**
     * Is partial payment
     */
    isNewInvoice: boolean;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
    /**
     * 
     * @function to Cancel emit payment operation
     */
    cancelApplyCredit: () => void
    /**
     * 
     * @function to Handle success payment
     */
    onSuccess: () => void
}

interface CreditFormData {
    amount: number,
    notes: string;
}

const ApplyCreditModalComponent: React.FC<ApplyCreditProps> = ({
    student,
    invoice,
    itemsInInvoice,
    totalToPaid,
    isPartialPayment,
    isNewInvoice,
    closeModal,
    cancelApplyCredit,
    onSuccess,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const http = HttpManager.getInstance();
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const [total, setTotal] = useState<number>(
        isNewInvoice
            ? Math.min(student?.generalBalance?.positiveBalance?.value, totalToPaid)
            : Math.min(student?.generalBalance?.positiveBalance?.value, invoice.pendingAmount)
    );

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm<CreditFormData>();

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    const useCredits = async (data: CreditFormData) => {
        try {
            dispatch(enableIdleLockSwitch());
            const invoiceItems: any = {
                tuitions: Array.from(itemsInInvoice.tuitions),
                articles: Array.from(itemsInInvoice.articles)
            };
            const payload = {
                ...data,
                invoiceItems
            };
            const response = isPartialPayment && !isNewInvoice ?
                await http.appliedCreditService.addCreditToExistentInvoice(
                    currentCampus.id,
                    student.id,
                    invoice.id,
                    payload
                )
                : await http.appliedCreditService.applyPositiveCredit(
                    currentCampus.id,
                    student.id,
                    payload
                )
            reset();
            onSuccess();
        } catch (error) {
            console.error("Error submitting form:", error);
            onError(getErrorMessage(error));
        } finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const validatePartialAmount = (value: any) => {
        const MAX = isNewInvoice
            ? Math.min(student?.generalBalance?.positiveBalance?.value, totalToPaid)
            : Math.min(student?.generalBalance?.positiveBalance?.value, invoice.pendingAmount);

        if (value < 1) {
            return 'El valor no puede ser menor a 1';
        }
        if (value > MAX) {
            return `El valor no puede ser mayor a ${MAX}`;
        }

        return true;
    }

    const handlePartialAmount = (e: any) => {
        const MAX = isNewInvoice
            ? Math.min(student?.generalBalance?.positiveBalance?.value, totalToPaid)
            : Math.min(student?.generalBalance?.positiveBalance?.value, invoice.pendingAmount);

        let value = parseFloat(e.target.value);
        if (value > MAX) {
            value = MAX;
        }

        if (value < 1) {
            value = 1;
        }
        setValue('amount', value);
        setTotal(value);
    }

    useEffect(() => {
        if (!isPartialPayment || (isPartialPayment && invoice?.invoiceItems?.length > 1)) {
            setValue('amount', total);
        }
    }, [isPartialPayment]);

    return (
        student &&
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{
                    flexGrow: 1
                }}>
                    <span style={{
                        paddingLeft: "10px"
                    }}>
                        {Constants.Common.applyCredit}
                        &nbsp;
                        {isNewInvoice ? "nueva" : invoice?.id}
                    </span>
                </h2>
                <button
                    type="button"
                    onClick={closeModal}
                    style={{
                        ...styles.btnCloseModal,
                        flexGrow: 0,
                        zIndex: 2
                    }}
                >
                    <FontAwesomeIcon
                        icon={solid("times")}
                        style={{
                            height: "100%",
                            position: "relative",
                            bottom: "1px"
                        }} />
                </button>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px"
            }}>
                <div
                    style={{
                        width: "100%",
                        paddingTop: "2px",
                        marginBottom: "10px"
                    }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "baseline",
                            paddingTop: "2px"
                        }}>
                        <div style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "baseline",
                            paddingTop: "2px"
                        }}>
                            <label htmlFor="name"
                                style={{
                                    position: "relative",
                                    ...styles.smallGrayText
                                }}>
                                {Constants.Common.student}
                            </label>
                        </div>
                    </div>
                    <div style={{
                        width: "50%",
                        borderBottom: "1px solid #7678BF"
                    }}>
                        {student.firstName}
                        &nbsp;
                        {student.lastName}
                    </div>
                </div>

                <form onSubmit={handleSubmit(useCredits)}
                    className="form"
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}>
                    <div style={{
                        background: "#F1F1FF",
                        padding: "15px",
                        display: "flex"
                    }}>
                        <h5
                            style={{
                                display: "inline-block",
                                width: "70%",
                                textAlign: "center"
                            }}>
                            {
                                `${Constants.Common.creditToUse}(${currentCampus.currencyCode})`
                            }
                        </h5>
                        <div style={{
                            display: "inline-block",
                            width: "30%",
                            textAlign: "right",
                            borderBottom: "1px solid #7678BF"
                        }}>
                            {isPartialPayment && invoice?.invoiceItems?.length === 1
                                || isPartialPayment && isNewInvoice ?
                                <input
                                    id="total"
                                    {
                                    ...register('amount', {
                                        required: 'El monto es requerido.',
                                        min: {
                                            value: 1,
                                            message: 'El valor debe ser mayor a 0',
                                        },
                                        max: {
                                            value: total,
                                            message: `El valor debe ser menor o igual a ${total}`,
                                        },
                                        validate: value => validatePartialAmount(value),
                                    })}
                                    onChange={(e: any) => handlePartialAmount(e)}
                                    value={total}
                                    type="number"
                                    style={{
                                        display: "inline-block",
                                        width: "100%",
                                        textAlign: "right"
                                    }}
                                />
                                : <h5 style={{
                                    marginBottom: "unset"
                                }}>
                                    {sprintf("%.02f", totalToPaid)}
                                </h5>
                            }
                        </div>
                    </div>
                    {errors.amount &&
                        <div style={{
                            display: "flex",
                            alignItems: "flex-end",
                            width: "100%"
                        }}>
                            <p style={{
                                ...styles.errorMessage,
                            }}>
                                {errors.amount.message}
                            </p>
                        </div>
                    }
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                        <label htmlFor="notes"
                            style={{
                                ...styles.smallGrayText,
                                position: "relative"
                            }}>
                            {Constants.Common.notes}
                        </label>
                        <textarea id="notes"
                            {
                            ...register('notes',
                                {
                                    required: 'El campo notas es requerido.',
                                    minLength: {
                                        value: 10,
                                        message: 'La nota debe tener al menos 10 caracteres'
                                    }
                                }
                            )}
                            name="notes"
                            placeholder={Constants.Common.notes}
                            style={{
                                resize: "vertical",
                                width: "100%",
                                border: "1px solid #7678BF",
                                borderRadius: "5px",
                                minHeight: "150px",
                                padding: "5px"
                            }}
                        />
                        {errors.notes &&
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {errors.notes.message}
                            </p>
                        }
                    </div>

                    <br />
                    <div style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "10px"
                    }}>
                        <button className="btn btn-outline-danger rounded-pill"
                            type="submit"
                            disabled={idleLocked}
                            style={{
                                width: "50%"
                            }}>
                            {Constants.Common.usePositiveBalance}
                        </button>
                        <button
                            onClick={cancelApplyCredit}
                            disabled={idleLocked}
                            type="button"
                            className="btn btn-outline-default rounded-pill"
                            style={{
                                width: "50%",
                                border: "1px solid #000000"
                            }} >
                            {Constants.Common.cancel}
                        </button>
                    </div>
                </form>
            </div>


        </React.Fragment>
    );

}

export default ApplyCreditModalComponent;