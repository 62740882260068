import { axiosInstance } from "./axiosInstance";

const RESOURCE_URL_V2 = "/admin/recipientAccounts";

export class RecipientAccountsService {

    getRecipientAccounts = async (countryId?: number) => {
        let params = [];
        if (countryId) {
            params.push("countryId=" + countryId)
        }
        const queryParams = params.length > 0 ? "?" + params.join("&") : "";
        const response = await axiosInstance.get(`${RESOURCE_URL_V2}${queryParams}`);
        return response;
    }

    saveRecipientAccount = (recipientAccount: any) => {
        const saveFunction = recipientAccount.id == null ? this.registerRecipientAccount : this.updateRecipientAccount

        let payload: any = {
            name: recipientAccount.name + "",
            accountNumber: recipientAccount.account_number + "",
            countries: recipientAccount.countryIds
        }

        if (recipientAccount.description !== null && recipientAccount.description !== "") {
            payload.description = recipientAccount.description
        }
        const response = saveFunction(payload, recipientAccount.id);
        return response;
    }

    registerRecipientAccount = async (payload: any) => {
        return axiosInstance.post(RESOURCE_URL_V2, payload);
    }

    updateRecipientAccount = async (payload: any, id: number) => {
        return await axiosInstance.put(RESOURCE_URL_V2 + "/" + id, payload);
    }

    removeRecipientAccounts = async (recordIds: any) => {
        let url: string = RESOURCE_URL_V2;
        let payload: any;
        if (recordIds.length < 2) {
            url = RESOURCE_URL_V2 + "/" + recordIds[0]
        }
        else {
            url = RESOURCE_URL_V2
            payload.data = { ids: recordIds.join(",") }
        }
        const response = await axiosInstance.delete(url, payload);
        return response;
    }
}