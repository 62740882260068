import { CSSProperties } from "react";
import Constants from "../../../constants";

const TransferCreditContainer: CSSProperties = {
    paddingTop: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    overflowY: "visible",
}

const smallGrayText: CSSProperties = {
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.grayOpacity,
    opacity: 1,
    marginBottom: "5px",
    paddingTop: "7px"
}

const errorMessage: CSSProperties = {
    color: Constants.Styles.text.colors.red,
    width: "100%",
    font: Constants.Styles.text.fonts.robotoNormal16,
    padding: "5px"
}

export const styles = {
    TransferCreditContainer,
    smallGrayText,
    errorMessage
}