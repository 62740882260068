import React from "react";
import classNames from "classnames";

import "./style.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    size?: "sm" | "lg"; // Define the size prop with "sm" and "lg" options
    tooltip?: string;
}

const ButtonComponent: React.FC<ButtonProps> = ({ className, size, children, onClick, tooltip, ...props }) => {
    // Use the classNames library to conditionally apply classes
    const buttonClass = classNames(
        "wa-btn", // default button class
        {
            "wa-btn-sm": size === "sm", // apply "btn-sm" class if size is "sm"
            "wa-btn-lg": size === "lg", // apply "btn-lg" class if size is "lg"
        },
        className // apply any additional classes passed in via className prop
    );

    return (
        tooltip ? <OverlayTrigger overlay={
            <Tooltip>
                {tooltip}
            </Tooltip>
        }>
            <button className={buttonClass} onClick={onClick} {...props}>
                {children}
            </button>
        </OverlayTrigger>

            : <button className={buttonClass} onClick={onClick} {...props}>
                {children}
            </button>
    );
};

export default ButtonComponent;
