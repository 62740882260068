import React,
{
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import Constants,
{
    articleType
} from "../../constants";
import CommonTable,
{
    Column
} from "../commonTable";
import Card from "../card";
import { styles } from "./style";
import { RootState } from "../../globals";

export interface ArticleCardProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Columns to show in the Table
     */
    columns: Column[];
    /**
     * Data to show in the Table
     */
    data: any;
    /**
     * Header to show in the Table example: Saldo Kits, Others, etc
     */
    header: string;
    /**
     * function to manage total
     */
    type: number;
}

const ArticleCardComponent: React.FC<ArticleCardProps> = (props: ArticleCardProps) => {
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const { columns, data, header, type } = props;
    const [total, setTotal] = useState<number>(0);
    const [filterData, setFilterData] = useState<any[]>([]);
    const [currency, setCurrency] = useState<string>("");

    const handleTotal = () => {
        if (filterData.length > 0) {
            const sum = filterData.reduce((acc, item) => acc + item.pendingAmount.value, 0);
            setTotal(sum);
        }
    }

    const formatTotal = (value: number) => {
        const totalText = `${currency}${value.toFixed(2)}`;
        return totalText;
    };

    useEffect(() => {
        setCurrency(currentCampus.currency ?? "");
        const filteredData = (type === articleType.STANDARD_KIT)
            ? data.filter((item: any) => item.typeId === articleType.STANDARD_KIT)
            : data.filter((item: any) => item.typeId !== articleType.STANDARD_KIT)

        setFilterData([...filteredData]);
    }, [type, data]);

    useEffect(() => {
        handleTotal();
    }, [filterData]);

    return (
        <div style={{ padding: 0, width: "100%" }}>
            <Card>
                <section style={{ ...styles.header }}>
                    <h3 style={styles.courseText}>
                        {header}
                    </h3>
                </section>
                {filterData.length > 0 ?
                    <div style={{ width: "100%" }}>
                        <CommonTable
                            columns={columns}
                            data={filterData}
                        />
                        <div className="cardFooter">
                            <section style={styles.totalSection}>
                                <div
                                    style={styles.totalContainer}
                                >
                                    <div style={styles.totalText}>
                                        <p style={{ marginBottom: 0 }}>
                                            {Constants.Screens.Finances.accountStatement.total}:
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            ...styles.totalTextNumber,
                                            paddingLeft: "15px",
                                            color: total === 0 ? "#000000" : "#C32722"
                                        }}
                                    >
                                        <p style={{ marginBottom: 0 }}>
                                            {formatTotal(total)}
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    :
                    <div style={{ ...styles.header }}>
                        <p>Sin registros</p>
                    </div>
                }
            </Card>
        </div>
    )
}

export default ArticleCardComponent;
