import { CSSProperties } from "react";
import Constants from "../../../constants";

const updateEnrollmentContainer: CSSProperties = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflowY: "visible",
}

const subtitleContainer: CSSProperties = {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "start",
    gap: 10,
    flexFlow: "wrap"
}

const updateEnrollmentForm: CSSProperties = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
}

const enrollmentOptionsContainer: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: "20px"
}

const enrollmentOptionsSubContainer: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    width: "20%"
}

const selectContainer: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%"
}
const tuitionsTableContainer: CSSProperties = {
    paddingLeft: "30px",
    paddingRight: "30px"
}

const tableContainer: CSSProperties = {
    paddingTop: "30px"
}

const studentInfoLabel: CSSProperties = {
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.gray,
    opacity: 1,
    marginLeft: "10px",
    paddingTop: "7px",
    paddingBottom: "10px",
    position: "relative"
}

const smallGrayText: CSSProperties = {
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.grayOpacity,
    opacity: 1,
    marginBottom: "5px",
    paddingTop: "7px",
    position: "relative"
}

const boldText: CSSProperties = {
    fontWeight: "bold",
    marginBottom: 0,
    flexGrow: 1,
    padding: "0px"
}

const btnCloseModal: CSSProperties = {
    top: 15,
    right: 15,
    borderRadius: "100%",
    width: 20,
    height: 20,
    border: "1px solid " + Constants.Styles.background.redButtonClose,
    color: Constants.Styles.text.colors.redButtonClose,
    background: Constants.Styles.background.transparent
}

const cellStyle: CSSProperties = {
    borderBottom: "1px solid #C7C7C7",
    textAlign: "right",
    width: "1px",
    verticalAlign: "bottom",
    padding: "unset",
    paddingBottom: "10px"
}

const errorMessage: CSSProperties = {
    color: Constants.Styles.text.colors.red,
    width: "100%",
    font: Constants.Styles.text.fonts.robotoNormal16,
    padding: "5px"
}

const studentDataRow: CSSProperties = {
    display: "flex",
    flexFlow: "wrap",
    flexWrap: "wrap",
    gap: "15px",
    margin: "10px 0 10px 0"
}

const studentDataText: CSSProperties = {
    font: Constants.Styles.text.fonts.robotoBold16,
    marginBottom: 0
}

const loadingContainer: CSSProperties = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto"
}

const saveChangesContainer: CSSProperties = {
    width: "100%",
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
}

const saveChangesBtn: CSSProperties = {
    width: "50%",
    cursor: "pointer"
}

export const styles = {
    updateEnrollmentContainer,
    subtitleContainer,
    updateEnrollmentForm,
    enrollmentOptionsContainer,
    enrollmentOptionsSubContainer,
    selectContainer,
    tuitionsTableContainer,
    tableContainer,
    studentInfoLabel,
    smallGrayText,
    boldText,
    btnCloseModal,
    cellStyle,
    errorMessage,
    studentDataRow,
    studentDataText,
    loadingContainer,
    saveChangesContainer,
    saveChangesBtn
}