import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"
import
{
	isNotEmpty,
	removeRecords,
	toAcceptableDate
}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/";

class EmployeesService
{
	sessionToken = store.getState().sessionToken.value
	basicInfo    = store.getState().basicInfo.value
	campusId     = store.getState().currentUserCampusId.value

	constructor()
	{
		store.subscribe
		(
			()=>
			{
				this.sessionToken = store.getState().sessionToken.value
				this.basicInfo    = store.getState().basicInfo.value
				this.campusId     = store.getState().currentUserCampusId.value
			}
		)
	}

	async countEmployees(search? : any)
	{
		let params = [];

		if(search)
		{
			if(search.roleIds && search.roleIds.length > 0)
			{
				params.push("roleIds=" +  search.roleIds.join(","))
			}

			if(search.statusId >= 0)
			{
				params.push("statusId=" + search.statusId)
			}

			if(search.onlyMentors)
			{
				params.push("onlyMentors");
			}
		}

		return axios.get
		(
			RESOURCE_URL + this.campusId + "/employees/_count"+ (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async searchEmployees(search? : any, page? : number, resultsLimit? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}
		else
		{
			if(resultsLimit)
			{
				params.push("limit=" + resultsLimit)
			}
		}

		if(search)
		{
			if(search.statusId >= 0)
			{
				params.push("statusId=" + search.statusId)
			}

			if(isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}

			if(isNotEmpty(search.roleIds))
			{
				params.push("roleIds=" +  search.roleIds.join(","))
			}

			if(search.onlyMentors)
			{
				params.push("onlyMentors");
			}
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + this.campusId + "/employees"+ (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveEmployee(user : any)
	{
		let payload : FormData = new FormData()

		let record : any=
		{
			nationalityId        : user.nationality_id,
			countryId            : user.country_id,
			statusId             : user.status_id,
			genderId             : user.gender_id,
			identificationTypeId : user.identification_type_id,
			identificationCode   : user.identification_code + "",
			firstName            : user.first_name + "",
			lastName             : user.last_name + "",
			email                : user.email + "",
			links                : JSON.stringify
			({
				facebook   : user.facebook  || "",
				instagram  : user.instagram || "",
			}),
			roleCampusIdsListing : user
				.roles
				.map
				(
					(role : any)=>
					{
						let data : any = role.id

						if(role.campuses && role.campuses.length > 0)
						{
							data += "," + _.map(role.campuses , "id").join(",")
						}

						return data
					}
				)
				.join(";")
		}

		for(let field of ["password", "observations", "zipcode", "address", "phone1", "phone2"])
		{
			if(isNotEmpty(user[field]))
			{
				record[field] = user[field] + ""
			}
		}

		if(isNotEmpty(user.id_clase_365))
		{
			record.idClase365 = user.id_clase_365 + ""
		}

		if(this.basicInfo.countryRef["C_" + user.country_id].states.length < 1)
		{
			if(isNotEmpty(user.state_name))
			{
				record.stateName = user.state_name + ""
			}

			if(isNotEmpty(user.city_name))
			{
				record.cityName = user.city_name + ""
			}
		}
		else
		{
			record.stateId = user.state_id

			if(this.basicInfo.countryRef["C_" + user.country_id].stateRef["S_" + user.state_id].cities.length < 1)
			{
				if(isNotEmpty(user.city_name))
				{
					record.cityName = user.city_name + ""
				}
			}
			else
			{
				record.cityId = user.city.id
			}
		}

		if(user.birthdayValue)
		{
			record.birthday = toAcceptableDate(user.birthdayValue)
		}

		if(user.tags.length > 0)
		{
			record.tagIds = _.map(user.tags, "id").join(",")
		}

		Object.keys(record).map((key : string) => {payload.append(key, record[key])})

		if(user.image)
		{
			payload.append("image", user.image == user.imagePreview ? "" : user.image)
		}

		return axios.post
		(
			RESOURCE_URL + this.campusId + "/employees" + (user.id == null ? "" : "/" + user.id + "?_method=PUT"),
			payload,
			{
				headers:
				{
					Accept         : "application/json, text/plain, */*",
					"Content-Type" : "multipart/form-data",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
	}

	async removeEmployees(recordIds : any)
	{
		return removeRecords(RESOURCE_URL + this.campusId + "/employees", recordIds)
	}
	//Mentors----------------------------------------------------------------------------------------------------------------
	async getLectures(search? : any, page? : number)
	{
		return axios.get
		(
			RESOURCE_URL + this.campusId +"/employees/_lectures",
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async getMentorById(campusId : number, id: number)
	{
		try
		{
			let res = await axios.get
			(
				RESOURCE_URL + campusId + "/mentors" + id,
				{
					headers:
					{
						"Content-Type"  : "application/json",
						"Authorization" : "Bearer " + this.sessionToken
					}
				}
			)
			return res;
		}
		catch(err)
		{
			console.log(err)
		}
	}
}

export default new EmployeesService()