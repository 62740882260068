import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
    useState
} from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Constants from "../../constants";
import { styles } from "./style";

export interface TruncateTextProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    value: string;
    maxLength: number;
    maxWidth: string;
}

const TruncateTextComponent: React.FC<TruncateTextProps> = ({
    value,
    maxLength,
    maxWidth
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const truncatedText = value.length > maxLength ? value.slice(0, maxLength) + "..." : value;

    return (
        <div style={{ ...styles.container, width: maxWidth }}>
            <span style={styles.truncatedText}>
                {isExpanded ? value : truncatedText}
            </span>
            {value.length > maxLength && (
                <span
                    onClick={handleToggle}
                    style={styles.chevron}>
                    {isExpanded
                        ? <OverlayTrigger overlay={
                            <Tooltip>
                                {Constants.Common.showLess}
                            </Tooltip>
                        }>
                            <FontAwesomeIcon icon={solid("chevron-up")} size="1x" />
                        </OverlayTrigger>
                        : <OverlayTrigger overlay={
                            <Tooltip>
                                {Constants.Common.showMore}
                            </Tooltip>
                        }>
                            <FontAwesomeIcon icon={solid("chevron-down")} size="1x" />
                        </OverlayTrigger>
                    }
                </span>
            )}
        </div>
    );
};

export default TruncateTextComponent;