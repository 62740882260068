const container = {
    width: "600px",
    height: "50px",
    background: "#F3F8FF",
    border: "1px solid #8F91DA",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const clearFilter = {
    display: "flex",
    width: "20px",
    height: "100%"
}

const clearFilterBtn = {
    color: "#8F91DA",
    padding: "10px",
    background: "#F3F8FF",
    width: "100%",
    height: "44px"
}

const inputContainer = {
    width: "100%",
    paddingLeft: "10px"
}

const inputSearch = {
    background: "#F3F8FF",
    width: "100%",
    height: "44px"
}

const searchBtnContainer = {
    display: "flex",
    width: "30px"
}

const searchBtn = {
    color: "#8F91DA",
    background: "#F3F8FF",
    width: "100%",
    height: "44px"
}

export const styles =
{
    container,
    clearFilter,
    clearFilterBtn,
    inputContainer,
    inputSearch,
    searchBtnContainer, 
    searchBtn
}