import {
    Suspense,
    lazy
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../globals";
import { setShowModal } from "../../../../redux/reducers/invoicesReducer";
import Constants, { FINANCES_MODALS } from "../../../../constants";
import CancelInvoiceModalComponent from "../../../../components/modals/cancelInvoice/cancelInvoice";
import GenerateInvoiceModalComponent from "../../../../components/modals/generateInvoice/generateInvoice";
import InvoiceDetailModalComponent from "../../../../components/modals/invoiceDetails/invoiceDetails";
import CancelPaymentModalComponent from "../../../../components/modals/payments/cancel/cancelPayment";
import EmitPaymentModalComponent from "../../../../components/modals/payments/create/emitPayment";
import PaymentDetailModalComponent from "../../../../components/modals/payments/detail/paymentDetails";
import CommonTable from "../../../../components/commonTable";
import PaginationComponent from "../../../../components/pagination/pagination";
import Card from "../../../../components/card";
import SearchInputComponent from "../../../../components/searchInput/searchInput";
import { FinancesModalsTypes } from "../../../../types";
import ApplyCreditModalComponent from "../../../../components/modals/applied_credits/create/applyCredit";
import CreditDetailModalComponent from "../../../../components/modals/applied_credits/detail/creditDetail";

const AsideModalComponent = lazy(() => import("../../../../components/modals/asideModal"));

const InvoiceView = (props: any) => {
    const {
        invoicesProps,
        paginationProps,
        searchProps,
        invoiceDetailProps,
        paymentDetailProps,
        emitPaymentProps,
        cancelPaymentProps,
        generateInvoiceProps,
        cancelInvoiceProps,
        applyCreditProps,
        appliedCreditsDetailProps
    } = props

    const dispatch = useDispatch<AppDispatch>();
    const showModal: boolean = useSelector((state: RootState) => state.rootReducer.invoices.showModal);
    const modalContent: any = useSelector((state: RootState) => state.rootReducer.invoices.modalContent);
    const student: any = useSelector((state: RootState) => state.rootReducer.invoices.studentData);
    const payment: any = useSelector((state: RootState) => state.rootReducer.invoices.selectedPayment);
    const invoice: any = useSelector((state: RootState) => state.rootReducer.invoices.selectedInvoice);
    const credit: any = useSelector((state: RootState) => state.rootReducer.invoices.selectedCredit);
    const itemsInInvoice: any = useSelector((state: RootState) => state.rootReducer.invoices.invoiceItems);
    const totalToPaid: any = useSelector((state: RootState) => state.rootReducer.invoices.totalToPaid);

    const renderModalContent = (children: FinancesModalsTypes) => {
        switch (children) {
            case FINANCES_MODALS.INVOICE:
                return (
                    <InvoiceDetailModalComponent
                        student={student}
                        invoice={invoice}
                        closeModal={invoiceDetailProps.closeModal}
                        showCreditDetail={invoiceDetailProps.showCreditDetail}
                        showPaymentDetail={invoiceDetailProps.showPaymentDetail}
                        cancelInvoice={invoiceDetailProps.cancelInvoice}
                        proccessPayment={invoiceDetailProps.proccessPayment}
                    />
                );
            case FINANCES_MODALS.GENERATE_INVOICE:
                return (
                    <GenerateInvoiceModalComponent
                        onSuccess={generateInvoiceProps.onSuccess}
                        closeModal={generateInvoiceProps.closeModal}
                    />
                );
            case FINANCES_MODALS.CANCEL_INVOICE:
                return (
                    <CancelInvoiceModalComponent
                        student={student}
                        invoice={invoice}
                        closeModal={cancelInvoiceProps.closeModal}
                        onSuccess={cancelInvoiceProps.onSuccess}
                    />
                );
            case FINANCES_MODALS.PAYMENT_DETAIL:
                return (
                    <PaymentDetailModalComponent
                        student={student}
                        payment={payment}
                        closeModal={paymentDetailProps.closeModal}
                        handleCancelPayment={paymentDetailProps.handleCancelPayment}
                    />
                );
            case FINANCES_MODALS.EMIT_PAYMENT:
                return (
                    <EmitPaymentModalComponent
                        student={student}
                        invoice={invoice}
                        itemsInInvoice={itemsInInvoice}
                        totalToPaid={totalToPaid}
                        isPartialPayment={emitPaymentProps.isPartialPayment}
                        isNewInvoice={emitPaymentProps.isNewInvoice}
                        closeModal={emitPaymentProps.closeModal}
                        cancelEmitPayment={emitPaymentProps.cancelEmitPayment}
                        onSuccess={emitPaymentProps.onSuccess}
                        showApplyCreditModal={emitPaymentProps.showApplyCreditModal}
                    />
                );
            case FINANCES_MODALS.CANCEL_PAYMENT:
                return (
                    <CancelPaymentModalComponent
                        student={student}
                        payment={payment}
                        closeModal={cancelPaymentProps.closeModal}
                        onSuccess={cancelPaymentProps.onSuccess}
                    />
                );
            case FINANCES_MODALS.APPLY_CREDIT:
                return (
                    <ApplyCreditModalComponent
                        student={student}
                        invoice={invoice}
                        itemsInInvoice={itemsInInvoice}
                        totalToPaid={totalToPaid}
                        isPartialPayment={applyCreditProps.isPartialPayment}
                        isNewInvoice={applyCreditProps.isNewInvoice}
                        closeModal={applyCreditProps.closeModal}
                        cancelApplyCredit={applyCreditProps.cancelApplyCredit}
                        onSuccess={applyCreditProps.onSuccess}
                    />
                );
            case FINANCES_MODALS.APPLY_CREDIT_DETAILS:
                return (
                    <CreditDetailModalComponent
                        credit={credit}
                        closeModal={appliedCreditsDetailProps.closeModal}
                    />
                );
            default:
                dispatch(setShowModal(false));
                return null;
        }
    }

    return (
        <div className="transition-width"
            style={{
                width: `calc(100% - ${showModal ? "648px" : "0px"})`
            }}>

            <div className="row"
                style={{
                    paddingLeft: "30px",
                    paddingRight: "30px"
                }}>
                <div
                    className="col-lg-12"
                    style={{
                        padding: "30px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <div
                        className="col-lg-3"
                        style={{
                            display: "flex",
                            justifyContent: "flex-start"
                        }}>
                        <h2>{Constants.Common.invoices}</h2>
                    </div>
                    <div
                        className="col-lg-9"
                        style={{
                            display: "flex",
                            justifyContent: "flex-start"
                        }}>
                        <SearchInputComponent
                            search={searchProps.search}
                            onChangeSearch={searchProps.onChangeSearch}
                            onClickSearch={searchProps.onClickSearch}
                            onClearSearch={searchProps.onClearSearch}
                        />
                    </div>
                </div>
                <div style={{
                    padding: 0,
                    width: "100%"
                }}>
                    <Card>
                        {
                            <div
                                className="col-lg-12"
                                style={{
                                    padding: "10px 10px 0px 10px",
                                }}>
                                <CommonTable
                                    columns={invoicesProps.columns}
                                    data={invoicesProps.invoices}
                                    filters={invoicesProps.filters}
                                    onFilterChange={invoicesProps.onFilterChange}
                                    onClearFilters={invoicesProps.onClearFilters}
                                >
                                </CommonTable>
                            </div>
                        }
                    </Card>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "sticky",
                        bottom: 0,
                        paddingRight: 100,
                        background: "#FFFFFF"
                    }}>
                        <PaginationComponent
                            pages={paginationProps.pages}
                            onPageSizeChange={paginationProps.handlePageSizeChange}
                            pageSizes={paginationProps.pagesSize}
                            itemCount={paginationProps.itemCount}
                        />
                    </div>
                </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                <AsideModalComponent
                    showModal={showModal}
                    location="right"
                    style={{ width: "648px" }}>
                    {renderModalContent(modalContent)}
                </AsideModalComponent>
            </Suspense>
        </div>
    )
}

export default InvoiceView;