import {
    createSlice,
    current,
    PayloadAction
} from "@reduxjs/toolkit";

const initialState: any = {
    course: null,
    tuitions: [],
    selectedTuitions: [],
    checkedTuitions: new Set<number>(),
    disabledTuitions: new Set<number>()
}

const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setCourse: (state, action: PayloadAction<any>) => {
            state.course = action.payload;
        },
        setTuitions: (state, action: PayloadAction<any>) => {
            state.tuitions = action.payload;
        },
        setSelectedTuitions: (state, action: PayloadAction<any>) => {
            state.selectedTuitions = action.payload;
        },
        setCheckedTuitions: (state, action: PayloadAction<any>) => {
            state.checkedTuitions = action.payload;
        },
        setDisabledTuitions: (state, action: PayloadAction<any>) => {
            state.disabledTuitions = action.payload;
        },
        handleEnrollmentTuitionSelection: (state, action: PayloadAction<any>) => {
            const currentState = current(state);
            const currentTuition = action.payload;
            const tuitionIndex = currentState.tuitions
                .findIndex((tuition: any) => tuition.id === currentTuition.id);

            if (state.checkedTuitions.has(currentTuition.id)) {
                for (let i = 0; i <= tuitionIndex; i++) {
                    const tuitionId = currentState.tuitions[i].id;
                    state.checkedTuitions.delete(tuitionId);
                    state.disabledTuitions.delete(tuitionId);
                    state.selectedTuitions = state.selectedTuitions
                        .filter((item: any) => item.id !== tuitionId);
                }
            } else {
                state.selectedTuitions = [];
                state.disabledTuitions = new Set<number>();
                for (let i = 0; i <= tuitionIndex; i++) {
                    const tuition = currentState.tuitions[i];
                    state.checkedTuitions.add(tuition.id);
                    state.selectedTuitions.push(tuition);
                    if (i < tuitionIndex) {
                        state.disabledTuitions.add(tuition.id);
                    }
                }
            }
        }
    }
});

export const {
    setCourse,
    setTuitions,
    setSelectedTuitions,
    setCheckedTuitions,
    setDisabledTuitions,
    handleEnrollmentTuitionSelection
} = scheduleSlice.actions;

export default scheduleSlice.reducer;