// Tabla.tsx
import React from 'react';

import { styles } from './style';
import Constants from '../../constants';
import CommonTable, { Column } from '../commonTable';
import Card from '../card';
import { Badge } from 'react-bootstrap';
import Utils from '../../utils/utils';

export interface AccountStatementCardProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Columns to show in the Table
     */
    columns: Column[];
    /**
     * Data to show in the Table
     */
    data: any;
}

const AccountStatementCard: React.FC<AccountStatementCardProps> = ({ columns, data }) => {

    return (
        <React.Fragment > {data ?
            <Card>
                <section style={{
                    ...styles.header,
                    flexDirection: "column"
                }}>
                    <div style={{
                        marginLeft: "7px",
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <p style={{
                            ...styles.subtitle,
                            margin: 0
                        }}>
                            {Constants.Common.course}:
                        </p>
                        <div style={{
                            display: "flex",
                            paddingRight: "20px"
                        }}>
                            <Badge bg={Utils.renderStatusEnrollment(data.enrollmentStatusId)}>
                                {data.enrollmentStatus}
                            </Badge>
                        </div>
                    </div>
                    <h3 style={styles.courseText}>
                        {data.courseName}
                    </h3>
                </section>
                <section style={{
                    marginBottom: 10,
                    marginLeft: "25px",
                    display: "inline-flex",
                    alignItems: "stretch",
                    flexFlow: "wrap"
                }}>
                    <div style={{ flexGrow: 1 }}>
                        <div style={{
                            display: "flex",
                            alignItems: "stretch",
                            flexFlow: "wrap"
                        }}>
                            <div style={{ flexGrow: 1 }}>
                                <div style={{
                                    ...styles.subtitleContainer,
                                    alignItems: "stretch"
                                }}>
                                    <div style={{ ...styles.subtitle }}>
                                        {Constants.Common.level}:
                                    </div>
                                    <div style={{ ...styles.boldText }}>
                                        {data.levelName}
                                    </div>
                                </div>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <div style={{
                                    ...styles.subtitleContainer,
                                    alignItems: "stretch"
                                }}>
                                    <div style={{ ...styles.subtitle }}>
                                        {Constants.Common.period}:
                                    </div>
                                    <div style={{ ...styles.periodText }}>
                                        {data.periodName}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <div style={{
                            display: "flex",
                            alignItems: "stretch",
                            flexFlow: "wrap"
                        }}>
                            <div style={{ flexGrow: 1 }}>
                                <div style={{
                                    ...styles.subtitleContainer,
                                    alignItems: "stretch"
                                }}>
                                    <div style={{ ...styles.subtitle }}>
                                        {Constants.Common.scheduleCode}:
                                    </div>
                                    <div style={{ ...styles.boldText }}>
                                        {data.scheduleCode}
                                    </div>
                                </div>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <div style={{
                                    ...styles.subtitleContainer,
                                    alignItems: "stretch"
                                }}>
                                    <div style={{ ...styles.subtitle }}>
                                        {Constants.Common.schedule}:
                                    </div>
                                    <div style={{ ...styles.boldText }}>
                                        {data.schedules}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CommonTable
                    columns={columns}
                    data={data.tuitions}
                />
                <div className="cardFooter">
                    <section style={styles.totalSection}>
                        <div style={styles.totalContainer}>
                            <div style={styles.totalText}>
                                {Constants.Screens.Finances.accountStatement.total}:
                            </div>
                            <div
                                style={{
                                    ...styles.totalTextNumber,
                                    paddingLeft: "15px",
                                    color: data.pendingAmountTotal.value === 0 ? "#000000" : "#C32722"
                                }}>
                                {data.pendingAmountTotal.moneyFormat}
                            </div>
                        </div>
                    </section>
                </div>
            </Card>
            :
            <Card>
                <div style={{ ...styles.header }}>
                    <p>Sin registros</p>
                </div>
            </Card>
        }
        </React.Fragment>
    )
}

export default AccountStatementCard;
