import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";

import CheckoutTuitionItemComponent from "../../checkoutTuitionItem";
import CheckoutArticleItemComponent from "../../checkoutArticleItem";
import DiscountButtonComponent from "../../buttons/discountButton";
import Constants,
{
    FINANCES_MODALS,
    owedArticleStatus,
    owedTuitionStatus
} from "../../../constants";
import CustomIcon from "../../customIcon";
import { Column } from "../../commonTable";
import { AppDispatch, RootState } from "../../../globals";
import { styles } from "./style";
import {
    buildInvoiceItems,
    handleArticlesSelection,
    handleTuitionsSelection,
    setModalContent,
    setTotalToPaid
} from "../../../redux/reducers/accountStatementReducer";

import "./style.css"

export interface CheckoutProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The student data to show.
     */
    student: any;
    /**
     * Content to render inside the card.
     */
    data: any;
    /**
     * 
     * @function to close Modal
     */
    goToProccessPayment: (isPartialPayment: boolean) => void
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
}

const tuitionColumns: Column[] = [
    {
        id: "selectTuition",
        header: "",
        accessor: "",
        render: (data: any) => data.statusId === owedTuitionStatus.FULLY_PAID ? <></>
            : <button className="btn btn-default" type="button"
                disabled={false}
            >
                <CustomIcon name="bCheck" />
            </button>,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "5%"
        }),
        overrideHeaderStyle: () => ({
            textAlign: "center",
            borderBottom: 0
        })
    },
    {
        id: "",
        header: Constants.Common.concept,
        accessor: "",
        render: (data: any) =>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <span style={{
                    whiteSpace: "unset",
                    position: "relative",
                }}>
                    {data.monthNumber}
                </span>
                <label style={{
                    width: "100%",
                    position: "relative",
                    wordBreak: "break-all",
                    whiteSpace: "unset"
                }}>
                    {data.title}
                </label>
            </div>,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "25%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    },
    {
        id: "",
        header: Constants.Common.amount,
        accessor: "",
        render: (data: any) => data.subTotal.moneyFormat,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "20%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    },
    {
        id: "discount",
        header: Constants.Common.discount,
        accessor: "",
        render: (data: any) => <DiscountButtonComponent charge={data}
            onDiscountChange={() => { }}
        />,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "25%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    },
    {
        id: "",
        header: Constants.Common.tax,
        accessor: "",
        render: (data: any) => data.taxValue,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "20%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    }
];

const articleColumns: Column[] = [
    {
        id: "selectArticle",
        header: "",
        accessor: "",
        render: (data: any) => data.statusId === owedArticleStatus.FULLY_PAID ? <></>
            : <button className="btn btn-default" type="button"
                disabled={false}
            >
                <CustomIcon name="bCheck" />
            </button>,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "5%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    },
    {
        id: "",
        header: Constants.Common.concept,
        accessor: "",
        render: (data: any) =>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <span style={{
                    whiteSpace: "unset",
                    position: "relative",
                }}>
                    {data.name}
                </span>
                <label style={{
                    width: "100%",
                    position: "relative",
                    wordBreak: "break-all",
                    whiteSpace: "unset"
                }}>
                    {data.sku}
                </label>
            </div>,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "25%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    },
    {
        id: "",
        header: Constants.Common.amount,
        accessor: "",
        render: (data: any) => data.subTotal.moneyFormat,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "20%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    },
    {
        id: "discount",
        header: Constants.Common.discount,
        accessor: "",
        render: (data: any) => <DiscountButtonComponent charge={data}
            onDiscountChange={() => { }}
        />,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "25%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    },
    {
        id: "",
        header: Constants.Common.tax,
        accessor: "",
        render: (data: any) => data.taxValue,
        overrideCellStyle: () => ({
            textAlign: "center",
            borderBottom: 0,
            textWrap: "wrap",
            width: "20%"
        }),
        overrideHeaderStyle: () => ({
            ...styles.subtitle,
            textAlign: "center",
            borderBottom: 0
        })
    }
];

const CheckoutModalComponent: React.FC<CheckoutProps> = ({
    student,
    data,
    goToProccessPayment,
    closeModal }) => {
    const dispatch = useDispatch<AppDispatch>();
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const checkedArticles: any = useSelector((state: RootState) => state.rootReducer.accountStatement.checkedArticles);
    const disabledTuitions: any = useSelector((state: RootState) => state.rootReducer.accountStatement.disabledTuitions);
    const selectedTuitions: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedTuitions);
    const totalToPaid: any = useSelector((state: RootState) => state.rootReducer.accountStatement.totalToPaid);
    const [total, setTotal] = useState<number>(0);
    const [discounts, setDiscounts] = useState<{ [key: string]: number }>({});
    const [items, setItems] = useState<number>(0);

    const toggleArticleSelection = (article: any) => {
        dispatch(handleArticlesSelection(article));
    }

    const toggleTuitionsSelection = (tuition: any) => {
        dispatch(handleTuitionsSelection(tuition));
    }

    const tuitionColumnsWithLogic = tuitionColumns.map((column: any) => {
        if (column.id === "selectTuition" && column.render) {
            return {
                ...column,
                render: (tuition: any) => (
                    tuition.statusId === owedTuitionStatus.FULLY_PAID ? <></>
                        : <button
                            className="btn btn-default"
                            type="button"
                            disabled={idleLocked || Array.from(disabledTuitions).includes(tuition.id)}
                            onClick={() => toggleTuitionsSelection(tuition)}
                        >
                            <CustomIcon name={Array.from(selectedTuitions).includes(tuition.id) ? "bCheck" : "bUncheck"} />
                        </button>
                ),
            };
        }
        if (column.id === "discount" && column.render) {
            return {
                ...column,
                render: (tuition: any) => (
                    <DiscountButtonComponent
                        charge={tuition}
                        onDiscountChange={(value: number) => handleDiscountChange(tuition.id, value)}
                    />
                ),
            };
        }
        return column;
    });
    const articleColumnsWithLogic = articleColumns.map((column: any) => {
        if (column.id === "selectArticle" && column.render) {
            return {
                ...column,
                render: (article: any) => (
                    article.statusId === owedArticleStatus.FULLY_PAID ? <></>
                        : <button
                            className="btn btn-default"
                            type="button"
                            disabled={idleLocked}
                            onClick={() => toggleArticleSelection(article)}
                        >
                            <CustomIcon name={Array.from(checkedArticles).includes(article.id) ? "bCheck" : "bUncheck"} />
                        </button>
                ),
            };
        }
        if (column.id === "discount" && column.render) {
            return {
                ...column,
                render: (article: any) => (
                    <DiscountButtonComponent
                        charge={article}
                        onDiscountChange={(value: number) => handleDiscountChange(article.id, value)}
                    />
                ),
            };
        }
        return column;
    });

    const handleTotal = () => {
        let sum = 0;
        for (let course of data.courses) {
            for (let tuition of course.tuitions) {
                sum += tuition.subTotal.value;
            }
        }
        for (let article of data.articles) {
            sum += article.subTotal.value;
        }
        setTotal(sum);
    }

    const calculateTotalToPaid = () => {
        let discountedTotal = 0;
        for (let key in discounts) {
            discountedTotal += +discounts[key];
        }
        dispatch(setTotalToPaid(total - discountedTotal));
    }

    const handleDiscountChange = (chargeId: string, discount: number) => {
        setDiscounts(prevDiscounts => {
            const newDiscounts = { ...prevDiscounts, [chargeId]: discount };
            return newDiscounts;
        });
        calculateTotalToPaid();
    }

    const handleTotalItems = () => {
        const totalTuitions = data?.courses.reduce((acc: number, course: any) => acc + course.tuitions.length, 0);
        const totalArticles = data?.articles.length;
        setItems(totalTuitions + totalArticles);
    }

    useEffect(() => {
        handleTotal();
        handleTotalItems();
        dispatch(buildInvoiceItems());
    }, [data]);

    useEffect(() => {
        calculateTotalToPaid();
    }, [discounts, total]);

    return (
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{ flexGrow: 1 }}>
                    <CustomIcon name="shoppingCart"
                        style={{
                            fill: "#8F91DA",
                            width: "25px",
                            height: "25px"
                        }} />
                    <span style={{
                        paddingLeft: "10px"
                    }}>
                        {Constants.Screens.Finances.modalShopping.cart}
                    </span>
                </h2>
                <button style={{
                    ...styles.btnCloseModal,
                    flexGrow: 0,
                    zIndex: 2
                }}
                    type="button"
                    onClick={closeModal}
                >
                    <FontAwesomeIcon
                        style={{
                            height: "100%",
                            position: "relative",
                            bottom: "1px"
                        }}
                        icon={solid("times")}
                    />
                </button>
            </div>
            <div style={styles.subtitleContainer}>
                <label style={{
                    ...styles.subtitle,
                    position: "relative"
                }}>
                    {Constants.Common.name}:
                </label>
                <span style={styles.boldText}>
                    {student.firstName}
                </span>
            </div>
            <div style={styles.subtitleContainer}>
                <label style={{ ...styles.subtitle, position: "relative" }}>
                    {Constants.Common.lastName}:
                </label>
                <span style={styles.boldText}>
                    {student.lastName}
                </span>
            </div>
            <div style={{
                ...styles.subtitleContainer,
                marginBottom: "10px"
            }}>
                <label style={{ ...styles.subtitle, position: "relative" }}>
                    {Constants.Common.correlative}:
                </label>
                <span style={styles.boldText}>
                    {student.identityCode}
                </span>
            </div>

            {data?.courses.length > 0 ?
                data.courses.map((course: any, index: number) => {
                    return (
                        <div key={`course-row-${index}`} className="row">
                            <div key={`course-col-${index}`} className="col-lg-12" style={{ paddingTop: "10px" }}>
                                <CheckoutTuitionItemComponent
                                    style={{ height: "100%" }}
                                    key={course.courseId}
                                    columns={tuitionColumnsWithLogic}
                                    data={course}
                                />
                            </div>
                        </div>
                    )
                })
                : <React.Fragment></React.Fragment>
            }
            {data?.articles.length > 0 ?
                <div className="row">
                    <div className="col-lg-12" style={{ paddingTop: "10px" }}>
                        <CheckoutArticleItemComponent
                            style={{ height: "100%" }}
                            columns={articleColumnsWithLogic}
                            data={data.articles}
                        />
                    </div>
                </div>
                : <React.Fragment></React.Fragment>
            }
            <hr style={{ border: "2px solid #000000", opacity: 1 }} />
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <label style={{
                        ...styles.subtitleBoldText,
                        position: "relative"
                    }}>
                        {Constants.Common.totalText} : &nbsp;
                    </label>
                    <h2 style={{ display: "inline-block" }}>
                        {currentCampus.currency}{sprintf("%.02f", totalToPaid)}
                    </h2>
                </div>
                {
                    items > 1 && totalToPaid > 0
                        ?
                        <label
                            style={{
                                fontSize: "12px",
                                position: "relative",
                                textTransform: "uppercase",
                                color: "#CA413D"
                            }}>
                            {Constants.Common.noPartialPayments}
                        </label>
                        : <React.Fragment></React.Fragment>
                }
                {
                    items > 1 || totalToPaid <= 0 ?
                        <React.Fragment></React.Fragment>
                        : <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "5px"
                        }}>
                            <button style={{ width: "50%" }}
                                onClick={() => goToProccessPayment(true)}
                                type="button"
                                className="btn btn-outline-danger rounded-pill"
                            >
                                {Constants.Common.partialPaymentText}
                            </button>
                        </div>
                }
                {
                    totalToPaid > 0 ?
                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "15px"
                        }}>
                            <button
                                style={{ width: "50%" }}
                                onClick={() => goToProccessPayment(false)}
                                type="button"
                                className="btn btn-danger rounded-pill"
                            >
                                {Constants.Common.totalPaymentText}
                            </button>
                        </div>
                        : <React.Fragment></React.Fragment>
                }
                {
                    totalToPaid > 0 ? <React.Fragment></React.Fragment>
                        : <div
                            style={{
                                paddingTop: "15px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            <label className="titleLabel">
                                {Constants.Common.generateInvoiceLegend}
                            </label>
                            <button
                                style={{ width: "50%" }}
                                onClick={() => dispatch(setModalContent(FINANCES_MODALS.GENERATE_INVOICE))}
                                type="button"
                                className="btn btn-danger rounded-pill"
                            >
                                {Constants.Common.generateInvoice}
                            </button>
                        </div>
                }
            </div>
        </React.Fragment>
    )
}

export default CheckoutModalComponent;