import Constants
    from "../../../constants"

import "../../../assets/css/variables.css";

const subtitleContainer =
{
    display: "flex",
    alignItems: "baseline",
    justifyContent: "start",
    gap: 10,
    flexFlow: "wrap"
}

const subtitle =
{
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.gray,
    opacity: 1,
    marginLeft: "10px",
    paddingTop: "7px"
}

const subtitleBoldText =
{
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.gray,
    fontWeight: "bold",
    opacity: 1,
    marginLeft: "10px",
    paddingTop: "7px"
}

const boldText =
{
    fontWeight: "bold",
    marginBottom: 0,
    flexGrow: 1,
    paddingRight: "15px"
}

const btnCloseModal =
{
    top: 15,
    right: 15,
    borderRadius: "100%",
    width: 20,
    height: 20,
    border: "1px solid " + Constants.Styles.background.redButtonClose,
    color: Constants.Styles.text.colors.redButtonClose,
    background: Constants.Styles.background.transparent
}

export const styles =
{
    subtitleContainer,
    subtitle,
    subtitleBoldText,
    boldText,
    btnCloseModal
}