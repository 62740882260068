import React, {useRef}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid, regular}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useSelector}
	from "react-redux"
import _
	from "lodash"
import Multiselect
	from "multiselect-react-dropdown"
import {Range}
	from "react-range"
import {isEmpty, revertStringDate}
	from "./../../common"
import {RootState}
	from "./../../globals"
import {SelectReact}
	from "../../components/select"
import
{
	OverlayTrigger,
	Tooltip
}
	from "react-bootstrap"

import "./../../components/dropdown/style.css"
import Constants from "../../constants"

export const unrestrictedResultsDisplay = (customSearch : any)=>
{
	return (customSearch.period == null || customSearch.period.id == null) &&
	(
		!(customSearch.onlyPendingTuitions || customSearch.onlyPendingKits) &&
		(
			isEmpty(customSearch.mentors) && isEmpty(customSearch.courses) && isEmpty(customSearch.mentors) &&
			(
				(
					(
						(customSearch.attendance[0] ==   0 && customSearch.attendance[1] == 101) ||
						(customSearch.attendance[0] == 101 && customSearch.attendance[1] ==   0)
					)
						&&
					(
						(customSearch.subsMin[0] ==   0 && customSearch.subsMin[1] == 126) ||
						(customSearch.subsMin[0] == 126 && customSearch.subsMin[1] ==   0)
					)
				)
					&&
				(customSearch.capacity[0] == 0 || customSearch.capacity[1] == 0)
			)
		)
	)
}

const CalendarSideFilter = (props : any)=>
{
	const 
	{
		periods, courseRef, courses, buildings, mentors, buildingRef, searchChangeFn , customSearch, periodSearch,
		unrestrictedDisplay, availabilityDisplay, setAvailabilityDisplay
	} = props

	const idleLocked : boolean = useSelector((state : RootState) => state.idleLockSwitch.value)
	let   courseSelRef         = useRef<Multiselect | null>(null)
	let   buildingSelRef       = useRef<Multiselect | null>(null)

	const rangeFilterRef : any=
	{
		capacity:
		{
			label    : "Capacidad",
			limit    : 125,
			gradient : "#00FF00 0%, #0000FF 56%, #FFFF00 80%, #FF0000 100%"
		},
		attendance:
		{
			label    : "Asistencias",
			limit    : 100,
			gradient : "#FF0000 0%, #FFFF00 50%, #0000FF 75%, #00FF00 100%"
		},
		subsMin:
		{
			label    : "Ins/Min",
			limit    : 125,
			gradient : "#FF0000 0%, #FFFF00 75%, #0000FF 100%"
		}
	}

	const debtFilters : any[]=
	[
		{
			title     : "Saldos",
			parameter : "onlyPendingTuitions"
		},
		{
			title     : "Saldos kits",
			parameter : "onlyPendingKits"
		}
	]

	const rangeHandlesDisplay = (handleA : number, handleB : number, limit : number)=>
	{
		let andMore = limit + 1

		if(Math.abs(handleA - handleB) == andMore)
		{
			return "Mostrar todo"
		}
		else
		{
			if(handleA == handleB)
			{
				return handleA == andMore ? limit + "%^" : handleA + "%"
			}
			else
			{
				let arrangedData : any = [handleA, handleB].sort((a : number, b : number) => (a - b))

				return arrangedData[0] + " - " + (arrangedData[1] == andMore ? limit + "%^" : arrangedData[1] + "%")
			}
		}
	}

	const subTermsUpdate = (term : any, index : number, tags : any, listingField : string, subListingField : string)=>
	{
		let items = customSearch[listingField]

		items.splice(index, 1, {...term, [subListingField] : tags})

		searchChangeFn
		({
			target:
			{
				name  : listingField,
				value : items
			}
		})
	}

	return <div style={{minWidth : "342px", overflow : "auto", height : "85vh"}}>
		<div className="card card-default" style={{padding : "15px", marginTop : "40px", width : "325px"}}>
			<div style={{display : "flex", alignItems : "stretch"}}>
				<b style={{display : "inline-block", flex : 1, padding : "10px"}}>
					Filtros
				</b>
				<OverlayTrigger overlay=
				  {<Tooltip id="tooltip-activos">
					  Visibilidad de espacios libres:
					  &nbsp;
					  {
						!unrestrictedDisplay
							? "No disponible (Los parámetros de búsqueda personalizada no lo permiten)"
								: (availabilityDisplay ? "Habilitada" : "Deshabilitada")
					  }
				  </Tooltip>}
				>
					<div>
						<button
						  className="btn btn-default"
						  disabled={idleLocked || !unrestrictedDisplay}
						  onClick={() => setAvailabilityDisplay(!availabilityDisplay)}
						  style=
						  {{
							border : "5px dashed #2F316B",
							width : "50px",
							height : "50px",
							background : "#B8FFBC",
							borderRadius : "15px",
							padding : "0px"
						  }}
						>
							<FontAwesomeIcon icon={availabilityDisplay && unrestrictedDisplay ? solid("eye") : solid("eye-slash")} />
						</button>
					</div>
				</OverlayTrigger>
			</div>
			<div>
				<fieldset style={{padding : "0px 5px 5px 5px"}}>
					<legend>
						<label htmlFor="periodId" style={{position : "relative"}}>
							Periodo
						</label>
					</legend>
					<SelectReact disabled={idleLocked} style={{height : "30px"}} name="period" id="period" onChange={periodSearch}
					  clearable={true} value={customSearch.period} className="btn btn-primary text-start" placeholder="Seleccione..."
					  children={periods}
					/>
					{
						customSearch.period == null ? "" : <div style={{textAlign : "center", padding : "5px 5px 0px 5px"}}>
							{revertStringDate(customSearch.period.start)}
							&nbsp;
							-
							&nbsp;
							{revertStringDate(customSearch.period.end)}
						</div>
					}
				</fieldset>
			</div>
			{
				debtFilters.map
				(
					(filter : any) => <div key={filter.parameter}>
						<label htmlFor="buildingIds" style={{position : "relative"}}>
							{filter.title}
						</label>
						<div style={{display : "flex"}}>{
							["Ver todo", "Pendientes"].map
							(
								(label : string, index : number)=>
								{
									const boolVal : boolean = index > 0
									const chosen  : boolean = customSearch[filter.parameter] == boolVal

									return <React.Fragment key={filter.parameter + index}>
										{!boolVal ? "" : <>&emsp;</>}
										<button className={"btn " + (chosen ? "btn-primary" :  "btn-default")} type="button"
										  onClick={() => searchChangeFn({target : {name : filter.parameter, value : boolVal}})}
										  style={{border : "1px solid #8F91DA", color : chosen ? "#FFFFFF" : "#8F91DA", width : "50%"}}
										  disabled={idleLocked || chosen}
										>
											{label}
										</button>
									</React.Fragment>
								}
							)
						}</div>
					</div>
				)
			}
			{
				Object.keys(rangeFilterRef).map
				(
					(key : string) => <div key={key}>
						<label htmlFor="buildingIds" style={{position : "relative"}}>
							{rangeFilterRef[key].label}
						</label>
						<br />
						<div style={{textAlign : "center"}}>
							[{rangeHandlesDisplay(customSearch[key][0], customSearch[key][1], rangeFilterRef[key].limit)}]
						</div>
						<br />
						<Range min={0} max={rangeFilterRef[key].limit + 1} values={customSearch[key]} disabled={idleLocked}
						  onChange={(values : any) => searchChangeFn({target : {name  : key, value : values}})} allowOverlap={true}
						  renderTrack=
						  {
							({props, children} : any) => <div {...props}  key={key} style=
							  {{
								...props.style, background : "linear-gradient(to right, " + rangeFilterRef[key].gradient + ")",
									height                 : "6px",
									width                  : "100%"
							  }}
							>
								{children}
							</div>
						  } renderThumb=
						  {
							({props} : any) => <div {...props} key={key + Math.random()} style=
							  {{...props.style, height : "20px", width : "10px", background : "#2F316B", borderRadius : "15px"}}
							/>
						  }
						/>
						<br />
						<br />
					</div>
				)
			}
			<div><label htmlFor="buildingIds" style={{position : "relative"}}>
				Profesores
			</label></div>
			<Multiselect onSelect={(tags) => searchChangeFn({target : {name : "mentors", value : tags}})} displayValue="name"
			  options={mentors} selectedValues={customSearch.mentors} emptyRecordMsg="No hay más opciones" disable={idleLocked}
			  onRemove={(tags) => searchChangeFn({target : {name : "mentors", value : tags}})} placeholder="Seleccione"
			/>
			<hr />
			<div><label htmlFor="buildingIds" style={{position : "relative"}}>
				Cursos
			</label></div>
			<div style={{padding : "0px 15px"}} className="nestedSelection">
				<Multiselect
				  options={courses}
				  displayValue="name"
				  placeholder="Seleccione..."
				  emptyRecordMsg="No hay más opciones"
				  ref={courseSelRef} disable={idleLocked}
				  selectedValues={[...customSearch.courses]}
				  onSelect={(courses) => searchChangeFn({target : {name : "courses", value : [...courses]}})}
				  onRemove={(courses) => searchChangeFn({target : {name : "courses", value : [...courses]}})}
				/>
			</div>
			{
				customSearch.courses.length < 1 ? "" : <>
					<hr />
					{
						customSearch.courses.map
						(
							(course : any, index : any) => <div key={"C_" + course.id} style={{padding : "5px 10px"}}>
								<div style=
								  {{
									background   : "#F5F5F5",
									borderRadius : "15px 15px 0px 0px"
								  }}
								><table style={{width : "100%"}}><tbody><tr>
									<td style={{padding : "8px 15px", width : "100%"}}>
										{course?.name}
									</td>
									<td>
										<button
										  className="btn btn-default"
										   disabled={idleLocked}
										  type="button"
										  onClick=
										  {
											()=>
											{
												let courses = customSearch.courses

												courses.splice(index, 1)

												if(courseSelRef && courseSelRef.current)
												{
													courseSelRef
														.current
														.resetSelectedValues()
														.then
														(
															()=>
															{
																searchChangeFn
																({
																	target:
																	{
																		name  : "courses",
																		value : courses
																	}
																})
															}
														)
												}
											}
										  }
										>
											<FontAwesomeIcon icon={solid("times")} />
										</button>
									</td>
								</tr></tbody></table></div>
								<fieldset style=
								  {{
									borderRadius : "0px 0px 15px 15px", padding :"0px 10px 10px", position : "relative",
									borderTop    : "unset"
								  }}
								>{
									courseRef["C_" + course.id].levels.length < 2
											?
										<div className="bg-info text-white"
										  style={{padding : "5px", borderRadius : "0px 0px 15px 15px"}}
										>
											<FontAwesomeIcon icon={solid("circle-info")} />
											&nbsp;
											El curso seleccionado no tiene ningún módulo asociado.
										</div>
											:
										<>
											<legend style={{paddingLeft : "15px", position : "absolute"}}>
												<label style={{position : "relative", paddingTop : "2px"}}>
													{Constants.Common.modules}
												</label>
											</legend>
											<div style={{paddingTop : "30px"}}>
												<Multiselect
												  displayValue="name"
												  options={courseRef["C_" + course.id].levels}
												  selectedValues={[...course.levels]}
												  emptyRecordMsg="No hay más opciones" placeholder="Especifique..."
												  onRemove={(levels : any) => subTermsUpdate(course, index, levels, "courses", "levels")}
												  onSelect={(levels : any) => subTermsUpdate(course, index, levels, "courses", "levels")}
												/>
											</div>
										</>
								}</fieldset>
							</div>
						)
					}
				</>
			}
			<hr />
			<div>
				<label
				  htmlFor="buildingIds"
				  style={{position : "relative"}}
				>
					Edificios
				</label>
			</div>
			<div style={{padding : "0px 15px"}} className="nestedSelection">
				<Multiselect
				  displayValue="name"
				  ref={buildingSelRef}
				  placeholder="Seleccione..." 
				  emptyRecordMsg="No hay más opciones"
				  options={buildings} selectedValues={[...customSearch.buildings]}
				  onSelect={(tags) => {searchChangeFn({target : {name : "buildings", value : tags}})}}
				  onRemove={(tags)=>{searchChangeFn({target : {name : "buildings", value : tags}})}}
				/>
			</div>
			{
				customSearch.buildings.length < 1 ? "" : <>
					<hr />
					{
						customSearch.buildings.filter((building : any) => building).map
						(
							(building : any, index : any) => <div key={"B_" + building.id} style={{padding : "5px 10px"}}>
								<div style={{background : "#F5F5F5", borderRadius : "15px 15px 0px 0px"}}>
									<table style={{width : "100%"}}>
										<tbody>
											<tr>
												<td style={{padding : "8px 15px", width : "100%"}}>
													{building?.name}
												</td>
												<td>
													<button
													  className="btn btn-default"
													  disabled={idleLocked}
													  type="button"
													  onClick=
													  {
														()=>
														{
															let buildings = customSearch.buildings

															buildings.splice(index, 1)

															if(buildingSelRef && buildingSelRef.current)
															{
																buildingSelRef
																	.current
																	.resetSelectedValues()
																	.then
																	(
																		()=>
																		{
																			searchChangeFn
																			({
																				target:
																				{
																					name  : "buildings",
																					value : buildings
																				}
																			})
																		}
																	)
															}
														}
													  }
													>
														<FontAwesomeIcon icon={solid("times")} />
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<fieldset style=
								  {{
									borderTop    : "unset",
									borderRadius : "0px 0px 15px 15px",
									padding      :"0px 10px 10px",
									position     : "relative"
								  }}
								>{
									buildingRef["B_" + building.id].classrooms.length < 2
										?
									<div className="bg-info text-white"
									  style={{padding : "5px", borderRadius : "0px 0px 15px 15px"}}
									>
										<FontAwesomeIcon icon={solid("circle-info")} />
										&nbsp;
										El edificio seleccionado no tiene ningún aula asociada.
									</div>
										:
									<>
										<legend style={{paddingLeft : "15px", position : "absolute"}}>
											<label style={{position : "relative", paddingTop : "2px"}}>
												Aulas
											</label>
										</legend>
										<div style={{paddingTop : "30px"}}>
											<Multiselect
											  displayValue="name"
											  placeholder="Especifique..."
											  emptyRecordMsg="No hay más opciones"
											  selectedValues={building.classrooms}
											  options={[...buildingRef["B_" + building.id].classrooms]}
											  onRemove={(classrooms : any) => subTermsUpdate(building, index, classrooms, "buildings", "classrooms")}
											  onSelect={(classrooms : any) => subTermsUpdate(building, index, classrooms, "buildings", "classrooms")}
											/>
										</div>
									</>
								}</fieldset>
							</div>
						)
					}
				</>
			}
		</div>
	</div>
}

export default CalendarSideFilter
