import Constants from "../../constants"

const header =
{
    display: "flex",
    marginLeft: 25
};

const subtitleContainer =
{
    display: "flex",
    alignItems: "baseline",
    justifyContent: "start",
    gap: 10,
    flexFlow: "wrap"
};

const subtitle =
{
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.gray,
    opacity: 1,
    marginLeft: "10px",
    paddingTop: "7px"
};

const periodText =
{
    color: Constants.Styles.text.colors.orange,
    marginBottom: 0,
    flexGrow: 1
}

const boldText =
{
    fontWeight: "bold",
    marginBottom: 0,
    flexGrow: 1,
    paddingRight: "15px"
}

const courseText =
{
    font: Constants.Styles.text.fonts.robotoBold20
}

const totalSection =
{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexFlow: "wrap",
    marginTop: 10,
    marginRight: 10,
    gap: 5
}

const totalContainer =
{
    display: "flex",
    alignItems: "center",
    flexFlow: "wrap"
}

const totalText =
{
    color: Constants.Styles.text.colors.totalGray,
    font: Constants.Styles.text.fonts.robotoBold15,
    marginBottom: 0
}

const totalTextNumber =
{
    color: Constants.Styles.text.colors.red,
    font: Constants.Styles.text.fonts.robotoBold20,
    marginBottom: 0
}

export const styles =
{
    header,
    subtitleContainer,
    subtitle,
    periodText,
    boldText,
    courseText,
    totalSection,
    totalContainer,
    totalText,
    totalTextNumber
}