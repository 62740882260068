import React, {
    Suspense,
    lazy
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../../globals";
import { setShowModal, setShowTransferCreditModal } from "../../../../redux/reducers/accountStatementReducer";
import Constants, {
    articleType,
    FINANCES_MODALS,
    PERMISSIONS_CODES,
    TRANSFER_MODAL_CONTENT
} from "../../../../constants";
import Header from "./header";
import AccountStatementCard from "../../../../components/accountStatementCard";
import ArticleCardComponent from "../../../../components/articlesCard";
import CheckoutModalComponent from "../../../../components/modals/checkout/checkout";
import InvoiceDetailModalComponent from "../../../../components/modals/invoiceDetails/invoiceDetails";
import GenerateInvoiceModalComponent from "../../../../components/modals/generateInvoice/generateInvoice";
import PaymentDetailModalComponent from "../../../../components/modals/payments/detail/paymentDetails";
import EmitPaymentModalComponent from "../../../../components/modals/payments/create/emitPayment";
import CancelInvoiceModalComponent from "../../../../components/modals/cancelInvoice/cancelInvoice";
import CancelPaymentModalComponent from "../../../../components/modals/payments/cancel/cancelPayment";
import UnderlineHeading from "../../../../components/underlineHeading";
import ShoppingCartButtonComponent from "../../../../components/buttons/shoppingCartButton/shoppingCartButton";
import { FinancesModalsTypes, TransferModalsTypes } from "../../../../types";
import ApplyCreditModalComponent from "../../../../components/modals/applied_credits/create/applyCredit";
import CreditDetailModalComponent from "../../../../components/modals/applied_credits/detail/creditDetail";
import SelectStudentComponent from "../../../../components/transferCredit/selectStudent/selectStudent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import TransferCreditModalComponent from "../../../../components/transferCredit/transferModal/transferCredit";

const AsideModalComponent = lazy(() => import("../../../../components/modals/asideModal"));
const ModalComponent = lazy(() => import("../../../../components/modals/modal/modal"));

const AccountStatementView = (props: any) => {
    const {
        accountStatementProps,
        tableProps,
        checkoutDataProps,
        invoiceDetailProps,
        paymentDetailProps,
        emitPaymentProps,
        cancelPaymentProps,
        generateInvoiceProps,
        cancelInvoiceProps,
        applyCreditProps,
        appliedCreditsDetailProps,
        transferCreditProps
    } = props

    const dispatch = useDispatch<AppDispatch>();
    const permissions: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.permissions);
    const showModal: boolean = useSelector((state: RootState) => state.rootReducer.accountStatement.showModal);
    const showTransferCreditModal: boolean = useSelector((state: RootState) => state.rootReducer.accountStatement.showTransferCreditModal);
    const modalContent: any = useSelector((state: RootState) => state.rootReducer.accountStatement.modalContent);
    const transferModalContent: any = useSelector((state: RootState) => state.rootReducer.accountStatement.transferModalContent);
    const generalData: any = useSelector((state: RootState) => state.rootReducer.accountStatement.generalData);
    const toStudent: any = useSelector((state: RootState) => state.rootReducer.accountStatement.studentWhoReceives);
    const payment: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedPayment);
    const invoice: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedInvoice);
    const credit: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedCredit);
    const itemsInInvoice: any = useSelector((state: RootState) => state.rootReducer.accountStatement.invoiceItems);
    const totalToPaid: any = useSelector((state: RootState) => state.rootReducer.accountStatement.totalToPaid);
    const totalCheckoutItems: number = useSelector((state: RootState) => state.rootReducer.accountStatement.totalCheckoutItems);

    const renderModalContent = (children: FinancesModalsTypes) => {
        switch (children) {
            case FINANCES_MODALS.CHECKOUT:
                return (
                    <CheckoutModalComponent
                        student={generalData?.student}
                        data={checkoutDataProps.checkoutData}
                        closeModal={checkoutDataProps.closeModal}
                        goToProccessPayment={checkoutDataProps.goToProccessPayment}
                    />
                );
            case FINANCES_MODALS.INVOICE:
                return (
                    <InvoiceDetailModalComponent
                        student={generalData?.student}
                        invoice={invoice}
                        closeModal={invoiceDetailProps.closeModal}
                        showPaymentDetail={invoiceDetailProps.showPaymentDetail}
                        showCreditDetail={invoiceDetailProps.showCreditDetail}
                        cancelInvoice={invoiceDetailProps.cancelInvoice}
                        proccessPayment={invoiceDetailProps.proccessPayment}
                    />
                );
            case FINANCES_MODALS.GENERATE_INVOICE:
                return (
                    <GenerateInvoiceModalComponent
                        onSuccess={generateInvoiceProps.onSuccess}
                        closeModal={generateInvoiceProps.closeModal}
                    />
                );
            case FINANCES_MODALS.CANCEL_INVOICE:
                return (
                    <CancelInvoiceModalComponent
                        student={generalData?.student}
                        invoice={invoice}
                        closeModal={cancelInvoiceProps.closeModal}
                        onSuccess={cancelInvoiceProps.onSuccess}
                    />
                );
            case FINANCES_MODALS.PAYMENT_DETAIL:
                return (
                    <PaymentDetailModalComponent
                        student={generalData?.student}
                        payment={payment}
                        closeModal={paymentDetailProps.closeModal}
                        handleCancelPayment={paymentDetailProps.handleCancelPayment}
                    />
                );
            case FINANCES_MODALS.EMIT_PAYMENT:
                return (
                    <EmitPaymentModalComponent
                        student={generalData?.student}
                        invoice={invoice}
                        itemsInInvoice={itemsInInvoice}
                        totalToPaid={totalToPaid}
                        isPartialPayment={emitPaymentProps.isPartialPayment}
                        isNewInvoice={emitPaymentProps.isNewInvoice}
                        closeModal={emitPaymentProps.closeModal}
                        cancelEmitPayment={emitPaymentProps.cancelEmitPayment}
                        onSuccess={emitPaymentProps.onSuccess}
                        showApplyCreditModal={emitPaymentProps.showApplyCreditModal}
                    />
                );
            case FINANCES_MODALS.CANCEL_PAYMENT:
                return (
                    <CancelPaymentModalComponent
                        student={generalData?.student}
                        payment={payment}
                        closeModal={cancelPaymentProps.closeModal}
                        onSuccess={cancelPaymentProps.onSuccess}
                    />
                );
            case FINANCES_MODALS.APPLY_CREDIT:
                return (
                    <ApplyCreditModalComponent
                        student={generalData?.student}
                        invoice={invoice}
                        itemsInInvoice={itemsInInvoice}
                        totalToPaid={totalToPaid}
                        isPartialPayment={applyCreditProps.isPartialPayment}
                        isNewInvoice={applyCreditProps.isNewInvoice}
                        closeModal={applyCreditProps.closeModal}
                        cancelApplyCredit={applyCreditProps.cancelApplyCredit}
                        onSuccess={applyCreditProps.onSuccess}
                    />
                );
            case FINANCES_MODALS.APPLY_CREDIT_DETAILS:
                return (
                    <CreditDetailModalComponent
                        credit={credit}
                        closeModal={appliedCreditsDetailProps.closeModal}
                    />
                );
            default:
                dispatch(setShowModal(false));
                return null;
        }
    }

    const renderTransferModalContent = (children: TransferModalsTypes) => {
        switch (children) {
            case TRANSFER_MODAL_CONTENT.SELECT_STUDENT:
                return (
                    <SelectStudentComponent
                        fromStudent={generalData?.student}
                    />
                );
            case TRANSFER_MODAL_CONTENT.TRANSFER:
                return (
                    <TransferCreditModalComponent
                        fromStudent={generalData?.student}
                        toStudent={toStudent}
                        closeModal={transferCreditProps.closeModal}
                        onSuccess={transferCreditProps.onSuccess}
                    />
                );
            default:
                dispatch(setShowTransferCreditModal(false));
                return null;
        }
    }

    const renderTransferPositiveBalance = () => {
        if (permissions.includes(PERMISSIONS_CODES.CREDITS_PERMISSIONS.CREATE)) {
            return generalData?.balance ?
                <div
                    className="row"
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "30px"
                    }}>
                    <div
                        className="col-lg-12"
                        style={{ paddingTop: "30px" }}>
                        <button type="button"
                            onClick={transferCreditProps.showModal}
                            disabled={generalData?.balance.positiveBalance.value <= 0}
                            className="btn btn-outline-success"
                            style={{
                                borderRadius: "25px",
                                display: "flex"
                            }} >
                            <FontAwesomeIcon
                                icon={solid("money-bill-transfer")}
                                style={{
                                    marginTop: "4px"
                                }} />
                            <span
                                className="d-xs-none d-sm-none d-lg-none d-xl-block"
                                style={{
                                    paddingLeft: "10px"
                                }}>
                                {Constants.Common.transferCredit}
                            </span>
                        </button>
                    </div>
                </div>
                : <React.Fragment />
        }
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            {!showModal && totalCheckoutItems > 0
                ? <ShoppingCartButtonComponent
                    totalItems={totalCheckoutItems}
                    onClickEvent={accountStatementProps.showCheckoutModal} />
                : <React.Fragment />
            }
            <div className="transition-width"
                style={{
                    width: `calc(100% - ${showModal ? "648px" : "0px"})`
                }}>
                {
                    generalData?.balance && generalData?.student
                        ? <Header
                            balance={generalData.balance}
                            student={generalData.student} />
                        : <React.Fragment />
                }
                {
                    renderTransferPositiveBalance()
                }
                {generalData?.courses.length > 0 ?
                    generalData.courses.map((course: any, index: number) => {
                        return (
                            <div key={`course-row-${index}`}
                                className="row"
                                style={{
                                    paddingLeft: "30px",
                                    paddingRight: "30px"
                                }}>
                                <div key={`course-col-${index}`}
                                    className="col-lg-12"
                                    style={{ paddingTop: "30px" }}>
                                    <AccountStatementCard
                                        key={course.courseId}
                                        columns={tableProps.tuitionColumns}
                                        data={course}
                                    />
                                </div>
                            </div>
                        )
                    })
                    : <React.Fragment />
                }
                <div className="row"
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        marginBottom: "30px"
                    }}>
                    <div className="col-lg-6"
                        style={{ paddingTop: "30px" }}>
                        <ArticleCardComponent
                            columns={tableProps.articleColumns}
                            data={generalData.articles}
                            header={Constants.Screens.Finances.accountStatement.kitBalance}
                            type={articleType.STANDARD_KIT}
                        />
                    </div>
                    <div className="col-lg-6"
                        style={{ paddingTop: "30px" }}>
                        <ArticleCardComponent
                            columns={tableProps.articleColumns}
                            data={generalData.articles}
                            header={Constants.Screens.Finances.accountStatement.otherBalance}
                            type={articleType.OTHERS}
                        />
                    </div>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                    <AsideModalComponent
                        showModal={showModal}
                        location="right"
                        style={{ width: "648px" }}>
                        {renderModalContent(modalContent)}
                    </AsideModalComponent>
                </Suspense>

                <Suspense fallback={<div>Loading...</div>}>
                    <ModalComponent
                        title={Constants.Common.transferCredit}
                        showModal={showTransferCreditModal}
                        onClose={transferCreditProps.closeModal}>
                        {renderTransferModalContent(transferModalContent)}
                    </ModalComponent>
                </Suspense>

                <div className="row"
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "30px"
                    }}>
                    <UnderlineHeading
                        name={Constants.Common.historial} />
                </div>

                {generalData?.coursesHistory.length > 0 ?
                    generalData.coursesHistory.map((course: any, index: number) => {
                        return (
                            <div key={`course-row-${index}`}
                                className="row"
                                style={{
                                    paddingLeft: "30px",
                                    paddingRight: "30px"
                                }}>
                                <div key={`course-col-${index}`}
                                    className="col-lg-12"
                                    style={{ paddingTop: "30px" }}>
                                    <AccountStatementCard
                                        key={course.courseId}
                                        columns={tableProps.tuitionColumns}
                                        data={course}
                                    />
                                </div>
                            </div>
                        )
                    })
                    : <React.Fragment />
                }

                <div className="row"
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        marginBottom: "30px"
                    }}>
                    <div className="col-lg-6"
                        style={{ paddingTop: "30px" }}>
                        <ArticleCardComponent
                            columns={tableProps.articleColumns}
                            data={generalData.articlesHistory}
                            header={Constants.Screens.Finances.accountStatement.kitBalance}
                            type={articleType.STANDARD_KIT}
                        />
                    </div>
                    <div className="col-lg-6"
                        style={{ paddingTop: "30px" }}>
                        <ArticleCardComponent
                            columns={tableProps.articleColumns}
                            data={generalData.articlesHistory}
                            header={Constants.Screens.Finances.accountStatement.otherBalance}
                            type={articleType.OTHERS}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccountStatementView;