import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Constants from "../../../constants";
import { styles } from "./style";
import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../globals";
import { StudentDTO } from "../../../models/StudentDTO";
import { useForm } from "react-hook-form";
import HttpManager from "../../../services/HttpManager";
import { getErrorMessage } from "../../../common";


export interface TransferCreditProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
    * Student who transfer credit.
    */
    fromStudent: StudentDTO;

    /**
    * Student who transfer credit.
    */
    toStudent: StudentDTO;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
    /**
     * 
     * @function on success cancel invoice
     */
    onSuccess: () => void
}

interface TransferCreditFormData {
    toStudentId: StudentDTO,
    amount: number,
    notes: string;
}

const TransferCreditModalComponent: React.FC<TransferCreditProps> = ({
    fromStudent,
    toStudent,
    closeModal,
    onSuccess
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const http = HttpManager.getInstance();
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const [total, setTotal] = useState<number>(0);
    const [startDate, setStartDate] = useState<string>(
        new Date().toLocaleDateString('en-CA')
    );

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm<TransferCreditFormData>();

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    }

    const transferCredit = async (data: TransferCreditFormData) => {
        try {
            dispatch(enableIdleLockSwitch());
            const payload = {
                ...data,
                toStudentId: toStudent.id
            };
            const response =
                await http.appliedCreditService.studentTransfersCredit(
                    currentCampus.id,
                    fromStudent.id,
                    payload
                )
            reset();
            onSuccess();
        } catch (error) {
            console.error("Error submitting form:", error);
            onError(getErrorMessage(error));
        } finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const validatePartialAmount = (value: any) => {
        const MAX = fromStudent?.generalBalance?.positiveBalance?.value;

        if (value < 1) {
            return 'El valor no puede ser menor a 1';
        }
        if (value > MAX) {
            return `El valor no puede ser mayor a ${MAX}`;
        }

        return true;
    }

    const handlePartialAmount = (e: any) => {
        const MAX = fromStudent?.generalBalance?.positiveBalance?.value;

        let value = parseFloat(e.target.value);
        if (value > MAX) {
            value = MAX;
        }

        if (value < 1) {
            value = 1;
        }
        setValue('amount', value);
        setTotal(value);
    }

    return (
        fromStudent &&
        <React.Fragment>
            <div style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px"
            }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "10px",
                    padding: "10px"
                }}>
                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <h5
                            style={{
                                display: "inline-block",
                                width: "70%",
                                textAlign: "left"
                            }}>
                            {Constants.Common.from}:
                        </h5>
                        <label htmlFor="name"
                            style={{
                                ...styles.smallGrayText,
                                position: "relative"
                            }}>
                            {Constants.Common.student}
                        </label>
                        <div className="roView"
                            style={{
                                borderBottom: "1px solid #7678BF"
                            }}>
                            {fromStudent?.firstName}
                            &nbsp;
                            {fromStudent?.lastName}
                        </div>
                    </div>
                    <div style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left"
                    }}>
                        <label htmlFor="name"
                            style={{
                                ...styles.smallGrayText,
                                position: "relative"
                            }}>
                            {Constants.Common.correlative}
                        </label>
                        <div style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                            {fromStudent?.correlative}
                        </div>
                    </div>
                </div>

                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "10px",
                    padding: "10px"
                }}>
                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <h5
                            style={{
                                display: "inline-block",
                                width: "70%",
                                textAlign: "left"
                            }}>
                            {Constants.Common.to}:
                        </h5>
                        <label htmlFor="name"
                            style={{
                                ...styles.smallGrayText,
                                position: "relative"
                            }}>
                            {Constants.Common.student}
                        </label>
                        <div className="roView"
                            style={{
                                borderBottom: "1px solid #7678BF"
                            }}>
                            {toStudent.firstName}
                            &nbsp;
                            {toStudent.lastName}
                        </div>
                    </div>
                    <div style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left"
                    }}>
                        <label htmlFor="name"
                            style={{
                                ...styles.smallGrayText,
                                position: "relative"
                            }}>
                            {Constants.Common.correlative}
                        </label>
                        <div style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                            {toStudent.correlative}
                        </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit(transferCredit)}
                    className="form"
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}>
                    <div style={{
                        background: "#F1F1FF",
                        padding: "15px",
                        display: "flex"
                    }}>
                        <div
                            style={{
                                display: "flex",
                                width: "50%",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "end"
                            }}>
                            <h5
                                style={{
                                    display: "inline-block",
                                    width: "100%",
                                    textAlign: "center"
                                }}>
                                {`${Constants.Common.creditToUse}(${currentCampus.currencyCode})`}
                            </h5>
                            <label
                                style={{
                                    ...styles.smallGrayText,
                                    display: "inline-block",
                                    color: Constants.Styles.text.colors.blackPrimary,
                                    width: "100%",
                                    position: "relative",
                                    textAlign: "center"
                                }}>
                                {
                                    `${Constants.Common.max}: ${fromStudent.generalBalance.positiveBalance.moneyFormat}`
                                }
                            </label>
                        </div>
                        <div style={{
                            display: "flex",
                            width: "40%",
                            alignItems: "center"
                        }}>
                            <input
                                id="total"
                                {
                                ...register('amount', {
                                    required: 'El monto es requerido.',
                                    min: {
                                        value: 1,
                                        message: 'El valor debe ser mayor a 0',
                                    },
                                    max: {
                                        value: total,
                                        message: `El valor debe ser menor o igual a ${total}`,
                                    },
                                    validate: value => validatePartialAmount(value),
                                })}
                                onChange={(e: any) => handlePartialAmount(e)}
                                value={total}
                                type="number"
                                style={{
                                    display: "inline-block",
                                    width: "100%",
                                    height: "40px",
                                    textAlign: "right",
                                    borderBottom: "2px solid #7678BF"
                                }}
                            />
                        </div>
                    </div>
                    {errors.amount &&
                        <div style={{
                            display: "flex",
                            alignItems: "flex-end",
                            width: "100%"
                        }}>
                            <p style={{
                                ...styles.errorMessage,
                            }}>
                                {errors.amount.message}
                            </p>
                        </div>
                    }
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                        <label htmlFor="notes"
                            style={{
                                ...styles.smallGrayText,
                                position: "relative"
                            }}>
                            {Constants.Common.notes}
                        </label>
                        <textarea id="notes"
                            {
                            ...register('notes',
                                {
                                    required: 'El campo notas es requerido.',
                                    minLength: {
                                        value: 10,
                                        message: 'La nota debe tener al menos 10 caracteres'
                                    }
                                }
                            )}
                            name="notes"
                            placeholder={Constants.Common.notes}
                            style={{
                                resize: "vertical",
                                width: "100%",
                                border: "1px solid #7678BF",
                                borderRadius: "5px",
                                minHeight: "150px",
                                padding: "5px"
                            }}
                        />
                        {errors.notes &&
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {errors.notes.message}
                            </p>
                        }
                    </div>

                    <br />
                    <div style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "10px"
                    }}>
                        <button className="btn btn-outline-danger rounded-pill"
                            type="submit"
                            disabled={idleLocked}
                            style={{
                                width: "50%"
                            }}>
                            {Constants.Common.transferCredit}
                        </button>
                        <button
                            onClick={closeModal}
                            disabled={idleLocked}
                            type="button"
                            className="btn btn-outline-default rounded-pill"
                            style={{
                                width: "50%",
                                border: "1px solid #000000"
                            }} >
                            {Constants.Common.close}
                        </button>
                    </div>
                </form>
            </div>

        </React.Fragment>
    );

}

export default TransferCreditModalComponent;