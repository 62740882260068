import { axiosInstance } from "./axiosInstance";

const RESOURCE_URL_V2 = process.env.REACT_APP_API_URL_V2 + "/admin/";

export class CampusService {

    getCampuses = async () => {
        let uri = `${RESOURCE_URL_V2}campuses`;
        let response = await axiosInstance.get(uri);
        return response;
    }

    getCampusById = async (campusId: number) => {
        let uri = `${RESOURCE_URL_V2}${campusId}`;
        let response = await axiosInstance.get(uri);
        return response;
    }

}