import React, { useEffect, useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Constants from '../../constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface FileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
    allowedFiletypes: string[];
    onFilesChange: any
    // onChange: (discount: number) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ allowedFiletypes, onFilesChange }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const allowedTypesString = allowedFiletypes.map((ft: any) => ft.type).join(', ');
    const tooltip = `Solo se permiten archivos del tipo: ${allowedTypesString}`;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            const validFiles: File[] = [];
            const maxFileSizeMB = 25 * 1024 * 1024; // 25MB en bytes
            let invalidFileAlert = '';

            filesArray.forEach((file) => {
                const isFileTypeAllowed = allowedFiletypes.some((ft: any) => ft.file === file.type);
                if (isFileTypeAllowed && file.size <= maxFileSizeMB) {
                    validFiles.push(file);
                } else {
                    invalidFileAlert += `El archivo ${file.name} no es válido. Asegúrate de que sea del tipo correcto (${allowedTypesString}) y no exceda los 25 MB.\n`;
                }
            });

            if (invalidFileAlert) {
                alert(invalidFileAlert);
            }

            if (validFiles.length > 0) {
                setSelectedFiles([...selectedFiles, ...validFiles]);
            }
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        document.getElementById('fileInput')?.click();
    };

    const handleRemoveFile = (index: number) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    }

    useEffect(() => {
        onFilesChange([...selectedFiles]);
    }, [selectedFiles]);

    return (
        <div>
            <OverlayTrigger overlay={
                <Tooltip>
                    {tooltip}
                </Tooltip>
            }>
                <div
                    // disabled={idleLocked}
                    style={{
                        width: "60%",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        border: "2px dotted #000000"
                    }}>
                    <input
                        id="fileInput"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{
                            display: "none"
                        }}
                    />
                    <button
                        type="button"
                        className="btn btn-default"
                        onClick={handleClick}
                        // style={{
                        //     background: "#F1F1FF",
                        //     border: "2px dashed #8F91DA",
                        //     width: "100%",
                        //     color: "#8F91DA"
                        // }}
                    >
                        <FontAwesomeIcon icon={solid("arrow-up-from-bracket")} />
                        {Constants.Screens.Finances.modalPayments.uploadFile}
                    </button>
                </div>
            </OverlayTrigger>
            {selectedFiles.length > 0 && (
                <div>
                    <ul style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        padding: "0"
                    }}>
                        {selectedFiles.map((file, index) => (
                            <li key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    padding: "0",
                                    columnGap: "10px"
                                }}>
                                {file.name}
                                <button
                                    type="button"
                                    onClick={() => handleRemoveFile(index)}
                                    style={{
                                        fontSize: "20px",
                                        backgroundColor: "transparent"
                                    }}>
                                    <FontAwesomeIcon icon={solid("times")} />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )
            }
        </div >
    );
};

export default FileUploadComponent;
