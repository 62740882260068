import { Badge } from "react-bootstrap"
import CustomIcon from "../../customIcon"
import Constants from "../../../constants"
import { styles } from "./style";

export interface ShoppingCartButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    totalItems: number;
    onClickEvent: () => void;
}

const ShoppingCartButtonComponent: React.FC<ShoppingCartButtonProps> = ({ totalItems, onClickEvent }) => {
    return (
        <button
            onClick={onClickEvent}
            style={styles.btnContainer}>
            <div style={styles.badgeContainer}>
                <CustomIcon name="shoppingCart"
                    style={{
                        fill: "#8F91DA",
                        width: "50%",
                        height: "50%"
                    }} />
                <Badge bg="primary"
                    style={styles.badge}>
                    {totalItems}
                </Badge>
            </div>
        </button>
    )
}

export default ShoppingCartButtonComponent