import {
    createSlice,
    current,
    PayloadAction
} from '@reduxjs/toolkit';
import {
    owedTuitionStatus,
    TYPE_OF_DISCOUNT
} from '../../constants';
import { GeneralBalanceDTO } from '../../models/GeneralBalanceDTO';
import { ArticleDTO } from '../../models/ArticleDTO';
import { CourseTuitionDTO } from '../../models/CourseTuitionDTO';
import { StudentDTO } from '../../models/StudentDTO';

interface Tuition {
    id: number;
    discount: number;
    typeOfDiscount: number;
}

interface Article {
    id: number;
    discount: number;
    typeOfDiscount: number;
}

interface InvoiceItems {
    tuitions: Set<Tuition>;
    articles: Set<Article>;
}

const invoiceItems: InvoiceItems = {
    tuitions: new Set<Tuition>(),
    articles: new Set<Article>()
}

const generalBalance = {
    currency: '',
    expiredMonthlyBalance: {
        value: 0,
        moneyFormat: ''
    },
    monthlyBalance: {
        value: 0,
        moneyFormat: ''
    },
    kitsBalance: {
        value: 0,
        moneyFormat: ''
    },
    othersBalance: {
        value: 0,
        moneyFormat: ''
    },
    positiveBalance: {
        value: 0,
        moneyFormat: ''
    }
}

const generalData: {
    student: StudentDTO | null;
    courses: CourseTuitionDTO[];
    articles: ArticleDTO[];
    coursesHistory: CourseTuitionDTO[];
    articlesHistory: ArticleDTO[];
    balance: GeneralBalanceDTO;
} = {
    student: null,
    courses: [],
    articles: [],
    coursesHistory: [],
    articlesHistory: [],
    balance: generalBalance
};

const initialState: any = {
    refresh: false,
    showModal: false,
    showTransferCreditModal: false,
    transferModalContent: 0,
    modalContent: 0,
    studentData: {},
    studentWhoReceives:{},
    generalData,
    checkoutData: {},
    selectedInvoice: null,
    selectedCredit: null,
    selectedCourses: [],
    selectedTuitions: new Set<number>(),
    disabledTuitions: new Set<number>(),
    selectedArticles: [],
    checkedArticles: new Set<number>(),
    cancelableInvoices: new Set<number>(),
    totalToPaid: 0,
    isPartialPayment: false,
    isCancelPayment: false,
    isNewInvoice: true,
    error: null,
    selectedRowData: null,
    invoiceItems,
    totalCheckoutItems: 0
}

const accountStatementSlice = createSlice({
    name: 'accountStatement',
    initialState,
    reducers: {
        setRefresh: (state, action) => {
            state.refresh = action.payload;
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload;
        },
        setShowTransferCreditModal: (state, action) => {
            state.showTransferCreditModal = action.payload;
        },
        setModalContent: (state, action) => {
            state.modalContent = action.payload;
        },
        setTransferModalContent: (state, action) => {
            state.transferModalContent = action.payload;
        },
        setGeneralData: (state, action) => {
            state.generalData = action.payload;
        },
        setStudentData: (state, action) => {
            state.studentData = action.payload;
        },
        setStudentWhoReceives: (state, action) => {
            state.studentWhoReceives = action.payload;
        },
        setCheckoutData: (state, action) => {
            state.checkoutData = action.payload;
        },
        setSelectedInvoice: (state, action) => {
            state.selectedInvoice = action.payload;
        },
        setSelectedPayment: (state, action) => {
            state.selectedPayment = action.payload;
        },
        setIsNewInvoice: (state, action) => {
            state.isNewInvoice = action.payload;
        },
        setSelectedCredit: (state, action) => {
            state.selectedCredit = action.payload;
        },
        setTotalToPaid: (state, action) => {
            state.totalToPaid = action.payload;
        },
        setIsPartialPayment: (state, action) => {
            state.isPartialPayment = action.payload;
        },
        setIsCancelPayment: (state, action) => {
            state.isCancelPayment = action.payload;
        },
        setDisabledTuitions: (state, action: PayloadAction<any>) => {
            state.disabledTuitions = action.payload;
        },
        setSelectedCourses: (state, action: PayloadAction<any>) => {
            state.selectedCourses = action.payload;
        },
        setSelectedTuitions: (state, action: PayloadAction<any>) => {
            state.selectedTuitions = action.payload;
        },
        setSelectedArticles: (state, action: PayloadAction<any>) => {
            state.selectedArticles = action.payload;
        },
        setCheckedArticles: (state, action: PayloadAction<any>) => {
            state.checkedArticles = action.payload;
        },
        setRefundItems: (state, action: PayloadAction<any>) => {
            state.refundItems = action.payload;
        },
        setTotalCheckoutItems: (state, action: PayloadAction<any>) => {
            state.totalCheckoutItems = action.payload;
        },
        handleArticlesSelection: (state, action: PayloadAction<any>) => {
            const article = action.payload;
            if (state.checkedArticles.has(article.id)) {
                state.checkedArticles.delete(article.id);
                state.selectedArticles = state.selectedArticles.filter((item: any) => item.id !== article.id);
            } else {
                state.checkedArticles.add(article.id);
                state.selectedArticles.push(article);
            }
        },
        handleTuitionsSelection(state, action: PayloadAction<any>) {
            const tuition = action.payload;
            const enrollmentId = tuition.enrollmentId;
            const currentState = current(state);
            const allCourses = [...currentState.generalData.courses, ...currentState.generalData.coursesHistory];
            const currentCourse = allCourses.find((course: any) => course.enrollmentId === enrollmentId);
            const pendientTuitions = currentCourse.tuitions
                .filter((t: any) => t.statusId === owedTuitionStatus.PENDING && t.invoiceId === 0);
            const tuitionIndex = pendientTuitions.indexOf(tuition);
            const tuitionIds = pendientTuitions
                .map((t: any) => t.id)
                .filter((id: number) => id <= tuition.id);
            const newSelectedCourses = [...currentState.selectedCourses];
            const newSelectedTuitions = new Set<number>(currentState.selectedTuitions);
            const existingCourse = newSelectedCourses
                .find(course => course.enrollmentId === enrollmentId);

            if (existingCourse) {
                const existingTuition = existingCourse.tuitions
                    .some((t: any) => t.id === tuition.id);
                const updatedTuitions = existingTuition ? [] : pendientTuitions.slice(0, tuitionIndex + 1);

                if (updatedTuitions.length === 0) {
                    const filteredTuitionIds = new Set(Array.from(newSelectedTuitions)
                        .filter((id: number) => !tuitionIds.includes(id)
                            || !existingCourse.tuitions.some((t: any) => t.id === id)));
                    state.selectedTuitions = filteredTuitionIds;
                    state.disabledTuitions = handleDisableTuitions(existingCourse.tuitions, false, current(state.disabledTuitions));
                    state.selectedCourses = newSelectedCourses.filter(course => course.enrollmentId !== enrollmentId);
                } else {
                    tuitionIds.forEach((id: number) => newSelectedTuitions.add(id));
                    state.selectedTuitions = newSelectedTuitions;
                    state.disabledTuitions = handleDisableTuitions(updatedTuitions, true, current(state.disabledTuitions));

                    state.selectedCourses = newSelectedCourses.map(course =>
                        course.enrollmentId === enrollmentId ? { ...course, tuitions: updatedTuitions } : course
                    );
                }
            } else {
                tuitionIds.forEach((id: number) => newSelectedTuitions.add(id));
                state.selectedTuitions = newSelectedTuitions;
                state.disabledTuitions = handleDisableTuitions(pendientTuitions.slice(0, tuitionIndex + 1), true, current(state.disabledTuitions));
                if (currentCourse) {
                    state.selectedCourses = [
                        ...newSelectedCourses,
                        {
                            ...currentCourse,
                            tuitions: pendientTuitions.slice(0, tuitionIndex + 1),
                        },
                    ];
                }
            }
        },
        buildInvoiceItems(state) {
            const existingTuitions: any[] = Array.from(current(state.invoiceItems.tuitions));
            const existingArticles: any[] = Array.from(current(state.invoiceItems.articles));
            const tuitions = new Set<Tuition>();
            const articles = new Set<Article>();

            const selectedCourses = current(state.selectedCourses);
            selectedCourses.forEach((course: any) => {
                course.tuitions.forEach((tuition: any) => {
                    const existingTuition = existingTuitions.find((t: any) => t.id === tuition.id);

                    if (existingTuition) {
                        tuitions.add({
                            ...existingTuition,
                            discount: existingTuition.discount,
                            typeOfDiscount: existingTuition.typeOfDiscount
                        });
                    } else {
                        tuitions.add({
                            id: tuition.id,
                            discount: tuition.discount.quantity,
                            typeOfDiscount: TYPE_OF_DISCOUNT.NONE
                        });
                    }
                });
            });

            const selectedArticles = current(state.selectedArticles);
            selectedArticles.forEach((article: any) => {
                const existingArticle = existingArticles.find(a => a.id === article.id);

                if (existingArticle) {
                    articles.add({
                        ...existingArticle,
                        discount: existingArticle.discount,
                        typeOfDiscount: existingArticle.typeOfDiscount
                    });
                } else {
                    articles.add({
                        id: article.id,
                        discount: article.discount.quantity,
                        typeOfDiscount: TYPE_OF_DISCOUNT.NONE
                    });
                }
            });

            state.invoiceItems = {
                tuitions,
                articles
            };
        },
        setInvoiceItems(state, action: PayloadAction<{ chargeId: number, discount: number, typeOfDiscount: number, isTuition: boolean }>) {
            const { chargeId, discount, typeOfDiscount, isTuition } = action.payload;

            if (isTuition) {
                const tuition = [...state.invoiceItems.tuitions].find(t => t.id === chargeId);
                if (tuition) {
                    tuition.discount = discount;
                    tuition.typeOfDiscount = typeOfDiscount;
                }
            } else {
                const article = [...state.invoiceItems.articles].find(a => a.id === chargeId);
                if (article) {
                    article.discount = discount;
                    article.typeOfDiscount = typeOfDiscount;
                }
            }
        }
    }
});

// Helper functions
const removeMaxValue = (array: number[]): number[] => {
    const max = Math.max(...array);
    return array.filter(value => value !== max);
}

const handleDisableTuitions = (tuitions: any[], shouldDisable: boolean, disabledTuitions: Set<number>): Set<number> => {
    const tuitionIds = tuitions.map((tuition: any) => tuition.id);
    const filteredWithoutMax = removeMaxValue(tuitionIds);
    const newDisabledTuitions = new Set(disabledTuitions);
    filteredWithoutMax.forEach(id => {
        if (shouldDisable) {
            newDisabledTuitions.add(id);
        } else {
            newDisabledTuitions.delete(id);
        }
    });
    return newDisabledTuitions;
}

export const {
    setShowModal,
    setShowTransferCreditModal,
    setRefresh,
    setModalContent,
    setTransferModalContent,
    setGeneralData,
    setStudentData,
    setStudentWhoReceives,
    setCheckoutData,
    setSelectedCourses,
    setSelectedInvoice,
    setSelectedCredit,
    setDisabledTuitions,
    setSelectedTuitions,
    setSelectedArticles,
    setCheckedArticles,
    setRefundItems,
    handleArticlesSelection,
    handleTuitionsSelection,
    buildInvoiceItems,
    setInvoiceItems,
    setSelectedPayment,
    setTotalToPaid,
    setIsPartialPayment,
    setIsCancelPayment,
    setIsNewInvoice,
    setTotalCheckoutItems
} = accountStatementSlice.actions;

export default accountStatementSlice.reducer;
