import React from "react";

import { styles } from "./style";

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Content to render inside the card.
     * The content will be any React nodes passed as children.
     */
    children: React.ReactNode; // React.ReactNode es un tipo adecuado para los children
}

/**
 * Card component for user interaction
 */
const Card: React.FC<CardProps> = ({ children }) => {
    return (
        <div style={styles.container}>
            <div className="card" style={{ ...styles.card }}>
                {children}
            </div>
        </div>

    );
};

export default Card;