import Constants from "../../constants"

const header =
{
    display: "flex"
};

const subtitleContainer =
{
    display: "flex",
    alignItems: "baseline",
    justifyContent: "start",
    gap: 10,
    flexFlow: "wrap"
};

const subtitle =
{
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.gray,
    opacity: 1,
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: "15px",
    paddingTop: "7px"
};

const boldText =
{
    color: Constants.Styles.text.colors.gray,
    fontWeight: "bold",
    marginBottom: 0,
    flexGrow: 1,
    paddingRight: "15px"
}

export const styles =
{
    header,
    subtitleContainer,
    subtitle,
    boldText
}