// Tabla.tsx
import React from 'react';

import Constants from '../../constants';
import CommonTable, { Column } from '../commonTable';
import { styles } from './style';

export interface CheckoutTuitionItemProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Columns to show in the Table
     */
    columns: Column[];
    /**
     * Data to show in the Table
     */
    data: any;
}

const CheckoutTuitionItemComponent: React.FC<CheckoutTuitionItemProps> = ({ columns, data }) => {
    return data ?
        <div>
            <section style={{ ...styles.header }}>
                <div style={{ ...styles.subtitleContainer }}>
                    <p style={styles.subtitle}>
                        {Constants.Common.course}:
                    </p>
                    <b style={{ color: "#5154AE" }}>
                        {data.courseName}
                    </b>
                </div>
            </section>
            <section style={{ ...styles.header }}>
                <div style={{ ...styles.subtitleContainer }}>
                    <p style={{ ...styles.subtitle }}>
                        {Constants.Common.period}:
                    </p>
                    <b style={{ color: "#5154AE" }}>
                        {data.periodName}
                    </b>
                </div>
            </section>
            <CommonTable
                className="not-hover"
                columns={columns}
                data={data.tuitions}
            />
        </div>
        : <></>
}

export default CheckoutTuitionItemComponent;
