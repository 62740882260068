import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	OverlayTrigger,
	Tab,
	Tabs,
	Tooltip
}
	from "react-bootstrap"
import Multiselect
	from "multiselect-react-dropdown"
import {Popover}
	from "react-tiny-popover"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import _
	from "lodash"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"

import
{
	buttonClickHandling,
	getAccessPermissions,
	imageTypes,
	isEmpty,
	isNotEmpty,
	monthRef,
	generateRandomPassword,
	pageGrouping,
	toAscendingDate,
	UIState,
	validateField,
	getErrorMessage,
	formatField,
}
	from "./../../common"
import Constants,
{
	tunning,
	employeeStatus,
	identificationType,
	country
}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer, {DataDisplayModal}
	from "./../../components/sectionContainer"
import {SelectReact}
	from "./../../components/select"
import UnderlineHeading
	from "./../../components/underlineHeading"
import LeDatePicker
	from "./../../components/leDatePicker"
import StudentInfoModal
	from "./studentInfoModal"
import RolesService
	from "./../../services/roles.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import CatalogService
	from "./../../services/catalog.service"
import defaultUserImage
	from "./../../assets/images/user.png"

import "./../../components/dropdown/style.css"
import "./../../components/input/style.css"
import {sprintf} from "sprintf-js"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action   = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled":
			return{ ...state, isButtonDisabled : action.payload}
		break;
	}
}

const Employees = ()=>
{
	const ROLES            : number = 0
	const ACADEMIC_DEGREES : number = 1

	const [extendedFilterShown, setExtendedFilterShown] = useState<boolean>(false)
	const [roles, setRoles] = useState<any[]>([])
	const [state, trigger] = useReducer(reducer, initialState)
	const dispatch = useDispatch()
	const [totalRecords, setTotalRecords] = useState<number>(0)
	const [initSetup, setInitSetup] = useState<number | null>(null)
	const [data, setData] = useState<any[]>([])
	const [readOnlyData, setReadOnlyData] = useState<any[]>([])
	const [academicDegrees, setAcademicDegrees] = useState<any>([])
	const [warningReport, listWarnings] = useState<any>({})
	const currentUser  : any = useSelector((state : RootState) => state.userProfile.value)
	const idleLocked   : boolean = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo    : any = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any = useSelector((state : RootState) => state.currentUserCampus.value)
	const employeeStatuses  : any = Object.values(basicInfo.employeeStatusRef)
	const campusRef         : any = useSelector((state : RootState) => state.campusRef.value)
	const [campuses, setCampuses] = useState<any>(Object.values(campusRef))
	const [userCampus, setUserCampus] = useState<number>(currentUserCampus)
	const [unfilledMainData, setUnfilledMainData] = useState<boolean>(false)
	const [noRolesProvided, setNoRolesProvided] = useState<boolean>(false)
	const [wrongRolesProvided, setWrongRolesProvided] = useState<boolean>(false)
	const [wrongValueOnFields, setWrongValueOnFields] = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields] = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord] = useState<boolean>(false)
	const [noCityProvided, setNoCityProvided] = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded] = useState<boolean>(false)
	const [roleView, setRoleView] = useState<boolean>(false)
	const [moduleReady, setModuleReady] = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus] = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords] = useState<number>(0)
	const [validRoles, setValidRoles] = useState<number>(0)
	const [minYear, setMinYear] = useState<number>(0)
	const [maxYear, setMaxYear] = useState<number>(0)
	const [newEmployee, setNewEmployee] = useState<any>({})
	const [selectedEmployee, setSelectedEmployee] = useState<any>(null)
	const [editableEmployee, setEditableEmployee] = useState<any>(null)
	const [selectedStudent, setSelectedStudent] = useState<any>(null)
	const [yearsToAlmostPresent, setYearsToAlmostPresent] = useState<any>([])
	const [minDate, setMinDate] = useState<any>(null)
	const [maxDate, setMaxDate] = useState<any>(null)
	const [date, setDate] = useState<any>(null)
	const [pagination, setPagination] = useState<any>(null)
	const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
	const [saveError, setSaveError] = useState<any>(null)
	const [currentDate, setCurrentDate] = useState<any>(null)
	const [recordsSelection, setRecordsSelection] = useState<any>([])
	const [currentTab, setCurrentTab] = useState<string | null>("mainInfo")
	const count        : number[] = [-1, 1]
	let   searchFormRef = useRef<HTMLFormElement>(null)
	let   recordFormRef = useRef<HTMLFormElement >(null)
	let   firstNameRef = useRef<HTMLInputElement>(null)
	let   lastNameRef = useRef<HTMLInputElement>(null)
	let   zipcodeRef = useRef<HTMLInputElement>(null)
	let   imageRef = useRef<HTMLInputElement>(null)
	let   observationsRef = useRef<HTMLTextAreaElement>(null)
	let   countrySelRef = useRef<HTMLSelectElement>(null)
	let   stateSelRef = useRef<HTMLSelectElement>(null)
	let   stateNameRef = useRef<HTMLInputElement>(null)
	let   cityNameRef = useRef<HTMLInputElement>(null)
	let   addressRef = useRef<HTMLInputElement>(null)
	let   emailRef = useRef<HTMLInputElement>(null)
	let   nationalitySelRef : any = useRef<HTMLSelectElement>(null)
	let   identificationCodeRef = useRef<HTMLInputElement>(null)
	let   passwordRef = useRef<HTMLInputElement>(null)
	let   phone1Ref = useRef<HTMLInputElement>(null)
	let   phone2Ref = useRef<HTMLInputElement>(null)
	let   idClase365Ref = useRef<HTMLInputElement>(null)
	let   tabDivRef = useRef<HTMLDivElement>(null)
	let   roleSelRef = useRef<Multiselect | null>(null)
	const allowed          : any = getAccessPermissions(currentUser)
	let   fileReader       : FileReader
	const defaultSearch=
	{
		q        : "",
		statusId : employeeStatus.ACTIVE,
		tags     : [],
	}
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [linkInfo, setLinkInfo] = useState<any>
	({
		facebook   : "Facebook",
		instagram  : "Instagram"
	})
	const [showPassword, setShowPassword] = useState(false)
	const toggleShowPassword              = ()=> setShowPassword(!showPassword)

	const unedit = ()=>
	{
		if(editableEmployee && editableEmployee.id == null)
		{
			setSelectedEmployee(null)
		}

		setEditableEmployee(null)
	}

	const hidePrompt = ()=>
	{
		setSelectedEmployee(null)
		setEditableEmployee(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)
		setSaveError(null)
		setRoleView(false)

		let index   : string
		let roleMap : any = {}

		recordData.roles.map
		(
			(role : any)=>
			{
				index = "R_" + role.id

				if(roleMap["R_" + role.id] == null)
				{
					roleMap["R_" + role.id]=
					{
						...role,
						valid : true
					}
				}

				if(Object.keys(campusRef).length > 1)
				{
					if(roleMap["R_" + role.id].campuses == null)
					{
						roleMap["R_" + role.id].campuses = []
					}

					if(campusRef["C_" + role.pivot.campus_id])
					{
						roleMap["R_" + role.id].campuses.push(campusRef["C_" + role.pivot.campus_id])
					}
				}
			}
		)

		let data=
		{
			...recordData, roles : Object.values(roleMap),
			password             : generateRandomPassword(10),
			imagePreview         : recordData.image
		}

		setValidRoles(recordData.roles.length)
		setCurrentTab("mainInfo")

		let cautionDate = new Date(date + " 23:00:00")
		let maximumDate = new Date(cautionDate)

		maximumDate.setFullYear(maximumDate.getFullYear() -  18)
		setMaxDate(maximumDate)
		setMinDate(new Date(sprintf("%04d-01-01 23:00:00", minYear)))

		if(recordData.id == null)
		{
			setEditableEmployee(data)
		}

		setSelectedEmployee(data)
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)

					if(currentTab != "roles")
					{
						recordFormRef.current.reportValidity()
					}
					else
					{
						tabDivRef?.current?.focus()
						setUnfilledMainData(true)
					}

					setTimeout
					(
						() =>
						{
							setUnfilledMainData(false)
							setUnfilledFields(false)
						},
						3000
					)
				}
			}
			else
			{
				if
				(
					(
						basicInfo.countryRef["C_" + editableEmployee.country_id].states.length > 0 &&
						basicInfo.countryRef["C_" + editableEmployee.country_id].stateRef["S_" + editableEmployee.state_id].cities.length > 0
					)
						&&
					(editableEmployee.city == null || editableEmployee.city.id == null)
				)
				{
					if(!unfilledFields)
					{
						setUnfilledFields(true)

						if(currentTab != "roles")
						{
							setNoCityProvided(true)
						}
						else
						{
							setUnfilledMainData(true)
							tabDivRef?.current?.focus()
						}

						setTimeout
						(
							() =>
							{
								setCustomSearch(defaultSearch)
								setUnfilledMainData(false)
								setUnfilledFields(false)
								setNoCityProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(editableEmployee.roles.length < 1)
					{
						if(!unfilledFields && !noRolesProvided)
						{
							tabDivRef?.current?.focus()
							setUnfilledFields(true)
							setNoRolesProvided(true)

							setTimeout
							(
								() =>
								{
									setNoRolesProvided(false)
									setUnfilledFields(false)
								},
								3000
							)
						}
					}
					else
					{
						if((campuses.length > 1) && (validRoles < editableEmployee.roles.length))
						{
							if(!unfilledFields && !wrongRolesProvided && !wrongValueOnFields)
							{
								tabDivRef?.current?.focus()
								setWrongRolesProvided(true)
								setWrongValueOnFields(true)

								setTimeout
								(
									() =>
									{
										setWrongRolesProvided(false)
										setWrongValueOnFields(false)
									},
									3000
								)
							}
						}
						else
						{
							if(saveError != null)
							{
								setSaveError(null)
							}

							setUIStatus(UIState.LOCKED)
							dispatch(enableIdleLockSwitch());

							EmployeesService.saveEmployee(editableEmployee).then
							(
								()=>
								{
									setSaveSuccess(true)
									setUIStatus(UIState.SUCCESS)

									setTimeout
									(
										()=>
										{
											setSelectedEmployee(null)
											setEditableEmployee(null)
											setSaveSuccess(false)
											setUIStatus(UIState.NORMAL)
											search()
										},
										tunning.MODAL_DISMISS_DELAY
									)
								},
								(error : any)=>
								{
									console.log(error)
									setUIStatus(UIState.ERROR)
									dispatch(disableIdleLockSwitch())
									setSaveError(getErrorMessage(error))
								}
							)
						}
					}
				}
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch
			({
				...customSearch,
				[e.target.name] : e.target.value
			})
		}
	}

	const imageLoad = (event : any)=>
	{
		if(event && event.target && event.target.files && event.target.files.length > 0)
		{
			if(event.target.files[0].size > 5242880 || imageTypes.indexOf(event.target.files[0].type) < 0)
			{
				setSaveError
				(
					<>
						Sólo se permiten imágenes con las siguientes características:
						<br />
						&emsp;Tamaño &lt;= 5MB, &nbsp;Formato: [BMP, GIF, JPEG, PNG, WEBP]
					</>
				)

				setUIStatus(UIState.ERROR)

				if(imageRef != null && imageRef.current != null)
				{
					imageRef.current.value = ""
				}
			}
			else
			{
				let employee : any   = {...editableEmployee, image : event.target.files[0]}
				fileReader           = new FileReader()
				fileReader.onloadend = (event : any)=>
				{
					setEditableEmployee({...employee, imagePreview : fileReader.result})

					if(imageRef != null && imageRef.current != null)
					{
						imageRef.current.value = ""
					}
				}

				fileReader.readAsDataURL(event.target.files[0])
			}
		}
	}

	const imageDisposal = ()=>
	{
		if(imageRef != null && imageRef.current != null)
		{
			imageRef.current.value = ""
		}

		setEditableEmployee
		({
			...editableEmployee, image : null,
			imagePreview               : null,
		})
	}

	const updateRoleCampuses = (index : number, role : any)=>
	{
		let roles        = editableEmployee.roles
		let passingRoles = 0

		roles.splice(index, 1, role)
		setEditableEmployee
		({
			...editableEmployee, roles : roles.map
			(
				(roleItem : any)=>
				{
					if(roleItem.valid)
					{
						passingRoles++
					}

					return roleItem
				}
			)
		})

		setValidRoles(passingRoles)
	}

	const handleRegistryChange = (event : any)=>
	{
		if(event && event.target)
		{
			setEditableEmployee({...editableEmployee, [event.target.name] : event.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const editEmployee = ()=>
	{
		setValidRoles(selectedEmployee.roles.length)

		setEditableEmployee
		({
			...selectedEmployee, password : "",
			nationality_id                : selectedEmployee.selectedEmployee || basicInfo.countries[0].id,
			identification_type_id        : identificationType[campusRef[currentUserCampus].company.country_id == country.HONDURAS ? "DNI" : "CURP"],
		})
	}

	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const roleRetrievalError : string = "El catálogo de roles no pudo ser extraído"

					dispatch(enableIdleLockSwitch())

					try
					{
						const result = await RolesService.searchRoles()

						if(result.status == 200 || result.status == 204)
						{
							setRoles
							(
								result.data.map
								(
									(role : any, index : number)=>
									({
										...role, campuses : [],
										valid    : true
									})
								)
							)
							setInitSetup(ROLES)
						}
						else
						{
							procedureComplaint(roleRetrievalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(roleRetrievalError)
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case ROLES:
						if(roles.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay roles registrados, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const degreeRetrievalError : string = "Los grados académicos no pudieron ser extraídos"

							try
							{
								const expand = true
								const result = await CatalogService.getAcademicDegrees(sessionToken)

								if(result.status == 200 || result.status == 204)
								{
									setAcademicDegrees(result.data)
									setInitSetup(ACADEMIC_DEGREES)
								}
								else
								{
									procedureComplaint(degreeRetrievalError)
								}
							}
							catch(error)
							{
								procedureComplaint(degreeRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case ACADEMIC_DEGREES:
						if(academicDegrees.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay grados académicos en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							setNewEmployee
							({
								country_id             : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
								nationality_id         : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
								status_id              : employeeStatus.ACTIVE,
								statate_id             : employeeStatus.ACTIVE,
								gender_id              : "",
								identification_type_id : basicInfo.identificationTypes[0].id,
								birthday               : null,
								birthdayValue          : "",
								tags                   : [],
								roles                  : [],
								identification_code    : "",
								id_clase_365           : "",
								city                   : [],
								address                : "",
								zipcode                : "",
								first_name             : "",
								last_name              : "",
								observations           : "",
								password               : "",
								phone1                 : "",
								phone2                 : "",
								email                  : "",
								facebook               : "",
								instagram              : ""
							})

							search()
						}
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => setTotalRecords(data.length), [data])

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				setNewEmployee
				({
					...newEmployee, country_id : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
					nationality_id : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
					identification_type_id : identificationType[campusRef[currentUserCampus].company.country_id == country.HONDURAS ? "DNI" : "CURP"]
				})
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				search()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if(moduleReady)
			{
				if(basicInfo.countries.length < 1)
				{
					procedureComplaint("No hay países registrados, por lo que el acceso a este módulo permanecerá deshabilitado")
				}
				else
				{
					search()
				}
			}
		},
		[moduleReady]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError = "La búsqueda no pudo ser completada"

		try
		{
			dispatch(enableIdleLockSwitch())

			if(extendedFilterShown)
			{
				setExtendedFilterShown(false)
			}

			const result = await EmployeesService.searchEmployees
				(customSearch, isNaN(page) ? 1 : (page || 1))

			const minimumYear : number = result.data.date.split("-")[0] - 168

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				setCurrentDate(result.data.date)
				setMinYear(minimumYear)
				setYearsToAlmostPresent([...Array(150)].map((_, i) => (minimumYear) + i))

				let normalData       : any = []
				let disabledData     : any = []
				let date             : any
				let dataToPush       : any

				result.data.data.map
				(
					(record : any, index : number)=>
					{
						date = record.birthday == null ? undefined : record.birthday.replaceAll("-", "/")

						dataToPush=
						{
							...record, birthday : date || "",
							status_id           : !record.bool_enabled ? 0 : record.employee.status_id,
							birthdayValue       : date == null ? "" : new Date(date + " 23:00:00")
						}

						if(record.id == currentUser.id)
						{
							disabledData.push(dataToPush)
						}
						else
						{
							if(dataToPush.state_id == null)
							{
								dataToPush=
								{
									...dataToPush, city : null,
									state_id            : ""
								}
							}
							else
							{
								dataToPush.state_name = ""

								if(dataToPush.city_id == null)
								{
									dataToPush.city = null
								}
								else
								{
									dataToPush=
									{
										...dataToPush, city : {...dataToPush.city, label : dataToPush.city.name},
										city_name           : ""
									}
								}
							}

							//adding boolean data type to toggle checking used by batch operations (multiple updating/deletion)--------------------------
							normalData.push
							({
								selected : false,
								data     : {...dataToPush}
							})
						}
					}
				)

				setReadOnlyData(disabledData)
				setData(normalData)

				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value}
					}

					return record
				}
			)
		)
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		)

		setSelectedRecords(totalRecords * (+selected))
	}

	const closePrompt = ()=>
	{
		setSelectedEmployee(null)
		setEditableEmployee(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError: string   = "El comando no pudo ser procesado"
					const recordIds   : number[] = !isNaN(recordId) ? [recordId] : data.filter((item : any) => item.selected)
						.map((item : any) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await EmployeesService.removeEmployees(recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const roleCampusesUpdate = (role : any, index : number, tags : any)=>
	{
		updateRoleCampuses
		(
			index,
			{
				...role,valid : tags.length > 0,
				 campuses     : tags
			}
		)
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  searchFormRef={searchFormRef}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  extendedFilterShown={extendedFilterShown}
		  searchIcon={<CustomIcon name="employee" />}
		  clearSearchRequested={clearSearchRequested}
		  addingPromptFn={() => showPrompt(newEmployee)}
		  setExtendedFilterShown={setExtendedFilterShown}
		  generalPlaceHolder={"Nombre, teléfono, observaciones, etc."}
		  extendedFilterViewClosing={() => setExtendedFilterShown(false)}
		  filterSummary=
		  {
			[
				customSearch.statusId < 0 ? null :
				{
					label   : "Estatus",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{basicInfo.employeeStatusRef["ES_" + customSearch.statusId].name}
					</p>
				},
				customSearch.tags.length < 1 ? null : 
				{
					label   : "Intereses",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				},
			].filter((setting : any) => setting)
		  }
		  advancedSearchForm=
		  {<div className="container"><div className="row">
			<div className="col-sm-6"><fieldset>
				<legend><label style={{position : "relative", top : "4px"}}>
					Estatus
				</label></legend>
				<button className="form-control btn btn-default" disabled={idleLocked || customSearch.statusId < 0}
				  type="button" onClick={() => setCustomSearch({...customSearch, statusId : -1})} style={{textAlign : "left"}}
				>
					<FontAwesomeIcon icon={customSearch.statusId < 0 ? solid("circle-dot") : solid("circle")} />
					<span style={{paddingLeft : "5px"}}>
						Mostrar todo
					</span>
				</button>
				{
					Object.values(employeeStatuses).map
					(
						(status : any) => <button onClick={() => setCustomSearch({...customSearch, statusId : status.id})}
						  disabled={idleLocked || customSearch.statusId == status.id} className="form-control btn btn-default"
						  type="button" key={"S_" + status.id} style={{textAlign : "left"}}
						>
							<FontAwesomeIcon icon={customSearch.statusId == status.id ? solid("circle-dot") : solid("circle")} />
							<span style={{paddingLeft : "5px"}}>
								{status.name}
							</span>
						</button>
					)
				}
			</fieldset></div>
			<div className="col-sm-6"><fieldset>
				<legend><label style={{position : "relative", top : "4px"}}>
					Intereses
				</label></legend>
				<Multiselect onSelect={(tags) => setCustomSearch({...customSearch, tags : tags})} displayValue="name"
				  onRemove={(tags) => setCustomSearch({...customSearch, tags : tags})} placeholder="Seleccione" disable={idleLocked}
				  emptyRecordMsg="No hay más opciones" options={basicInfo.tags} selectedValues={customSearch.tags}
				/>
			</fieldset></div>
		  </div></div>}
		>
			{
				Object.keys(warningReport).map
				(
					(code : string) => <div className="callout callout-warning" key={"warn_" + code}
					  style={{padding : "unset"}}
					><table style={{width : "100%"}}><tbody><tr>
						<td style={{padding : "16px", verticalAlign : "middle"}}>
							<FontAwesomeIcon icon={solid("triangle-exclamation")} />
						</td>
						<td style={{width : "100%"}}>
							{warningReport[code]}
						</td>
						<td style={{verticalAlign : "top"}}><button className="btn btn-default" style={{padding : "unset"}}
						  type="button" onClick=
						  {
							()=>
							{
								let warn = warningReport
									delete warn[code]
								listWarnings({...warn})
							}
						  }
						>
							<FontAwesomeIcon icon={solid("times")} style={{margin : "4px"}} />
						</button></td>
					</tr></tbody></table></div>
				)
			}
			{
				(data.length < 1 && readOnlyData.length < 1)
					?
				<>
					<hr />
					<div style={{padding : "25px"}}>
						{Constants.noCampusSearchResults}
					</div>
					<hr />
				</>
					:
				<table className="commonTable">
					<thead><tr key="usersHeader">
						{
							data.length < 1 ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll}
							  disabled={idleLocked || saveSuccess} type="button"
							>
								{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
							</button></th>
						}
						<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
							Id
						</th>
						<th>
							Nombre(s)
						</th>
						<th>
							Apellidos
						</th>
						<th>
							Teléfonos
						</th>
						<th>
							Correo
						</th>
						<th>
							Roles
						</th>
						<th>
							Intereses
						</th>
						<th>
							Estatus
						</th>
						<th />
					</tr></thead>
					<tbody>
						{
							readOnlyData.map
							(
								(record : any, index : number) => <tr key={"user" + index}>
									{data.length < 1 ? "" : <td />}
									<td ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
										{record.id}
									</td>
									<td>
										{record.first_name || "-"}
									</td>
									<td>
										{record.last_name || "-"}
									</td>
									<td>
										{[record.phone1, record.phone2].filter((phone : string) => isNotEmpty(phone)).join(", ") || "-"}
									</td>
									<td>
										{record.email}
									</td>
									<td>{
										record.roles && record.roles.filter((item : any)=>campusRef["C_" + item.pivot.campus_id]).map
										(
											(item : any, index : number) => <div key={"role_" + item.id + "_" + item.pivot.campus_id}
											  style={{width : "max-content"}}>{
												item.name + " " +
													(campusRef["C_" + item.pivot.campus_id].name || "")
											}</div>
										)
									}</td>
									<td>{
										isEmpty(record.tags) ? "-" : record.tags.map
										(
											(item : any, index : number) => <div className="badge rounded-pill bg-primary" key={"crc_" + item.id}>
												{item.name}
											</div>
										)
									}</td>
									<td>
										{basicInfo.employeeStatusRef["ES_" + record.status_id].name}
									</td>
									<td><div style={{display : "flex", justifyContent : "flex-end"}}><OverlayTrigger
									  overlay={<Tooltip id="tooltip-activos">Ver Detalle</Tooltip>}><button
									  className="button btn btn-default" type="button" disabled={idleLocked} onClick=
									  {
										()=>showPrompt
										({
											...record, phone1 : record.phone1 || "",
											phone2            : record.phone2 || "",
											observations      : record.employee.observations || "",
											campus_id         : record.employee.campus.id,
											identity_code     : record.employee.identity_code,
											id_clase_365      : record.id_clase_365 || "",
											zipcode           : record.zipcode || "",
											bool_read_only    : 1
										})
									  } style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
									>
										<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
									</button></OverlayTrigger></div></td>
								</tr>
							)
						}
						{
							data.map
							(
								(record : any, index : number) => <tr key={"user" + index}>
									<td><button className="btn btn-default" onClick={() => toggleRecordSelection(index)} type="button"
									  disabled={idleLocked}
									>
										{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
									</button></td>
									<td>
										{record.data.id}
									</td>
									<td>
										{record.data.first_name || "-"}
									</td>
									<td>
										{record.data.last_name || "-"}
									</td>
									<td>
										{[record.data.phone1, record.data.phone2].filter((phone : string) => isNotEmpty(phone)).join(", ") || "-"}
									</td>
									<td>
										{record.data.email}
									</td>
									<td>{
										record.data.roles && record
											.data
											.roles
											.filter((item : any)=>campusRef["C_" + item.pivot.campus_id])
											.map
											(
												(item : any, index : number)=> <div style={{width : "max-content"}}
												  key={"role_" + item.id + "_" + item.pivot.campus_id}
												>
													<b>
														{item.name}
														&nbsp;
														|
													</b>
													&nbsp;
													{(campusRef["C_" + item.pivot.campus_id].name || "")}
												</div>
											)
									}</td>
									<td>{
										isEmpty(record.data.tags) ? "-" : record.data.tags.map
										(
											(item : any, index : number) => <div className="badge rounded-pill bg-primary"
											  key={"crc_" + item.id}
											>
												{item.name}
											</div>
										)
									}</td>
									<td>
										{basicInfo.employeeStatusRef["ES_" + record.data.status_id].name}
									</td>
									<td>
										<div style={{display : "flex", justifyContent: "flex-end"}}>
										{
											// record.data.student ? "" : <OverlayTrigger
											//   overlay={<Tooltip id="tooltip-nuevo-alumno">Enlistar como alumno</Tooltip>}
											// >
											// 	<button type="button" className="button btn btn-default" disabled={idleLocked}
											// 		style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
											// 		onClick=
											// 		{
											// 			() => setSelectedStudent
											// 			({
											// 				...record.data, id : null,
											// 				contacts           : [],
											// 				enrollments        : [],
											// 				imagePreview       : record.data.image,
											// 				academic_degree_id : academicDegrees[0].id,
											// 				phone1             : record.data.phone1 || "",
											// 				phone2             : record.data.phone2 || "",
											// 				id_clase_365       : record.data.id_clase_365 || "",
											// 				zipcode            : record.data.zipcode || "",
											// 				observations       : record.data.employee.observations || ""
											// 			})
											// 		}
											// 	>
											// 		<CustomIcon name="addStudent" />
											// 	</button>
											// </OverlayTrigger>
										}
										<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Ver Detalle</Tooltip>}><button
										  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
										  type="button" className="button btn btn-default" disabled={idleLocked} onClick=
										  {
												()=>showPrompt
												({
													...record.data, phone1 : record.data.phone1 || "",
													phone2                 : record.data.phone2 || "",
													id_clase_365           : record.data.id_clase_365 || "",
													zipcode                : record.data.zipcode || "",
													observations           : record.data.employee.observations || ""
												})
										  }
										>
											<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
											</button></OverlayTrigger>
											<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Borrar</Tooltip>}><button
											className="button btn btn-default" style={{display : "table-column"}} type="button"
											onClick={() => confirmRemoval(record.data.id)} disabled={idleLocked}
											>
												<CustomIcon name="bTrash" />
											</button></OverlayTrigger>
										</div>
									</td>
								</tr>
							)
						}
					</tbody>
				</table>
			}
		</SectionContainer>
		{
			selectedEmployee == null ? "" : <DataDisplayModal unfilledFields={unfilledFields} saveLock={state.isButtonDisabled}
			  allowedUpdate={!selectedEmployee.bool_read_only && allowed.update} uneditFn={unedit} promptCloseFn={closePrompt}
			  UIStatus={UIStatus} dataEditInit={editEmployee} entityName="Empleado" wrongValueOnFields={wrongValueOnFields}
			  editableRecord={editableEmployee} saveSuccess={saveSuccess} saveDataFn={saveRecord} form={recordFormRef}
			  unchangedRecord={unchangedRecord} processingError={saveError} headIcon={<CustomIcon name="employee" />}
			><form ref={recordFormRef} className="form" onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			><div className="container"><div className="row">
				<div className="col-md-4 text-center">
					<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
						<img className="relatedPicture" src=
						  {
							(editableEmployee == null ? selectedEmployee.imagePreview : editableEmployee.imagePreview)
								|| defaultUserImage
						  }
						/>
						{
							editableEmployee == null ? "" : <>
								<input accept="image/*" type="file" style={{display : "none"}}
								  onChange={imageLoad} name="image" id="image"
								  ref={imageRef}
								/>
								{
									editableEmployee.image == null ? "" : <button className="btn btn-danger rounded-pill"
									  onClick={imageDisposal} type="button" disabled={idleLocked} style=
									  {{
										position : "relative",
										bottom   : "40px",
										left     : "15%"
									  }}
									>
										<FontAwesomeIcon icon={solid("times")} size="2x" fill="unset"/>
									</button>
								}
								<button onClick={() => buttonClickHandling(imageRef)} className="btn rounded-pill" type="button"
								  disabled={idleLocked} style=
								  {{
									border     : "1px solid var(--main-bg-color)",
									color      : "var(--secondary-txt-color)",
									position   : "relative",
									background : "#C7C7C7",
									height     : "56px",
									bottom     : "40px",
									left       : "16%"
								  }}
								>
									<FontAwesomeIcon style={{color : "#000000"}} icon={solid("camera")} fill="unset" size="2x" />
								</button>
							</>
						}
					</div>
					<br />
					<br />
					<div className="inputContainer text-start">
						<label htmlFor="status_id" style={{position : "relative"}}>
							Correlativo
						</label>
						<div className="roView">
							{selectedEmployee.identity_code}
						</div>
					</div>
					<div className="inputContainer text-start">
						<label htmlFor="correlativo_clase_365">
							Correlativo Clase365
						</label>
						<div className="roView">
							<br />
							{selectedEmployee?.correlativo_clase_365 || "-"}
						</div>
					</div>
					<div className="selectContainer">
						<label htmlFor="status_id">
							Estatus
						</label>
						{
							editableEmployee == null
								?
							<div className="roView">
								<br />
								{basicInfo.employeeStatusRef["ES_" + selectedEmployee.employee.status_id].name}
							</div>
								:
							<>
								<select value={editableEmployee.status_id} className="btn btn-primary text-start" id="status_id"
								  disabled={idleLocked} onChange={handleRegistryChange} name="status_id"
								  style={{height : "30px"}}
								>{
									employeeStatuses.map
									(
										(record : any, i : any) => <option key={i} value={record.id}>
											{record.name}
										</option>
									)
								}</select>
								<div className="iconRight" style={{pointerEvents : "none"}}>
									<CustomIcon name={"rowDown"} />
								</div>
							</>
						}
					</div>
					{
						basicInfo.tags.length < 1 ? "" : <div className="card card-default" style={{padding : "15px"}}>
							<div className="underlineHeadingContainer" style={{marginBottom : "unset"}}>
								Intereses
							</div>
							{
								editableEmployee == null
									?
								<div style={{textAlign : "left", display : "flex", flexFlow : "wrap"}}>{
									selectedEmployee.tags.length < 1 ? "(Ninguno)" : selectedEmployee.tags.map
									(
										(record : any, index: number) => <div key={"cr_" + record.id}
										  className="badge rounded-pill bg-primary prompt"
										  style={{display : "inline-block", padding : "5px", margin : "5px"}}
										>
											{record.name}
										</div>
									)
								}</div>
									:
								<>
									<label htmlFor="tags" style={{position : "unset"}}>
										Actualice
									</label>
									<Multiselect displayValue="name" selectedValues={editableEmployee.tags} disable={idleLocked}
									  options={basicInfo.tags} emptyRecordMsg="No hay más opciones" placeholder="Seleccione"
									  onSelect={(tags) => setEditableEmployee({...editableEmployee, tags: tags})}
									  onRemove={(tags) => setEditableEmployee({...editableEmployee, tags: tags})}
									/>
								</>
							}
						</div>
					}
				</div>
				<div className="col-md-8">
					<div ref={tabDivRef} tabIndex={-1}
					  style={{display : "flex", alignItems : "stretch", flexFlow : "row", borderBottom : "1px solid var(--main-txt-color)"}}
					>
						<Popover positions={["bottom"]} isOpen={unfilledMainData && currentTab == "roles"} content=
						  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
							<div className="requirementAlert">
							<FontAwesomeIcon icon={solid("exclamation")}/>
							</div>
							&nbsp;
							Requerido: Aun hay información sin completar
						  </div>}
						><button disabled={idleLocked || currentTab == "mainInfo"} style={{flex : 1}}
						  className="btn btn-default" onClick={()=>{setCurrentTab("mainInfo")}}
						>
							<FontAwesomeIcon icon={solid("user")} />
							<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
								Información principal
							</span>
						</button></Popover>
						<Popover positions={["bottom"]} isOpen={noRolesProvided || wrongRolesProvided} content=
						  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
							<div className="requirementAlert">
							<FontAwesomeIcon icon={solid("exclamation")}/>
							</div>
							&nbsp;
							Requerido:
							{
								noRolesProvided
									?
								"Agreque al menos un rol"
									:
								"Debe incluirse al menos un plantel para cada rol"
							}
						  </div>}
						><button disabled={idleLocked || currentTab == "roles"} style={{flex : 1}} className="btn btn-default"
						  onClick={() => {setCurrentTab("roles")}}
						>
							<CustomIcon name="role" />
							<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
								Roles
							</span>
						</button></Popover>
					</div>
					<Tabs defaultActiveKey="mainInfo" activeKey={currentTab!!} onSelect={setCurrentTab} id="employeeform"
					  className="mb-3 hidden displayNone"
					>
						<Tab eventKey="roles" title="">{
							editableEmployee != null
								?
							<>
								{
									roles.length <= editableEmployee.roles.length ? "" : <>
										{campuses.length > 1 ? "" : "Plantel: " + campuses[0].name}
	{/*Class used to hide chips. Their values are manipulated in the later code, being sort of a chip of chips component------*/}
										<div style={{padding : "0px 15px"}} className="nestedSelection">
	{/*Spread operator ("..." -Ellipsis-) is used to induce (force) state refresh---------------------------------------------*/}
											<Multiselect displayValue="name" placeholder="Seleccione..." id="role" ref={roleSelRef}
											  disable={idleLocked} onSelect=
											  {
												(roles)=>
												{
													setEditableEmployee({...editableEmployee, roles : roles})
													setValidRoles(validRoles + 1)
												}
											}
											  onRemove={(tags) => setEditableEmployee({...editableEmployee, roles : roles})}
											  emptyRecordMsg="No hay más opciones" selectedValues={[...editableEmployee.roles]}
											  options={roles}
											/>
										</div>
										<hr />
									</>
								}
								{
									{...editableEmployee}.roles.map
									(
										(role : any, index : number)=>
										{
											if(role)
											{
												return<div key={"sR_" + role.id} style={{padding : "5px 10px"}}>
													<div style={{background : "#F5F5F5", borderRadius : (campuses.length > 1 ? "15px 15px 0px 0px" : "15px")}}>
														<table style={{width : "100%"}}><tbody><tr>
															<td style={{padding : "8px 15px", width : "100%"}}>
																{role?.name}
															</td>
															<td><button className="btn btn-default" disabled={idleLocked}
															  type="button" onClick=
															  {
																()=>
																{
																	let roles        = editableEmployee.roles
																	let passingRoles = 0

																	roles.splice(index, 1)

																	if(roleSelRef && roleSelRef.current)
																	{//Reset being used because tag removal is not called as it should be (Directly from the multiselect component)
																		roleSelRef
																			.current
																			.resetSelectedValues()
																			.then
																			(
																				()=>
																				{
																					setEditableEmployee
																					({
																						...editableEmployee, roles : roles.map
																						(
																							(roleItem : any)=>
																							{
																								if(roleItem.valid)
																								{
																									passingRoles++
																								}

																								return roleItem
																							}
																						)
																					})

																					setValidRoles(passingRoles)
																				}
																			)
																	}
																}
															  }
															>
																<FontAwesomeIcon icon={solid("times")} />
															</button></td>
														</tr></tbody></table>
													</div>
													{
														(campuses.length < 2) ? "" : <fieldset
														  style={{borderTop : "unset", borderRadius : "0px 0px 15px 15px", padding :"0px 10px 10px", position : "relative"}}
														>
															<legend style={{paddingLeft : "15px", position : "absolute"}}>
																<label style={{position : "relative", paddingTop : "2px"}}>
																	Planteles
																</label>
															</legend>
															<div style={{paddingTop : "30px"}}>
																<Multiselect options={campuses} emptyRecordMsg="No hay más opciones"
																  onSelect={(tags : any) => roleCampusesUpdate(role, index, tags)}
																  onRemove={(tags : any) => roleCampusesUpdate(role, index, tags)}
																  selectedValues={[...role.campuses]} placeholder="Especifique..."
																  displayValue="name" disable={idleLocked}
																/>
															</div>
														</fieldset>
													}
												</div>
											}
										}
									)
								}
							</>
								:
							selectedEmployee.roles.map
							(
								(role : any, index : any) => <div key={"sR_" + role.id} style={{padding : "5px 10px"}}>
									<div style=
									  {{
										height       : "38px",
										background   : "#F5F5F5",
										padding      : "8px 15px",
										borderRadius : ((role.campuses && role.campuses.length > 0) ? "15px 15px 0px 0px" : "15px"),
									  }}
									>
										{role.name}
									</div>
									{
										(role.campuses == null || role.campuses.length < 1) ? "" : <fieldset
										  style={{borderTop : "unset", borderRadius : "0px 0px 15px 15px", padding :"0px 10px 10px"}}
										>
											<legend style={{paddingLeft : "15px", position : "absolute"}}><label
											  style={{position : "relative", paddingTop : "5px"}}
											>
												Planteles &emsp;
											</label></legend>
											<div style={{paddingTop : "35px"}}>{
												role.campuses.filter((campus : any) => campus).map
												(
													(campus : any, index : number) => <span className="badge bg-primary rounded-pill"
													  key={"sR_" + role.id + "c" + campus.id}
													>
														{campus.name}
													</span>
												)
											}</div>
										</fieldset>
									}
								</div>
							)
						}</Tab>
						<Tab eventKey="mainInfo" title="">
							<br />
							<UnderlineHeading name={"Datos personales"} />
							<div className="row">
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name">
										{editableEmployee == null ? "" : "*"}
										Nombres
									</label>
									{
										editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee.first_name}
											</div>
												:
											<input
											  style={{width : "100%"}}
											  onChange={handleRegistryChange}
											  ref={firstNameRef}
											  maxLength={50}
											  placeholder="Nombres"
											  type="text"
											  id="first_name"
											  disabled={idleLocked}
											  value={editableEmployee.first_name}
											  name="first_name"
											  onFocus={validateField}
											  onBlur={(event : any) => formatField(event, handleRegistryChange)}
											  required
											/>
									}
								</div></div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name">
										{editableEmployee == null ? "" : "*"}
									</label>
									{
										editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee.last_name}
											</div>
												:
											<input
											  value={editableEmployee.last_name}
											  onChange={handleRegistryChange}
											  placeholder="Apellidos"
											  style={{width : "100%"}}
											  onFocus={validateField}
											  name="last_name"
											  maxLength={50}
											  type="text"
											  id="last_name"
											  onBlur={(event : any) => formatField(event, handleRegistryChange)}
											  disabled={idleLocked}
											  ref={lastNameRef}
											  required
											/>
									}
								</div></div>
								<div className="col-md-6"><div className="selectContainer">
									<label htmlFor="identification_type_id">
										Tipo de identificación
									</label>
									{
										editableEmployee == null
												?
											<div className="roView">
												<br />
												{
													(
														(selectedEmployee && selectedEmployee.identification_type)
															&&
														selectedEmployee.identification_type.name
													)
														|| ""
												}
											</div>
												:
											<>
												<select
												  disabled={idleLocked}
												  id="identification_type_id"
												  className="btn btn-primary text-start"
												  value={editableEmployee.identification_type_id}
												  name="identification_type_id" style={{height : "30px"}}
												  onChange=
												  {
													(event : any)=>
													{
														setEditableEmployee
														({
															...editableEmployee, identification_type_id : event.target.value,
															identification_code : !basicInfo
																.identificationTypeRef["IT_" + event.target.value]
																.pattern
																.regexp
																.test(editableEmployee.identification_code)
																		?
																	""
																		:
																	editableEmployee.identification_code
														})
													}
												  }
												>{
													basicInfo.identificationTypes.map
													(
														(record : any, i : any) => <option key={i} value={record.id}>
															{record.name}
														</option>
													)
												}</select>
												<div className="iconRight" style={{pointerEvents : "none"}}>
													<CustomIcon name="rowDown" />
												</div>
											</>
									}
								</div></div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name">
										{editableEmployee == null ? "" : "*"}
										Código de identificación
									</label>
									{
										editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee.identification_code || "-"}
											</div>
												:
											<input
											  onFocus={validateField}
											  disabled={idleLocked}
											  placeholder="Código"
											  type="text"
											  value={editableEmployee.identification_code}
											  name="identification_code"
											  ref={identificationCodeRef}
											  id="identification_code"
											  onBlur={validateField}
											  style={{width : "100%"}}
											  data-description={basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].description}
											  pattern={basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].pattern.value}
											  minLength={basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].min_length}
											  maxLength={basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].max_length}
											  onChange=
											  {
												(event : any)=>
												{
													if
													(
														(
															basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].bool_only_digits
																? /^[0-9]*$/
																	: /^[a-zA-Z0-9]*$/
														)
														.test(event.target.value)
													)
													{
														setEditableEmployee({...editableEmployee, identification_code : event.target.value})
														validateField(event)
													}
												}
											  }
											  required
											/>
									}
								</div></div>
								<div className="col-md-6"><div className="selectContainer">
									<label htmlFor="name">
										Fecha de nacimiento
									</label>
									<div style={{paddingTop : "18px"}}>{
										editableEmployee == null
												?
											(
												(selectedEmployee == null || isEmpty(selectedEmployee.birthdayValue)) ? "-" : toAscendingDate
													(selectedEmployee.birthdayValue)
											)
												:
											<LeDatePicker
											  onBlur={validateField}
											  value={editableEmployee.birthdayValue}
											  months={monthRef}
											  name="birthdayValue"
											  id="birthdayValue"
											  maxDate={maxDate}
											  years={yearsToAlmostPresent}
											  onFocus={validateField}
											  disable={idleLocked}
											  minDate={minDate}
											  onChange={handleRegistryChange}
											  required
											/>
									}</div>
								</div></div>
								<div className="col-md-6"><div className="selectContainer">
									<label htmlFor="gender_id">
										Género
									</label>
									{
										editableEmployee == null
											?
										<div className="roView">
											<br />
											{selectedEmployee?.gender?.name}
										</div>
											:
										<>
										<select
										  name="gender_id"
										  disabled={idleLocked}
										  className="btn btn-primary text-start"
										  onChange={handleRegistryChange}
										  style={{height : "30px"}}
										  value={editableEmployee.gender_id}
										  id="gender_id"
										  onBlur={validateField}
										  onFocus={validateField}
										  required
										>
											<option value="">
												Seleccione...
											</option>
											{
												basicInfo.genders.map
												(
													(record : any, i : any) => <option key={i} value={record.id}>
														{record.name}
													</option>
												)
											}
										</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
									}
								</div></div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name">
										{editableEmployee == null ? "" : "*"}
										Teléfono 1
									</label>
									{
										editableEmployee == null
											?
										<div className="roView">
											<br />
											{selectedEmployee?.phone1 || "-"}
										</div>
											:
										<input
										  onFocus={validateField}
										  id="phone1" type="tel"
										  placeholder="Teléfono"
										  minLength={8}
										  maxLength={50}
										  onBlur={(event : any) => formatField(event, handleRegistryChange)}
										  ref={phone1Ref}
										  value={editableEmployee.phone1}
										  disabled={idleLocked}
										  style={{width : "100%"}}
										  name="phone1"
										  onKeyPress={(event : any) => {if(isNaN(event.key))event.preventDefault()}}
										  onChange=
										  {
											(event : any)=>
											{
												if(isNaN(event.target.value))
												{
													event.preventDefault()
												}
												else
												{
													setEditableEmployee({...editableEmployee, phone1 : event.target.value})
												}
											}
										  }
										  required
										/>
									}
								</div></div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name">
										Teléfono 2
									</label>
									{
										editableEmployee == null
											?
										<div className="roView">
											<br />
											{selectedEmployee?.phone2 || "-"}
										</div>
											:
										<input onFocus={validateField}
										  id="phone2"
										  name="phone2"
										  maxLength={50}
										  onBlur={(event : any) => formatField(event, handleRegistryChange)}
										  ref={phone2Ref}
										  value={editableEmployee.phone2}
										  disabled={idleLocked}
										  placeholder="Teléfono"
										  type="tel"
										  minLength={8}
										  style={{width : "100%"}}
										  onKeyPress={(event : any) => {if(isNaN(event.key))event.preventDefault()}}
										  onChange=
										  {
											(event : any)=>
											{
												if(isNaN(event.target.value))
												{
													event.preventDefault()
												}
												else
												{
													setEditableEmployee({...editableEmployee, phone2 : event.target.value})
												}
											}
										  }
									/>
									}
								</div></div>
							</div>
							<br />
							<UnderlineHeading name={"Ubicación"} />
							<div className="row">
								<div className="col-md-6">
									<div className="selectContainer"><label htmlFor="nationality_id">
										{editableEmployee ? "*" : ""}
										Nacionalidad
									</label>
									{
										editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee?.nationality?.name || "-"}
											</div>
												:
											<>
												<select value={editableEmployee.nationality_id} ref={nationalitySelRef}
												  onChange={handleRegistryChange} disabled={idleLocked}
												  id="nationality_id" name="nationality_id" className="btn btn-primary text-start"
												  style={{height : "30px"}}
												>{
													basicInfo.countries.map
													(
														(record : any, i : any) => <option key={i} value={record.id}>
															{record.name}
														</option>
													)
												}</select>
												<div className="iconRight" style={{pointerEvents : "none"}}>
													<CustomIcon name="rowDown" />
												</div>
											</>
									}
								</div></div>
								<div className="col-md-6">
									<div className="selectContainer">
										<label htmlFor="country_id">
											{editableEmployee ? "*" : ""}
											País de residencia
										</label>
										{
											editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee?.country?.name || "-"}
											</div>
												:
											<>
												<select
												  className="btn btn-primary text-start"
												  value={editableEmployee.country_id}
												  style={{height : "30px"}}
												  disabled={idleLocked}
												  ref={countrySelRef}
												  name="country_id"
												  id="country_id"
												  onChange=
												  {
													(event : any) => setEditableEmployee
													({
														...editableEmployee, country_id : event.target.value,
														state_id                        : "",
														state_name                      : "",
														city                            : null,
														city_name                       : ""
													})
												  }
												>{
													basicInfo.countries.map
													(
														(country : any, i : any) => <option key={i} value={country.id}>
															{country.name}
														</option>
													)
												}</select>
												<div className="iconRight" style={{pointerEvents : "none"}}>
													<CustomIcon name="rowDown" />
												</div>
											</>
										}
									</div>
								</div>
								<div className="col-md-6">
									<div style={{margin : "unset", width : "100%"}}
									  className=
									  {
										(
											(editableEmployee == null && basicInfo.countryRef["C_" + selectedEmployee.country_id].states.length < 1)
												||
											(editableEmployee && basicInfo.countryRef["C_" + editableEmployee.country_id].states.length < 1)
										)
											? "inputContainer"
												: "selectContainer"
									  }
									>
										<label htmlFor="state_id">
											{editableEmployee == null ? "" : "*"}
											Estado/Depto./Provincia...
										</label>
										<div className="roView" style={{width : "100%"}}>{
											editableEmployee == null
													?
												<>
													<br />
													{
														(
															(selectedEmployee && selectedEmployee.state)
																	?
																selectedEmployee.state.name
																	:
																selectedEmployee.state_name
														)
															|| "-"
													}
												</>
													:
												(
													(
														(editableEmployee == null && basicInfo.countryRef["C_" + selectedEmployee.country_id].states.length < 1)
															||
														(editableEmployee && basicInfo.countryRef["C_" + editableEmployee.country_id].states.length < 1)
													)
															?
														<>
															<br />
															<input
															  type="text"
															  maxLength={50}
															  id="state_name"
															  name="state_name"
															  ref={stateNameRef}
															  disabled={idleLocked}
															  onBlur={(event : any) => formatField(event, handleRegistryChange)}
															  onFocus={validateField}
															  style={{position : "relative", width : "100%"}}
															  onChange={handleRegistryChange}
															  value={editableEmployee.state_name}
															  required
															/>
														</>
															:
														<>
															<select
															  id="state_id"
															  name="state_id"
															  ref={stateSelRef}
															  disabled={idleLocked}
															  onBlur={validateField}
															  onFocus={validateField}
															  style={{height : "30px"}}
															  className="btn btn-primary text-start"
															  defaultValue={editableEmployee.state_id}
															  onChange=
															  {
																(event : any) => setEditableEmployee
																({
																	...editableEmployee, state_id : event.target.value,
																	state_name                    : "",
																	city                          : null,
																	city_name                     : ""
																})
															  }
															  required
															>
																<option value="">
																	&emsp;
																	Seleccione...
																</option>
																{
																	basicInfo.countryRef["C_" + editableEmployee.country_id] &&
																	basicInfo.countryRef["C_" + editableEmployee.country_id].states.map
																	(
																		(record : any, i : number) => <option
																		  value={record.id}
																		  key={record.id}
																		>
																			{record.name}
																		</option>
																	)
																}
															</select>
															<div className="iconRight" style={{pointerEvents : "none"}}>
																<CustomIcon name="rowDown" />
															</div>
														</>
												)
										}</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className=
									  {
										(
											(editableEmployee == null && basicInfo.countryRef["C_" + selectedEmployee.country_id].states.length < 1)
												||
											(editableEmployee && basicInfo.countryRef["C_" + editableEmployee.country_id].states.length < 1)
										)
											? "inputContainer"
												: "selectContainer"
									  }
									>
										<label htmlFor="city">
											{editableEmployee == null ? "" : "*"}
											Ciudad
										</label>
											<div className="roView" style={{width : "100%", paddingTop : "15px"}}>{
												editableEmployee == null
														?
													<div style={{paddingTop : "10px"}}>{
														(
															(
																selectedEmployee && selectedEmployee.city
																	? selectedEmployee.city.name
																		: selectedEmployee.city_name
															) || "-"
														)
													}</div>
														:
													(
														(
															(
																basicInfo.countryRef["C_" + editableEmployee.country_id].states.length > 0
																	&&
																(editableEmployee.state_id == null || basicInfo.countryRef["C_" + editableEmployee.country_id].stateRef["S_" + editableEmployee.state_id] == null)
															)
																||
															(
																basicInfo.countryRef["C_" + editableEmployee.country_id].states.length < 1
																	&&
																isEmpty(editableEmployee.state_name)
															)
														)
															?
																<div style={{paddingTop : "10px"}}>
																	&lt;Proporcione estado &gt;
																</div>
															:
														(
															(
																basicInfo.countryRef["C_" + editableEmployee.country_id].states.length < 1
																	||
																basicInfo.countryRef["C_" + editableEmployee.country_id].stateRef["S_" + editableEmployee.state_id].cities.length < 1
															)
																	?
																<input
																  type="text"
																  maxLength={50}
																  id="city_name"
																  name="city_name"
																  ref={cityNameRef}
																  disabled={idleLocked}
																  onBlur={(event : any) => formatField(event, handleRegistryChange)}
																  onFocus={validateField}
																  style={{width : "100%"}}
																  onChange={handleRegistryChange}
																  value={editableEmployee.city_name}
																  required
																/>
																	:
																<Popover
																  positions={["bottom"]}
																  isOpen={noCityProvided}
																  content=
																  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
																	<div className="requirementAlert">
																		<FontAwesomeIcon icon={solid("exclamation")}/>
																	</div>
																	&nbsp;
																	Requerido: Elija una opción
																  </div>}
																>
																	<div style={{width : "100%"}}>
																		<SelectReact
																		  id="city"
																		  name="city"
																		  placeholder="Ciudad"
																		  disabled={idleLocked}
																		  value={editableEmployee.city}
																		  onChange={handleRegistryChange}
																		  children={Object.values(basicInfo.countryRef["C_" + editableEmployee.country_id].stateRef["S_" + editableEmployee.state_id].cityRef)}
																		/>
																	</div>
																</Popover>
														)
													)
											}</div>
									</div>
								</div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name">
										{editableEmployee == null ? "" : "*"}
										Dirección actual
									</label>
									{
										editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee.address || "-"}
											</div>
												:
											<input
											  value={editableEmployee.address}
											  onChange={handleRegistryChange}
											  name="address" maxLength={255}
											  placeholder="Dirección actual"
											  style={{width : "100%"}}
											  onFocus={validateField}
											  onBlur={(event : any) => formatField(event, handleRegistryChange)}
											  disabled={idleLocked}
											  pattern="(?=.*\d)\S+(\s+\S+)+"
											  data-description="La dirección debe conformarse por al menos 2 (dos) palabras y contener al menos 1 (un) dígito"
											  type="text"
											  ref={addressRef}
											  id="address"
											  required
											/>
									}
								</div></div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name">
										Código postal
									</label>
									{
										editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee.zipcode || "-"}
											</div>
												:
											<input onBlur={validateField} max={999999999} step={1} name="zipcode" type="number"
											  onChange={handleRegistryChange} placeholder="Código postal" min={10000} id="zipcode"
											  value={editableEmployee.zipcode} onFocus={validateField} style={{width : "100%"}}
											  ref={zipcodeRef}
											/>
									}
								</div></div>
								{
									Object.keys(linkInfo).map
									(
										(record : any, index : number) => <div className="col-md-6" key={record}><div
										  className="inputContainer"
										>
											<label htmlFor={record}>
												{linkInfo[record]}
											</label>
											{
												editableEmployee == null
													?
												<div className="roView">
													<br />
													{(selectedEmployee && selectedEmployee[record]) || "-"}
												</div>
													:
												<input value={editableEmployee[record]} maxLength={50}
												  style={{width : "100%"}} placeholder={linkInfo[record]}
												  onChange={handleRegistryChange}
												  type="text" name={record} disabled={idleLocked}
												  id={record}
												/>
											}
										</div></div>
									)
								}
							</div>
							<div className="row">
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="email">
										{editableEmployee == null ? "" : "*"}
										Correo electrónico
									</label>
									{
										editableEmployee == null
											?
										<div className="roView">
											<br />
											{selectedEmployee.email}
										</div>
											:
										<input onChange={handleRegistryChange} placeholder="Correo electrónico"
										  onBlur={validateField} ref={emailRef} id="email"
										  value={editableEmployee.email} maxLength={50} style={{width : "100%"}} name="email"
										  onFocus={validateField} disabled={idleLocked}
										  type="email" required
										/>
									}
								</div></div>
								{
									editableEmployee == null ? "" : <div className="col-md-6"><div className="inputContainer">
										<label htmlFor="name">
											{editableEmployee.id == null ? "*" : ""}
											Contraseña
										</label>
										{
											editableEmployee == null
													?
												<div className="roView">
													<br />
													{selectedEmployee.password}
												</div>
													:
													<div style={{display: "flex", justifyContent: "center"}}>
														<input value={editableEmployee.password} name="password" maxLength={50}
														  required={editableEmployee.id == null} minLength={6} ref={passwordRef}
														  placeholder="Contraseña" style={{ width: "100%" }} id="password"
														  type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
														  onFocus={(event) => {if(editableEmployee.id == null)validateField(event)}}
														  onBlur={(event) => {if(editableEmployee.id == null)validateField(event)}}
														  onChange={handleRegistryChange} disabled={idleLocked}
														/>
														{
															editableEmployee.id ? <></> : <button disabled={idleLocked}
															  style={{position : "absolute", bottom : 0, border : "transparent", backgroundColor : "transparent"}}
															  type="button" onClick={toggleShowPassword}
															>
																<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
															</button>
														}
											  </div>
										}
									</div></div>
								}
							</div>
							<br />
							<UnderlineHeading name={"Observaciones"} />
							{
								editableEmployee == null ? (selectedEmployee.observations || "-") : <textarea
								  id="observations"
								  onChange={handleRegistryChange}
								  onBlur={(event : any) => formatField(event, handleRegistryChange)}
								  value={editableEmployee.observations}
								  className="notesField form-control"
								  disabled={idleLocked}
								  ref={observationsRef}
								  name="observations"
								/>
							}
						</Tab>
					</Tabs>
				</div>
			</div></div></form></DataDisplayModal>
		}
		{selectedStudent == null ? "" : <StudentInfoModal selectedStudent={selectedStudent} allowedUpdate={allowed.update}
		  academicDegrees={academicDegrees} closeFn={setSelectedStudent} afterCloseFn={search} date={currentDate}
		/>}
	</>
}

export default Employees
