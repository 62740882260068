import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    token: string;
}

interface sessionState {
    user: UserState | null;
    roles: any[],
    permissions: any[],
    currentRole: any,
    allowedCampuses: any[],
    currentCampusId: number,
    currentCampus: any
}

const initialState: sessionState = {
    user: null,
    roles: [],
    permissions: [],
    currentRole: null,
    allowedCampuses: [],
    currentCampusId: 0,
    currentCampus: null
};

const sessionSlice = createSlice({
    name: 'sessionState',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<any>) {
            state.user = action.payload;
        },
        setCurrentRole(state, action: PayloadAction<any>) {
            state.currentRole = action.payload;
        },
        setRoles(state, action: PayloadAction<any>) {
            state.roles = action.payload;
        },
        setPermissions(state, action: PayloadAction<any>) {
            state.permissions = action.payload;
        },
        setAllowedCampuses(state, action: PayloadAction<any>) {
            state.allowedCampuses = action.payload;
        },
        setCurrentCampusId(state, action: PayloadAction<number>) {
            state.currentCampusId = action.payload;
        },
        setCurrentCampus(state, action: PayloadAction<any>) {
            state.currentCampus = action.payload;
        },
        handleLogout(state) {
            state.user = null;
            state.roles = [];
            state.currentRole = null;
            state.allowedCampuses = [];
            state.currentCampusId = 0;
            state.currentCampus = null
        },
    }
});

export const {
    setUser,
    setCurrentRole,
    setRoles,
    setPermissions,
    setAllowedCampuses,
    setCurrentCampusId,
    setCurrentCampus,
    handleLogout
}
    = sessionSlice.actions;
export default sessionSlice.reducer;