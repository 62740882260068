import React, {
    useState
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";

import Constants from "../../../constants";
import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../globals";
import { styles } from "./style";
import HttpManager from "../../../services/HttpManager";
import { getErrorMessage } from "../../../common";
import { UserState } from "../../../redux/reducers/sessionReducer";


export interface GenerateInvoiceProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * 
     * @function to Handle success payment
     */
    onSuccess: () => void
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
}

interface FormData {
    notes: string;
    invoiceItems: {
        tuitions: { id: number; discount: number; typeOfDiscount: number }[];
        articles: { id: number; discount: number; typeOfDiscount: number }[];
    };
}

const GenerateInvoiceModalComponent: React.FC<GenerateInvoiceProps> = ({
    onSuccess,
    closeModal
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const http = HttpManager.getInstance();
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const studentData: any = useSelector((state: RootState) => state.rootReducer.accountStatement.studentData);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const itemsInInvoice: any = useSelector((state: RootState) => state.rootReducer.accountStatement.invoiceItems);
    const [total, setTotal] = useState<number>(0);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<FormData>();

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }));
    }

    const generateInvoice = async (data: FormData) => {
        try {
            dispatch(enableIdleLockSwitch());
            const invoiceItems: any = {
                tuitions: Array.from(itemsInInvoice.tuitions),
                articles: Array.from(itemsInInvoice.articles)
            };
            const payload = {
                ...data,
                invoiceItems
            };
            const response = await http.invoiceService.generateInvoice(
                userData?.token ?? "",
                currentCampus.id,
                studentData.id,
                payload
            );
            reset();
            onSuccess();
        } catch (error) {
            console.error("Error submitting form:", error);
            onError(getErrorMessage(error));
        } finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    return (
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{
                    flexGrow: 1
                }}>
                    <span style={{
                        paddingLeft: "10px"
                    }}>
                        {Constants.Common.generateInvoice}
                    </span>
                </h2>
                <button style={{
                    ...styles.btnCloseModal,
                    flexGrow: 0,
                    zIndex: 2
                }}
                    onClick={closeModal}
                >
                    <FontAwesomeIcon
                        icon={solid("times")}
                        style={{
                            height: "100%",
                            position: "relative",
                            bottom: "1px"
                        }}
                    />
                </button>
            </div>
            <form onSubmit={handleSubmit(generateInvoice)}
                className="form">
                <div style={{
                    background: "#F1F1FF",
                    padding: "15px"
                }}>
                    <h5 style={{
                        display: "inline-block",
                        width: "70%",
                        textAlign: "center"
                    }}>
                        {Constants.Screens.Finances.modalPayments.payment.receive}
                        {`(${currentCampus.currencyCode})`}
                    </h5>
                    <div style={{
                        display: "inline-block",
                        width: "30%",
                        textAlign: "right",
                        borderBottom: "1px solid #7678BF"
                    }}>
                        <h5 style={{
                            marginBottom: "unset"
                        }}>
                            {sprintf("%.02f", total)}
                        </h5>
                    </div>
                </div>
                <div style={{
                    padding: "10px"
                }}>
                    <label htmlFor="notes"
                        style={{
                            position: "relative"
                        }}>
                        {Constants.Common.notes}
                    </label>
                    <div style={{
                        wordBreak: "break-all",
                        textOverflow: "unset"
                    }}>
                        <br />
                        <textarea id="notes"
                            {
                            ...register('notes',
                                {
                                    required: 'El campo notas es requerido.',
                                    minLength: {
                                        value: 10,
                                        message: 'La nota debe tener al menos 10 caracteres'
                                    },
                                    // maxLength: {value: 255, message: 'La nota no debe exceder los 255 caracteres'}
                                }
                            )}
                            placeholder={Constants.Common.notes}
                            name="notes"
                            style={{
                                resize: "vertical",
                                width: "100%",
                                border: "1px solid #7678BF",
                                borderRadius: "5px",
                                minHeight: "150px",
                                padding: "5px"
                            }}
                        />
                        {errors.notes &&
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {errors.notes.message}
                            </p>
                        }
                    </div>
                </div>
                <div style={{
                    textAlign: "center"
                }}>
                    <button
                        className="btn btn-outline-danger rounded-pill"
                        type="submit"
                        disabled={idleLocked}
                        style={{
                            width: "50%"
                        }}>
                        {Constants.Common.generateInvoice}
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
}

export default GenerateInvoiceModalComponent;