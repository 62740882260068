import { AxiosResponse } from "axios";
import { axiosInstance } from "./axiosInstance";
import { AppliedCreditDTO } from "../../models/AppliedCreditDTO";
import { PaginatedResponse } from "../../models/common/PaginatedResponseDTO";

const RESOURCE_URL_V2 = "/admin/campus/";

export class AppliedCreditService {

	getAppliedCreditsByCampusId = async (campusId: number, perPage: number, criteria?: any): Promise<AxiosResponse<PaginatedResponse<AppliedCreditDTO>>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/appliedCredits?perPage=${perPage}`;
		if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
			try {
				const criteriaJson = JSON.stringify(criteria);
				uri = `${uri}&criteria=${encodeURIComponent(criteriaJson)}`;
			} catch (error) {
				console.error("Error serializando criteria as JSON:", error);
				throw new Error("Invalid criteria format");
			}
		}
		const response = await axiosInstance.get<PaginatedResponse<AppliedCreditDTO>>(uri);
		return response;
	}

	getPaginatedCredits = async (uri: string): Promise<AxiosResponse<PaginatedResponse<AppliedCreditDTO>>> => {
		let response = await axiosInstance.get<PaginatedResponse<AppliedCreditDTO>>(uri);
		return response;
	}

	getCreditById = async (campusId: number, creditId: number): Promise<AxiosResponse<AppliedCreditDTO>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/appliedCredits/${creditId}/_details`;
		let response = await axiosInstance.get<AppliedCreditDTO>(uri);
		return response;
	}

	applyPositiveCredit = async (campusId: number, studentId: number, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/appliedCredits`;
        const formData = new FormData();

        formData.append("amount", payload.amount);
        formData.append("creditDate", payload.creditDate);
        formData.append("notes", payload.notes || "");
        formData.append("invoiceItems", JSON.stringify(payload.invoiceItems));

        let response = await axiosInstance.post(uri, formData);
        return response;
    }

	addCreditToExistentInvoice = async (campusId: number, studentId: number, invoiceId: number, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/invoices/${invoiceId}/_addAppliedCredit`;
        const formData = new FormData();

        formData.append("amount", payload.amount);
        formData.append("creditDate", payload.creditDate);
        formData.append("notes", payload.notes || "");
        formData.append("invoiceItems", JSON.stringify(payload.invoiceItems));

        let response = await axiosInstance.post(uri, formData);
        return response;
    }

	studentTransfersCredit = async (campusId: number, fromStudentId: any, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${fromStudentId}/appliedCredits/_transfer`;
        let response = await axiosInstance.post(uri, payload);
        return response;
    }
}