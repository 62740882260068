import { CSSProperties } from "react";
import Constants from "../../../constants";

const SelectStudentContainer: CSSProperties = {
    paddingTop: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    overflowY: "visible",
}

export const styles = {
    SelectStudentContainer
}