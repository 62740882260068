import Constants from "../../../../../constants"
import { CSSProperties } from 'react';

const container: CSSProperties = {
	padding: "30px 30px 0px 30px",
	display: "flex",
	flexDirection: "column"
}

const header: CSSProperties = {
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-start"
}

const studentDataRow: CSSProperties = {
	display: "flex",
	flexFlow: "wrap",
	flexWrap: "wrap",
	gap: "15px",
	margin: "10px 0 10px 0"
}

const balanceGrid: CSSProperties = {
	display: "flex",
	width: "450px",
	flexDirection: "column",
	gap: "20px"
}

const balanceRow: CSSProperties =
{
	width: "100%",
	display: "flex",
	alignItems: "baseline",
	justifyContent: "space-between",
	gap: 10,
	borderBottom: `2px solid ${Constants.Styles.text.colors.gray}`
}

const subtitle: CSSProperties = {
	font: Constants.Styles.text.fonts.robotoNormal12,
	color: Constants.Styles.text.colors.veryPeri,
	opacity: 1,
	marginBottom: 0,
	position: "relative"
}

const balanceContainer: CSSProperties = {
	display: "flex",
	flexDirection: "column"
}

const subtitleContainer: CSSProperties = {
	display: "flex",
	alignItems: "baseline",
	justifyContent: "center",
	gap: 10
}

const financeTextContainer: CSSProperties = {
	display: "flex",
	justifyContent: "flex-start",
	marginBottom: "10px"
}

const balanceText: CSSProperties = {
	color: Constants.Styles.text.colors.blackPrimary,
	font: Constants.Styles.text.fonts.robotoBold20,
	paddingRight: "15px",
	verticalAlign: "bottom",
	marginBottom: 0
}

const pendingAmount: CSSProperties = {
	font: Constants.Styles.text.fonts.robotoBold24,
	color: Constants.Styles.text.colors.red,
	marginBottom: 0,
	textAlign: "right"
}

const pendingAmountBlackText: CSSProperties = {
	font: Constants.Styles.text.fonts.robotoBold24,
	color: Constants.Styles.text.colors.blackPrimary,
	marginBottom: 0,
	textAlign: "right"
}

const positiveBalance: CSSProperties = {
	color: Constants.Styles.text.colors.green,
	font: Constants.Styles.text.fonts.robotoBold24,
	marginBottom: 0,
	textAlign: "right"
}

const studentDataText: CSSProperties = {
	font: Constants.Styles.text.fonts.robotoBold16,
	marginBottom: 0
}

const financeText: CSSProperties = {
	font: Constants.Styles.text.fonts.robotoNormal14,
	color: Constants.Styles.text.colors.gray,
	opacity: 1,
	marginBottom: 0
}


export const styles =
{
	container,
	header,
	studentDataRow,
	balanceContainer,
	financeTextContainer,
	balanceGrid,
	studentDataText,
	subtitle,
	financeText,
	subtitleContainer,
	balanceText,
	balanceRow,
	pendingAmount,
	pendingAmountBlackText,
	positiveBalance
}