import { AxiosResponse } from "axios";
import { GeneralBalanceDTO } from "../../models/GeneralBalanceDTO";
import { axiosInstance } from "./axiosInstance";
import { ArticleDTO } from "../../models/ArticleDTO";
import { CourseTuitionDTO } from "../../models/CourseTuitionDTO";

const RESOURCE_URL_V2 = "/admin/campus/";

export class FinancesService {

	getStudentGeneralBalance = async (campusId: number, studentId: number): Promise<AxiosResponse<GeneralBalanceDTO>> => {
		const uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/_generalBalance`;
		const generalBalance = await axiosInstance.get<GeneralBalanceDTO>(uri);
		return generalBalance;
	}

	getStudentCourses = async (campusId: number, studentId: number): Promise<AxiosResponse<CourseTuitionDTO[]>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/_courses`;
		let studentCourses = await axiosInstance.get<CourseTuitionDTO[]>(uri);
		return studentCourses;
	}

	getStudentArticles = async (campusId: number, studentId: number): Promise<AxiosResponse<ArticleDTO[]>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/_articles`;
		let studentArticles = await axiosInstance.get<ArticleDTO[]>(uri);
		return studentArticles;
	}

	getEnrollmentDetails = async (campusId: number, studentId: number, enrollmentId: number) => {
		let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/enrollments/${enrollmentId}/_details`;
		let tuitions = await axiosInstance.get(uri);
		return tuitions;
	}

	getOwedTuitionsByEnrollment = async (campusId: number, enrollmentId: number) => {
		let uri = `${RESOURCE_URL_V2}${campusId}/enrollments/${enrollmentId}/_tuitions`;
		let tuitions = await axiosInstance.get(uri);
		return tuitions;
	}

}