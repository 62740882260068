import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Constants from "../../../../constants";
import { styles } from "./style";
import {
    RootState
} from "../../../../globals";
import { AppliedCreditDTO } from "../../../../models/AppliedCreditDTO";
import utils from "../../../../utils/utils";


export interface CreditDetailProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Credit info data to show.
     */
    credit: AppliedCreditDTO;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
}

const CreditDetailModalComponent: React.FC<CreditDetailProps> = ({
    credit,
    closeModal
}) => {
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currencies: any = useSelector((state: RootState) => state.rootReducer.catalog.currencies || []);
    const [currency, setCurrency] = useState<any>({});

    useEffect(() => {
        const newCurrency = currencies.find((currency: any) => currency.id === credit.currencyId) || {};
        setCurrency(newCurrency);
    }, [currencies]);

    return (
        credit &&
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{
                    flexGrow: 1
                }}>
                    <span style={{
                        paddingLeft: "10px"
                    }}>
                        {utils.renderCreditDetailTitle(credit.typeId, credit.invoiceId)}
                    </span>
                </h2>
                <button style={{
                    ...styles.btnCloseModal,
                    flexGrow: 0,
                    zIndex: 2
                }}
                    onClick={closeModal}
                >
                    <FontAwesomeIcon
                        icon={solid("times")}
                        style={{
                            height: "100%",
                            position: "relative",
                            bottom: "1px"
                        }}
                    />
                </button>
            </div>
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                gap: "10px",
                padding: "10px"
            }}>
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <label htmlFor="name"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.student}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {credit.ownerFirstName}
                        &nbsp;
                        {credit.ownerLastName}
                    </div>
                </div>
                <div style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right"
                }}>
                    <label htmlFor="name"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.creditNumber}
                    </label>
                    <div style={{
                        borderBottom: "1px solid #7678BF"
                    }}>
                        {credit.id}
                    </div>
                </div>
            </div>

            <div style={{
                background: "#F1F1FF",
                padding: "15px"
            }}>
                <h5 style={{
                    display: "inline-block",
                    width: "70%",
                    textAlign: "center"
                }}>
                    {utils.renderImportCreditTitle(credit.typeId, currency.code)}
                </h5>
                <div style={{
                    display: "inline-block",
                    width: "30%",
                    textAlign: "right",
                    borderBottom: "1px solid #7678BF"
                }}>
                    <h5 style={{
                        marginBottom: "unset"
                    }}>
                        {credit.amount.moneyFormat}
                    </h5>
                </div>
            </div>

            <div style={{
                display: "flex",
                alignItems: "flex-end",
                padding: "10px",
                gap: "10px"
            }}>
                <div style={{ width: "50%" }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {utils.renderCreditDateTitle(credit.typeId)}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {credit.createdAt}
                    </div>
                </div>
                <div style={{
                    width: "50%"
                }}>
                    <label htmlFor="status"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.status}
                    </label>
                    <div className="roView"
                        style={{
                            color: Constants.Styles.text.colors.blackPrimary,
                            font: Constants.Styles.text.fonts.robotoBold16,
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {credit.status}
                    </div>
                </div>
            </div>

            <div style={{
                display: "flex",
                padding: "10px",
                alignItems: "flex-end",
                gap: "10px"
            }}>
                <div style={{ width: "50%" }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {utils.renderInvoiceTitle(credit.typeId)}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {credit.invoiceId > 0 ? credit.invoiceId : "-"}
                    </div>
                </div>
                <div style={{
                    width: "50%"
                }}>
                    <label htmlFor="status"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.transfered}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {utils.renderIsTransferedCredit(credit.typeId)}
                    </div>
                </div>
            </div>

            {credit.previousOwnerId > 0
                ? <div style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    gap: "10px"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <h5 style={{
                            margin: 0,
                            padding: 0
                        }}>
                            {Constants.Common.transferedFrom}
                        </h5>
                    </div>
                    <div style={{
                        display: "flex",
                        padding: "0 10px 10px 10px",
                        gap: "10px"
                    }}>
                        <div style={{ width: "50%" }}>
                            <label htmlFor="date"
                                style={{
                                    ...styles.smallGrayText,
                                    position: "relative"
                                }}>
                                {Constants.Common.student}
                            </label>
                            <div className="roView"
                                style={{
                                    borderBottom: "1px solid #7678BF"
                                }}>
                                {credit.previousOwnerFirstName}
                                &nbsp;
                                {credit.previousOwnerLastName}
                            </div>
                        </div>
                        <div style={{
                            width: "50%"
                        }}>
                            <label htmlFor="status"
                                style={{
                                    ...styles.smallGrayText,
                                    position: "relative"
                                }}>
                                {Constants.Common.correlative}
                            </label>
                            <div className="roView"
                                style={{
                                    borderBottom: "1px solid #7678BF"
                                }}>
                                {credit.previousOwnerCorrelative}
                            </div>
                        </div>
                    </div>
                </div>
                : <React.Fragment />
            }

            {credit.futureOwnerId > 0
                ? <div style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    gap: "10px"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <h5 style={{
                            margin: 0,
                            padding: 0
                        }}>
                            {Constants.Common.transferedTo}
                        </h5>
                    </div>
                    <div style={{
                        display: "flex",
                        padding: "0 10px 10px 10px",
                        gap: "10px"
                    }}>
                        <div style={{ width: "50%" }}>
                            <label htmlFor="date"
                                style={{
                                    ...styles.smallGrayText,
                                    position: "relative"
                                }}>
                                {Constants.Common.student}
                            </label>
                            <div className="roView"
                                style={{
                                    borderBottom: "1px solid #7678BF"
                                }}>
                                {credit.futureOwnerFirstName}
                                &nbsp;
                                {credit.futureOwnerLastName}
                            </div>
                        </div>
                        <div style={{
                            width: "50%"
                        }}>
                            <label htmlFor="status"
                                style={{
                                    ...styles.smallGrayText,
                                    position: "relative"
                                }}>
                                {Constants.Common.correlative}
                            </label>
                            <div className="roView"
                                style={{
                                    borderBottom: "1px solid #7678BF"
                                }}>
                                {credit.futureOwnerCorrelative}
                            </div>
                        </div>
                    </div>
                </div>
                : <React.Fragment />
            }

            <div style={{
                display: "flex",
                padding: "10px",
                marginBottom: "20px"
            }}>
                <div style={{
                    width: "100%"
                }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.notes}
                    </label>
                    <p style={{
                        padding: "5px",
                        margin: "0",
                        wordBreak: "break-word",
                        borderBottom: "1px solid #7678BF"
                    }}>
                        {credit.notes}
                    </p>
                </div>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <button className="btn btn-outline-default rounded-pill"
                        onClick={closeModal}
                        disabled={idleLocked}
                        type="button"
                        style={{
                            width: "50%",
                            border: "1px solid #000000"
                        }}
                    >
                        {Constants.Common.close}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );

}

export default CreditDetailModalComponent;