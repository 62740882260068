import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../globals";
import Constants from "../../constants";
import { styles } from "./style";

export interface SearchInputProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
    * Content to render inside the card.
    */
    search: any;
    /**
     * 
     * @function to change search value
     */
    onChangeSearch: (value: string) => void;
    /**
     * 
     * @function to fetch records by search value
     */
    onClickSearch: () => void;
    /**
     * 
     * @function to clear value
     */
    onClearSearch: () => void;
}

const SearchInputComponent: React.FC<SearchInputProps> = ({
    search,
    onChangeSearch,
    onClickSearch,
    onClearSearch
}) => {

    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            onClickSearch();
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.clearFilter}>
                <button
                    disabled={idleLocked}
                    onClick={onClearSearch}
                    type="button"
                    style={styles.clearFilterBtn}
                >
                    <FontAwesomeIcon
                        icon={search ? solid("times") : solid("filter")}
                    />
                </button>
            </div>
            <div style={styles.inputContainer}>
                <input
                    id="search"
                    placeholder={Constants.Common.search}
                    type="text"
                    disabled={idleLocked}
                    style={styles.inputSearch}
                    maxLength={50}
                    value={search || ""}
                    onChange={(e: any) => onChangeSearch(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <div style={styles.searchBtnContainer}>
                <button
                    disabled={idleLocked}
                    onClick={onClickSearch}
                    type="button"
                    style={styles.searchBtn}
                >
                    <FontAwesomeIcon
                        icon={solid("arrow-turn-down")}
                        transform={{ rotate: 90 }}
                    />
                </button>
            </div>
        </div>
    )
}

export default SearchInputComponent;