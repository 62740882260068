import React,
{
    useEffect,
    useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { sprintf } from "sprintf-js";

import Constants, { TYPE_OF_DISCOUNT } from "../../../constants";
import { AppDispatch, RootState } from "../../../globals";
import "./style.css";
import { setInvoiceItems } from "../../../redux/reducers/accountStatementReducer";

export interface DiscountButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    charge: any;
    onDiscountChange: (discount: number) => void;
}

const DiscountButtonComponent: React.FC<DiscountButtonProps> = ({ charge, onDiscountChange }) => {
    const dispatch = useDispatch<AppDispatch>();
    const currencies: any = useSelector((state: RootState) => state.rootReducer.catalog.currencies || []);
    const [value, setValue] = useState<string>("0");
    const [currency, setCurrency] = useState<any>({});
    const [symbol, setSymbol] = useState<string>("%");
    const [discountInPercentage, setDiscountInPercentage] = useState<boolean>(false);
    const [percentageCalculated, setPercentageCalculated] = useState<string>("");

    const handleDiscountChange = (chargeId: number, discount: number, typeOfDiscount: number, isTuition: boolean) => {
        dispatch(setInvoiceItems({ chargeId, discount, typeOfDiscount, isTuition }));
    };

    const handleDiscount = (event: any, charge: any) => {
        const inputValue = event.target.value;
        const max = discountInPercentage ? 100 : charge.amount.value;

        if (/^\d*\.?\d{0,2}$/.test(inputValue)
            && Number(inputValue) >= 0
            && Number(inputValue) <= max) {
            const discountCalculated = discountInPercentage
                ? (Number(inputValue) / 100) * charge.amount.value
                : 0;
            setPercentageCalculated(sprintf("%.02f", discountCalculated));
            setValue(inputValue);
            onDiscountChange(discountInPercentage ? discountCalculated : inputValue);
            handleDiscountChange(charge.id,
                +inputValue,
                discountInPercentage ? TYPE_OF_DISCOUNT.PERCENTAGE : TYPE_OF_DISCOUNT.NUMERIC,
                charge.PRODUCT_TYPE === "TUITION"
            );
        } else {
            setValue("0");
        }
    }

    const handleTypeOfDiscount = () => {
        const typeOfDiscount = !discountInPercentage;
        setValue("0");
        setDiscountInPercentage(typeOfDiscount);
        onDiscountChange(0);
        handleDiscountChange(charge.id, 0,
            typeOfDiscount ? TYPE_OF_DISCOUNT.PERCENTAGE : TYPE_OF_DISCOUNT.NUMERIC,
            charge.PRODUCT_TYPE === "TUITION"
        );
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[\d.]/.test(event.key)
            && event.key !== 'Backspace'
            && event.key !== 'ArrowLeft'
            && event.key !== 'ArrowRight') {
            event.preventDefault();
        }
        if (event.key === '.' && event.currentTarget.value.includes('.')) {
            event.preventDefault();
        }
    }

    useEffect(() => {
        setValue("0");
        const newCurrency = currencies.find((currency: any) => currency.id === charge.currencyId) || {};
        setCurrency(newCurrency);
    }, [charge, currencies]);

    useEffect(() => {
        setSymbol(discountInPercentage ? "%" : currency.code);
        if (discountInPercentage) {
            setPercentageCalculated(sprintf("%.02f", "0"));
        } else {
            setPercentageCalculated(sprintf("%.02f", charge.amount.value));
        }
    }, [discountInPercentage, currency]);

    return charge ? (
        <div style={{
            width: "150px",
            padding: "0 15px",
            textAlign: "right",
            whiteSpace: "nowrap",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <div style={{
                border: "1px solid #8F91DA",
                borderRadius: "10px 10px 0px 10px",
                display: "inline-flex",
                width: "100%"
            }}>
                <input name={"discountVal" + charge.id}
                    value={value || "0"}
                    min={0}
                    max={discountInPercentage ? 100 : +charge.amount.value}
                    style={{
                        width: "90%",
                        borderRadius: "10px 0px 0px 10px",
                        textAlign: "right"
                    }}
                    onChange={(event: any) => handleDiscount(event, charge)}
                    onKeyDown={handleKeyDown}
                    type="text"
                    required
                    disabled={+charge.subTotal.value <= 0}
                />
                <button className="btn discount-button"
                    type="button"
                    onClick={handleTypeOfDiscount}
                    disabled={+charge.subTotal.value <= 0}
                >
                    {symbol}
                </button>
            </div>
            {!discountInPercentage ? <></> : <span
                style={{
                    color: Constants.Styles.text.colors.blackPrimary,
                    fontSize: 12
                }}
            >
                {currency.symbol}
                {percentageCalculated}
            </span>
            }
        </div>
    )
        : <React.Fragment></React.Fragment>
}

export default DiscountButtonComponent;