import Constants
    from "../../../constants"

import "../../../assets/css/variables.css";

const btnCloseModal =
{
    top: 15,
    right: 15,
    borderRadius: "100%",
    width: 20,
    height: 20,
    border: "1px solid " + Constants.Styles.background.redButtonClose,
    color: Constants.Styles.text.colors.redButtonClose,
    background: Constants.Styles.background.transparent
}

const errorMessage =
{
    color: Constants.Styles.text.colors.red,
    width: "100%",
    padding: "5px"
}

export const styles =
{
    btnCloseModal,
    errorMessage
}