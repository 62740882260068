import Constants from "../../../../constants"
import "../../../../assets/css/variables.css";

const subtitleContainer =
{
    display: "flex",
    alignItems: "baseline",
    justifyContent: "start",
    gap: 10,
    flexFlow: "wrap"
}

const subtitle =
{
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.gray,
    opacity: 1,
    marginLeft: "10px",
    paddingTop: "7px",
    borderBottom: "2px solid #C0C0C0",
    paddingBottom: "10px"    
}

const smallGrayText =
{
    font: Constants.Styles.text.fonts.robotoNormal12,
    color: Constants.Styles.text.colors.grayOpacity,
    opacity: 1,
    paddingTop: "7px"
}

const boldText =
{
    fontWeight: "bold",
    marginBottom: 0,
    flexGrow: 1,
    padding: "0px"
}

const btnCloseModal =
{
    top: 15,
    right: 15,
    borderRadius: "100%",
    width: 20,
    height: 20,
    border: "1px solid " + Constants.Styles.background.redButtonClose,
    color: Constants.Styles.text.colors.redButtonClose,
    background: Constants.Styles.background.transparent
}

const cellStyle = {
    borderBottom: "1px solid #C7C7C7",
    textAlign: "right",
    width: "1px",
    verticalAlign: "bottom",
    padding: "unset",
    paddingBottom: "10px"    
}

const errorMessage =
{
    color: Constants.Styles.text.colors.red,
    width: "100%",
    padding: "5px"
}

export const styles = {
    subtitleContainer,
    subtitle,
    smallGrayText,
    boldText,
    btnCloseModal,
    cellStyle,
    errorMessage
}