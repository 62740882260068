import { CSSProperties } from "react"
import Constants from "../../../constants"

const btnContainer: CSSProperties = {
    position: "absolute",
    top: 100,
    right: 50,
    width: "50px",
    height: "50px",
    zIndex: 9999,
    backgroundColor: Constants.Styles.background.white,
    border: `2px solid ${Constants.Styles.background.grayOpacity}`,
    borderRadius: "50px"
}

const badgeContainer: CSSProperties = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    justifyContent: "center"
}

const badge: CSSProperties = {
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translate(50%, -50%)",
    width: "auto",
    height: "auto"
}

export const styles = {
    btnContainer,
    badgeContainer,
    badge
}