import Constants
	from "./../../constants"

const container =
{
	flexGrow: "1",
	minWidth: "100px",
	maxWidth: "100%",
	padding: "30px 30px 0px 30px",
	flexFlow: "wrap"
}

const card =
{
	borderRadius: 5,
	padding: "20px 0px",
	marginBottom: 0
}

const header =
{
	display: "flex",
	marginLeft: 25
};

const title =
{
	font: Constants.Styles.text.fonts.robotoNormal37,
	color: Constants.Styles.text.colors.gray,
	opacity: 1
};

const subHeader =
{
	display: "flex",
	gap: 20,
	marginBottom: 10,
	flexFlow: "wrap"
};

const subtitleContainer =
{
	display: "flex",
	alignItems: "baseline",
	justifyContent: "start",
	gap: 10,
	flexFlow: "wrap"
}

const subtitle =
{
	font: Constants.Styles.text.fonts.robotoNormal12,
	color: Constants.Styles.text.colors.gray,
	opacity: 1,
	marginLeft: "10px",
	paddingTop: "7px"
}

const periodText =
{
	color: Constants.Styles.text.colors.orange,
	marginBottom: 0,
	flexGrow: 1
}

const boldText =
{
	fontWeight: "bold",
	marginBottom: 0,
	flexGrow: 1,
	paddingRight: "15px"
}

const courseText =
{
	font: Constants.Styles.text.fonts.robotoBold20
}

const pendingPaymentText =
{
	color: Constants.Styles.text.colors.red,
	font: Constants.Styles.text.fonts.robotoBold16
}

const tableHeader =
{
	color: Constants.Styles.text.colors.gray,
	font: Constants.Styles.text.fonts.robotoMedium16,
	boderBottom: "2px solid " + Constants.Styles.text.colors.borderGray + " !important",
	marginBottom: 5
}

const tableRow =
{
	color: Constants.Styles.text.colors.gray,
	font: Constants.Styles.text.fonts.robotoMedium16
}

const tableDataContainer =
{
	borderBottom: 0,
	padding: "0 12px"
}

const tableData =
{
	color: Constants.Styles.text.colors.blackPrimary,
	font: Constants.Styles.text.fonts.robotoNormal16
}

const totalSection =
{
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	flexFlow: "wrap",
	marginTop: 10,
	marginRight: 10,
	gap: 5
}

const totalText =
{
	color: Constants.Styles.text.colors.totalGray,
	font: Constants.Styles.text.fonts.robotoBold15,
	marginBottom: 0
}

const totalTextNumber =
{
	color: Constants.Styles.text.colors.red,
	font: Constants.Styles.text.fonts.robotoBold20,
	marginBottom: 0
}

const statusText =
{
	color: Constants.Styles.text.colors.blackPrimary,
	font: Constants.Styles.text.fonts.robotoBold16,
	marginBottom: 0
}

const statusRedText =
{
	...statusText,
	color: Constants.Styles.text.colors.red,
}

const checkBoxDisabled =
{
	padding: 0,
	border: "none",
	opacity: 0.3
}

const checkBoxEnabled =
{
	padding: 0,
	border: "none",
	opacity: 1
}

const showModal =
{
	/*width     : 500,*/
	height: "100vh",
	boxShadow: "-5px 0px 6px 0px rgba(61,61,61,1)"
}

const hideModal = {}

const modalBody =
{
	height: "100%",
	padding: 20,
	borderRadius: 0
}

const btnCloseModal =
{
	top: 15,
	right: 15,
	borderRadius: "100%",
	width: 20,
	height: 20,
	border: "1px solid " + Constants.Styles.background.redButtonClose,
	color: Constants.Styles.text.colors.redButtonClose,
	background: Constants.Styles.background.transparent
}

const separator =
{
	width: "100%",
	height: 1,
	backgroundColor: Constants.Styles.text.colors.blackPrimary,
	opacity: 1,
	margin: 0
}

export const styles =
{
	container, header, title, subtitleContainer, subHeader, subtitle, periodText, boldText, courseText, tableHeader, tableRow,
	pendingPaymentText, tableDataContainer, tableData, totalSection, totalText, totalTextNumber, card, checkBoxDisabled,
	checkBoxEnabled, showModal, hideModal, modalBody, btnCloseModal, separator, statusText, statusRedText
}