import { AxiosResponse } from "axios";
import { StudentDTO } from "../../models/StudentDTO";
import { axiosInstance } from "./axiosInstance";
import { PaginatedResponse } from "../../models/common/PaginatedResponseDTO";

const RESOURCE_URL_V2 = "/admin/campus/";

export class StudentService {

    async getStudentById(campusId: number, studentId: number): Promise<AxiosResponse<StudentDTO>> {
        const response = await axiosInstance
            .get<StudentDTO>(`${RESOURCE_URL_V2}${campusId}/students/${studentId}/_details`);
        return response;
    }

    getStudentsByCampusId = async (campusId: number, perPage: number, criteria?: any): Promise<AxiosResponse<PaginatedResponse<StudentDTO>>> => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/_list?perPage=${perPage}`;
        if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
            try {
                const criteriaJson = JSON.stringify(criteria);
                uri = `${uri}&criteria=${encodeURIComponent(criteriaJson)}`;
            } catch (error) {
                console.error("Error serializando criteria as JSON:", error);
                throw new Error("Invalid criteria format");
            }
        }
        const response = await axiosInstance.get<PaginatedResponse<StudentDTO>>(uri);
        return response;
    }

    getPaginatedStudents = async (uri: string): Promise<AxiosResponse<PaginatedResponse<StudentDTO>>> => {
        let response = await axiosInstance.get<PaginatedResponse<StudentDTO>>(uri);
        return response;
    }
}