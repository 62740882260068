import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { default as SelectComponent } from "react-select";
import Constants from "../../constants";

export interface AmountRangeProps extends React.HTMLAttributes<HTMLDivElement> {
    selectedOption: any; // Este ahora se ajustará a un solo valor en lugar de un array
    onOptionChange: (value: string) => void; // Cambia a un solo valor
    quantity: number;
    onQuantityChange: (value: number) => void;
    filterOptions?: { id: string; label: string; value: string }[];
    onFilterChange: () => void;
}

const AmountRangeComponent: React.FC<AmountRangeProps> = ({
    selectedOption,
    onOptionChange,
    quantity,
    onQuantityChange,
    filterOptions,
    onFilterChange
}) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = Number(e.target.value);
        onQuantityChange(newQuantity); // Actualiza la cantidad

        // Si hay opciones seleccionadas, actualiza el filtro
        if (selectedOption.length > 0 && newQuantity > 0) {
            onOptionChange(selectedOption);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onFilterChange(); // Aplica el filtro al presionar Enter
        }
    };

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%"
        }}>
            <SelectComponent
                id="amount-range-select"
                options={filterOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(selectedOption) => {
                    const value = selectedOption ? selectedOption.value : "";
                    onOptionChange(value);
                }}
                styles={{
                    menu: (provided) => ({
                        ...provided,
                        width: "100%", // Asegura que el contenedor del select sea 100%
                    }),
                    container: (provided) => ({
                        ...provided,
                        width: "100%", // Asegura que el contenedor del select sea 100%
                    }),
                    control: (provided) => ({
                        ...provided,
                        width: "100%", // Asegura que el control del select sea 100%
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        display: "flex", // Para permitir el uso de flexbox
                        alignItems: "center",
                        justifyContent: "center", // Centrar el contenido
                        textAlign: "center" // Centrar el texto
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        textAlign: "center", // Centrar el valor seleccionado
                    }),
                }}
                placeholder={`Select option`}
                value={filterOptions?.find(option => option.value === selectedOption) || null}
            />
            <div style={{
                display: "flex",
                gap: "10px",
                width: "100%"
            }}>
                <input
                    type="number"
                    value={quantity || 0}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter quantity"
                    disabled={selectedOption.length === 0}
                    style={{
                        width: "70%",
                        borderBottom: `2px solid ${Constants.Styles.text.colors.gray}`,
                        marginBottom: "5px"
                    }} />
                <button
                    onClick={onFilterChange}
                    style={{
                        width: "30%",
                        color: "#8F91DA",
                        padding: "10px",
                        background: "#F3F8FF",
                        height: "44px"
                    }}>
                    <FontAwesomeIcon
                        icon={solid("arrow-turn-down")}
                        transform={{ rotate: 90 }}
                    />
                </button>
            </div>
        </div>
    );
};

export default AmountRangeComponent;