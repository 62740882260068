import React, { useEffect, useState } from "react";
import { styles } from "./style";

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
    pages: any;
    pageSizes: number[];
    onPageSizeChange: (size: number) => void;
    itemCount: any
}

const PaginationComponent: React.FC<PaginationProps> = ({ pages, pageSizes, onPageSizeChange, itemCount }) => {
    const [activePage, setActivePage] = useState<number>(1);
    const [pageSize, setPageSize] = useState(pageSizes[0] ?? 10);

    const handlePageClick = (index: number, link: any) => {
        if (link.url) {
            setActivePage(index);
            link.onClick();
        }
    }

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSize = parseInt(event.target.value);
        setPageSize(newSize);
        onPageSizeChange(newSize);
    }

    useEffect(() => {
        const newActivePage = pages.findIndex((page: any) => page.active);
        setActivePage(newActivePage);
    }, [pages, pageSize, itemCount]);

    return (
        <div style={styles.container}>
            <div style={styles.pageContainer}>
                <label style={{
                    position: "inherit"
                }}>
                    Items per page:
                    <select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        style={styles.pageSizeSelect}
                    >
                        {pageSizes.map(size => (
                            <option
                                key={size}
                                value={size}
                                style={styles.sizeOption}>
                                {size}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <nav style={{
                ...styles.pageNavigationContainer,
                flexDirection: "column"
            }}>
                <label style={{
                    position: "inherit"
                }}>
                    {itemCount.start} a {itemCount.end} de {itemCount.total}
                </label>
                {pages && pages.length > 0 ?
                    <ul style={styles.pageItem}>
                        {pages.map((link: any, index: number) => (
                            <li key={index}
                                className={`page-item ${index === activePage ? "active" : ""}`}>
                                <button type="button"
                                    className={`btn btn-default ${link.url ? "active" : "disabled"}`}
                                    disabled={!link.url}
                                    onClick={() => handlePageClick(index, link)}
                                    aria-label={link.label.replace("Previous", "").replace("Next", "")}
                                    style={{
                                        opacity: index === activePage ? 1 : 0.8,
                                        color: index === activePage ? "red" : ""
                                    }}>
                                    <span aria-hidden="true">
                                        {
                                            link.label.replace("Previous", "")
                                                .replace("Next", "")
                                                .replace("&laquo;", "«")
                                                .replace("&raquo;", "»")
                                        }
                                    </span>
                                </button>
                            </li>
                        ))}
                    </ul>
                    : <React.Fragment />
                }
            </nav>
        </div>
    );
};

export default PaginationComponent;