import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {Popover}
	from "react-tiny-popover"
import {Tooltip}
	from "react-bootstrap"
import OverlayTrigger
	from "react-bootstrap/OverlayTrigger"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch
}
	from "./../../globals"
import
{
	scheduleStatus,
	tunning
}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import UnderlineHeading
	from "./../../components/underlineHeading"
import {SelectReact}
	from "./../../components/select"
import {DataDisplayModal}
	from "./../../components/sectionContainer"
import
{
	UIState,
	weekDays,
	dayHours,
	validateField,
	currencySuffix,
	revertStringDate,
	monthRef,
	isEmpty
}
	from "./../../common"
import SchedulesService
	from "./../../services/campus/schedules.service"
import courseImage
	from "./../../assets/images/course.png"

import "./../../components/dropdown/style.css"
import "./style.css"
import LeDatePicker from "../../components/leDatePicker"

type State                =  {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}

type Action =
{
	type    : "setIsButtonDisabled" ,
	payload : boolean
}

const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled":
			return {...state, isButtonDisabled : action.payload}
		break;
	}
}

const SchedulePrompt = (props : any)=>
{
	const [reservedHours, setReservedHours]                           = useState<any>({})
	const [state, trigger]                                            = useReducer(reducer, initialState)
	const dispatch                                                    = useDispatch()
	const idleLocked        : boolean                                 = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null                           = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle       : boolean                                 = useSelector((state : RootState) => state.themeToggle.value)
	const currentUserCampus : any                                     = useSelector((state : RootState) => state.currentUserCampus.value);
	const campusRef         : any                                     = useSelector((state : RootState) => state.campusRef.value)
	const currentUser       : any                                     = useSelector((state : RootState) => state.userProfile.value)
	const basicInfo         : any                                     = useSelector((state : RootState) => state.basicInfo.value)
	const [doubleDailySessionProvided, setDoubleDailySessionProvided] = useState<boolean>(false)
	const [wrongInterval, setWrongInterval]                           = useState<any>(null)
	const [noPeriodProvided, setNoPeriodProvided]                     = useState<boolean>(false)
	const [noClassroomProvided, setNoClassroomProvided]               = useState<boolean>(false)
	const [noHoursProvided, setNoHoursProvided]                       = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                         = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]                       = useState<boolean>(false)
	const [conflictingHours, setConflictingHours]                     = useState<boolean>(false)
	const [courseLoaded, setCourseLoaded]                             = useState<boolean>(false)
	const [levelLoaded, setLevelLoaded]                               = useState<boolean>(false)
	const [buildingLoaded, setBuildingLoaded]                         = useState<boolean>(false)
	const [classroomLoaded, setClassroomLoaded]                       = useState<boolean>(false)
	const [periodLoaded, setPeriodLoaded]                             = useState<boolean>(false)
	const [statusLoaded, setStatusLoaded]                             = useState<boolean>(false)
	const [startLoaded, setStartLoaded]                               = useState<boolean>(false)
	const [endLoaded, setEndLoaded]                                   = useState<boolean>(false)
	const [oneBatchSaved, setOneBatchSaved]                           = useState<boolean>(false)
	const [hourFillingRequested, setHourFillingRequested]             = useState<boolean>(false)
	const [UIStatus, setUIStatus]                                     = useState<number>(UIState.NORMAL)
	const [limitMonths, setLimitMonths]                               = useState<number>(4)
	const [editableSchedule, setEditableSchedule]                     = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                               = useState<boolean>(false)
	const [saveError, setSaveError]                                   = useState<any>(null)
	const [scheduleRef, setScheduleRef]                               = useState<any>({})
	let   recordFormRef                                               = useRef<HTMLFormElement >(null)
	let   nameRef                                                     = useRef<HTMLInputElement>(null)
	let   codeRef                                                     = useRef<HTMLInputElement>(null)
	let   studentsMinimumRef                                          = useRef<HTMLInputElement>(null)
	let   studentsLimitRef                                            = useRef<HTMLInputElement>(null)
	let   firstSurchargeDayRef                                        = useRef<HTMLInputElement>(null)
	let   secondSurchargeDayRef                                       = useRef<HTMLInputElement>(null)
	let   billableMonthsRef                                           = useRef<HTMLInputElement>(null)
	let   subscriptionPriceRef                                        = useRef<HTMLInputElement>(null)
	let   subscriptionSurchargeCostRef                                = useRef<HTMLInputElement>(null)
	let   secondSurchargeCostRef                                      = useRef<HTMLInputElement>(null)
	let   monthlyBillingPriceRef                                      = useRef<HTMLInputElement>(null)
	let   monthlyBillingSurchargeCostRef                              = useRef<HTMLInputElement>(null)
	let   campusSelRef                                                = useRef<HTMLSelectElement>(null)
	let   notesRef                                                    = useRef<HTMLTextAreaElement>(null)
	const suffix : string                                             = currencySuffix[campusRef[currentUserCampus].company.country_id -1]
	const side : string[]                                             = ["left", "right"]
	const unedit                                                      = ()=>
	{
		setEditableSchedule(null)
		setBuildingLoaded(false)
		setClassroomLoaded(false)
		setCourseLoaded(false)
		setLevelLoaded(false)
		setPeriodLoaded(false)

		if(editableSchedule.id == null)
		{
			props.closeFn()
		}
	}

	const reservedHoursFilling = async (recordData : any)=>
	{
		try
		{
			setUIStatus(UIState.locked)

			let result : any = await SchedulesService.searchSchedules
			(
				campusRef[currentUserCampus].id,
				{
					exceptionId  : recordData.id,
					startValue   : recordData.startValue,
					endValue     : recordData.endValue,
					classroomIds : [recordData.classroom.id],
					statusId     : scheduleStatus.ACTIVE
				}
			)

			if(result.status == 200 || result.status == 204)
			{
				matchingHoursSetting(result.data)
			}
			else
			{
				setSaveError("Información relativa al registro de información no pudo ser cargada")
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			setSaveError("Información relativa al registro de información no pudo ser cargada")
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setHourFillingRequested(false)
		}
	}

	const collateMentorSchedule = (e : any)=>
	{
		if(e.target.value == null || e.target.value.id == null)
		{
			handleRegistryChange(e)
		}
		else
		{
			if(props.courseRef && props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id].mentorRef["M_" + e.target.value.id].sessions)
			{
				let agenda : any  = {}
				let adjustedHours = editableSchedule.hours

				Object.keys(props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id].mentorRef["M_" + e.target.value.id].sessions).map
				(
					(hour : any)=>
					{
						if(adjustedHours[hour] && editableSchedule.level.mentorRef["M_" + e.target.value.id][hour] != true)
						{
							delete adjustedHours[hour]
						}
					}
				)

				setEditableSchedule
				({
					...editableSchedule, hours : adjustedHours,
					campus_id                  : editableSchedule.campus_id,
					building_id                : editableSchedule.building_id,
					mentor                     : e.target.value,
				})
			}
			else
			{
				let adjustedHours : any = editableSchedule.hours

				if(editableSchedule.level.mentorRef["M_" + e.target.value.id].sessions)
				{
					let agenda : any  = {}

					Object.keys(editableSchedule.level.mentorRef["M_" + e.target.value.id].sessions).map
					(
						(hour : any)=>
						{
							if(adjustedHours[hour] && editableSchedule.level.mentorRef["M_" + e.target.value.id][hour] != true)
							{
								delete adjustedHours[hour]
							}
						}
					)

					
				}

				setEditableSchedule
				({
					...editableSchedule, hours : adjustedHours,
					mentor                     : e.target.value
				})
			}
		}
	}

	const editSchedule = ()=>
	{
		let hours : any = {...props.selectedSchedule.hours}
		//Removes any half hour colliding with previosly registered halves with the same classroom and period----------------
		Object.keys(reservedHours).map
		(
			(hour : any)=>
			{
				if(hours[hour])
				{
					delete hours[hour]
				}
			}
		)
		//Removes any half hour colliding with previosly registered halves in charge of the current mentor-------------------
		if
		(
			(props.selectedSchedule.mentor && props.selectedSchedule.mentor.id) &&
				props.selectedSchedule.level.mentorRef["M_" + props.selectedSchedule.mentor.id]
		)
		{
			Object.keys(props.selectedSchedule.level.mentorRef["M_" + props.selectedSchedule.mentor.id]).map
			(
				(hour : any)=>
				{
					if(hours[hour] && props.selectedSchedule.level.mentorRef[props.selectedSchedule.mentor.id][hour] != props.selectedSchedule.id)
					{
						delete hours[hour]
					}
				}
			)
		}
		//Limitting initial hour setings-------------------------------------------------------------------------------------
		let schedule : any=
		{
			...props.selectedSchedule, hours : hours,
			notes                            : props.selectedSchedule.notes || "",
		}

		const periodStart      : Date    = new Date(schedule.period.start + " 23:00:00")
		const periodEnd        : Date    = new Date(schedule.period.end   + " 23:00:00")
		const datesOutOfBounds : boolean=
		!(
			(
				(schedule.startValue >= periodStart && schedule.startValue < periodEnd) &&
				(schedule.endValue <= periodEnd && schedule.endValue > periodStart) 
			)
				&&
			(schedule.startValue < schedule.endValue)
		)

		const limitMonthsVal = datesOutOfBounds ? 4 : 1 +
		(
			(schedule.endValue.getFullYear() - schedule.startValue.getFullYear()) * 12) + 
				((schedule.endValue.getMonth() + 1) - (schedule.startValue.getMonth() + 1)
		)


		if(schedule.billable_months != limitMonthsVal && datesOutOfBounds)
		{
			schedule.billable_months = limitMonthsVal
		}

		setLimitMonths(limitMonthsVal)
		setEditableSchedule(schedule)
	}

	const saveRecord = (override? : boolean)=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					recordFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				if(editableSchedule.classroom.id == null)
				{
					if(!unfilledFields && !noClassroomProvided)
					{
						setUnfilledFields(true)
						setNoClassroomProvided(true)

						setTimeout
						(
							() =>
							{
								setUnfilledFields(false)
								setNoClassroomProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(editableSchedule.period == null)
					{
						if(!unfilledFields && !noPeriodProvided)
						{
							setUnfilledFields(true)
							setNoPeriodProvided(true)

							setTimeout
							(
								() =>
								{
									setUnfilledFields(false)
									setNoPeriodProvided(false)
								},
								3000
							)
						}
					}
					else
					{
						let periodStart : Date    = new Date(editableSchedule.period.start + " 23:00:00")
						let periodEnd   : Date    = new Date(editableSchedule.period.end   + " 23:00:00")

						if
						(
							!(
								(
									(editableSchedule.startValue >= periodStart && editableSchedule.startValue < periodEnd) &&
									(editableSchedule.endValue <= periodEnd && editableSchedule.endValue > periodStart) 
								)
									&&
								(editableSchedule.startValue < editableSchedule.endValue)
							)
						)
						{
							if(isEmpty(wrongInterval))
							{
								setWrongInterval
								(<>
									Es necesario proporcionar ambos valores apropiados,
									<br />
									diferentes y dentro del periodo para las fechas de inicio y fin<br />
									al guardar el registro
								</>)

								setTimeout
								(
									() =>
									{
										setWrongInterval(null)
									},
									3000
								)
							}
						}
						else
						{
							if(editableSchedule.hours == null)
							{
								setUnfilledFields(true)
								setNoHoursProvided(true)

								setTimeout
								(
									() =>
									{
										setUnfilledFields(false)
										setNoHoursProvided(false)
									},
									3000
								)
							}
							else
							{
								let hourList : any = Object
									.keys(editableSchedule.hours)
									.map((hour : any) => parseInt(hour.substring(2)))
									.sort((a : number, b: number)=>(a - b))

								if(hourList.length < 1)
								{
									setUnfilledFields(true)
									setNoHoursProvided(true)

									setTimeout
									(
										() =>
										{
											setUnfilledFields(false)
											setNoHoursProvided(false)
										},
										3000
									)
								}
								else
								{
									let sessions                : any     = []
									let last                    : any     = sessions.length - 1
									let currentSequence         : any     = null
									let sequence                : any     = null
									let doubleDailySessionFound : boolean = false
									let hourNumber              : number

									for(let hour of hourList)
									{
										sequence = hour;

										if(((sequence - 5) != currentSequence))
										{
											if
											(
												currentSequence != null
													&&
												((sequence - (sequence % 1000)) == (currentSequence - (currentSequence % 1000)))
											)
											{
												doubleDailySessionFound = true
												break
											}

											sequence        = hour;
											currentSequence = sequence;
											hourNumber      = hour % 100

											sessions.push
											({
												weekDay    : (hour - (hour % 1000)),
												startHours : hourNumber,
												endHours   : hourNumber + 5
											})

											last++
										}
										else
										{
											sessions[last].endHours += 5;
											currentSequence         += 5;
										}
									}

									if(doubleDailySessionFound)
									{
										setUnfilledFields(true)
										setDoubleDailySessionProvided(true)

										setTimeout
										(
											() =>
											{
												setUnfilledFields(false)
												setDoubleDailySessionProvided(false)
											},
											3000
										)
									}
									else
									{
										if(saveError != null)
										{
											setSaveError(null)
										}

										setUIStatus(UIState.LOCKED)
										dispatch(enableIdleLockSwitch())

										SchedulesService.saveSchedule
										(campusRef[currentUserCampus].id, props.selectedSchedule,editableSchedule, hourList).then
										(
											()=>
											{
												setSaveSuccess(true)
												setUIStatus(UIState.SUCCESS)

												setTimeout
												(
													()=>
													{
														if(override != true)
														{
															props.closeFn()
															setEditableSchedule(null)
															props.afterCloseFn()
														}
														else
														{
															if(!oneBatchSaved)
															{
																setOneBatchSaved(true)
															}

															setEditableSchedule({...editableSchedule, hours : {}})
															reservedHoursFilling(editableSchedule)
														}

														setSaveSuccess(false)
														setUIStatus(UIState.NORMAL)
													},
													tunning.MODAL_DISMISS_DELAY
												)
											},
											(error : any)=>
											{
												console.log(error)
												setUIStatus(UIState.ERROR)
												dispatch(disableIdleLockSwitch())
												setSaveError
												(
													error.response.status == 409
															?
														"Hay conflictos en la información proporcionada (horas). " +
															"Revise que los valores sean únicos en comparación con los registros existentes"
															:
														"La información no pudo ser guardada"
												)
											},
										)
									}
								}
							}
						}
					}
				}
			}
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableSchedule({...editableSchedule, [e.target.name] : e.target.value})
		}
	}

	const hourFillingCompliance : any = (datesOutOfBounds : boolean, revisedSchedule : any)=>
	{
		if(datesOutOfBounds)
		{
			setSaveError("Ningún valor de las fechas proporcionadas puede estar fuera de los límites del periodo seleccionado")
			setUIStatus(UIState.WARNING)
		}
		else
		{
			if
			(
				!hourFillingRequested &&
				(
					(revisedSchedule.period && revisedSchedule.period.id) &&
					(
						(revisedSchedule.classroom && revisedSchedule.classroom.id) &&
							revisedSchedule.status_id == scheduleStatus.ACTIVE
					)
				)
			)
			{
				setHourFillingRequested(true)
			}
		}
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(props.selectedSchedule.id == null)
			{
				setEditableSchedule(props.selectedSchedule)
			}
			else
			{
				matchingHoursSetting(props.selectedSchedule.matchingHoursData)
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			if(editableSchedule)
			{
				if(!levelLoaded)
				{
					setLevelLoaded(true)
				}
				else
				{
					let alteredSchedule =
					{
						...editableSchedule, mentor :
						(
							editableSchedule.mentor == null ||
								props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id].mentorRef["M_" + editableSchedule.mentor.id] == null
						) ? null : props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id].mentorRef
							["M_" + editableSchedule.mentor.id]
					}

					if(editableSchedule.id == null)
					{
						alteredSchedule =
						{
							...alteredSchedule, subscription_price : props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id]["subscription_base_price"             + suffix],
							monthly_billing_price                  : props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id]["monthly_billing_base_price"          + suffix],
							subscription_surcharge_cost            : props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id]["subscription_surcharge_base_cost"    + suffix],
							monthly_billing_surcharge_cost         : props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id]["monthly_billing_surcharge_base_cost" + suffix],
							second_surcharge_cost                  : props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id]["second_surcharge_base_cost" + suffix]
						}
					}

					setEditableSchedule({...alteredSchedule})
				}
			}
		},
		[editableSchedule?.level_id]
	)

	useEffect
	(
		()=>
		{
			if(editableSchedule)
			{
				const periodSelected   : boolean = editableSchedule.period && editableSchedule.period.id
				let   modifiedSchedule : any = {...editableSchedule}

				if(periodSelected)
				{
					modifiedSchedule.endValue   = new Date(modifiedSchedule.period.end   + " 23:00:00")
					modifiedSchedule.startValue = new Date(modifiedSchedule.period.start + " 23:00:00")
				}

				if(!periodLoaded && editableSchedule.id)
				{
					modifiedSchedule.endValue   = new Date(modifiedSchedule.period.end   + " 23:00:00")
					modifiedSchedule.startValue = new Date(modifiedSchedule.period.start + " 23:00:00")
				}

				if(!periodLoaded && editableSchedule.id)
				{
					setPeriodLoaded(true)
				}
				else
				{
					setEditableSchedule({...modifiedSchedule})
				}
			}
		},
		[editableSchedule?.period]
	)
	//Using the least quantity of billable months when changing schedule start and end date (4 when greater than 4 months, the month diff when lower than months)
	useEffect
	(
		()=>
		{
			if(editableSchedule)
			{
				if(!startLoaded && editableSchedule.id)
				{
					setStartLoaded(true)
				}
				else
				{
					let modifiedSchedule : any     = {...editableSchedule}

					if(editableSchedule.period && modifiedSchedule.period.start && modifiedSchedule.period.end)
					{
						const periodStart      : Date    = new Date(modifiedSchedule.period.start + " 23:00:00")
						const periodEnd        : Date    = new Date(modifiedSchedule.period.end   + " 23:00:00")

						if(modifiedSchedule.startValue == null)
						{
							if(modifiedSchedule.period && modifiedSchedule.period.id)
							{
								modifiedSchedule.startValue = periodStart
							}
						}

						const datesOutOfBounds : boolean=
						!(
							(
								(modifiedSchedule.startValue >= periodStart && modifiedSchedule.startValue < periodEnd) &&
								(modifiedSchedule.endValue <= periodEnd && modifiedSchedule.endValue > periodStart) 
							)
								&&
							(modifiedSchedule.startValue < modifiedSchedule.endValue)
						)

						const limitMonthsVal = datesOutOfBounds ? 4 : 1 +
						(
							(modifiedSchedule.endValue.getFullYear() - modifiedSchedule.startValue.getFullYear()) * 12) + 
								((modifiedSchedule.endValue.getMonth() + 1) - (modifiedSchedule.startValue.getMonth() + 1)
						)

						setLimitMonths(limitMonthsVal)

						if(modifiedSchedule.billable_months != limitMonthsVal)
						{
							modifiedSchedule.billable_months = (limitMonthsVal > 4 ? 4 : limitMonthsVal)
						}

						setEditableSchedule({...modifiedSchedule})
						hourFillingCompliance(datesOutOfBounds, modifiedSchedule)
					}
				}
			}
		},
		[editableSchedule?.startValue]
	)

	useEffect
	(
		()=>
		{
			if(editableSchedule)
			{
				if(!endLoaded && editableSchedule.id)
				{
					setEndLoaded(true)
				}
				else
				{
					let modifiedSchedule : any = {...editableSchedule}

					if(editableSchedule.period && modifiedSchedule.period.start && modifiedSchedule.period.end)
					{
						const periodStart      : Date    = new Date(modifiedSchedule.period.start + " 23:00:00")
						const periodEnd        : Date    = new Date(modifiedSchedule.period.end   + " 23:00:00")

						if(modifiedSchedule.endValue == null)
						{
							if(modifiedSchedule.period && modifiedSchedule.period.id)
							{
								modifiedSchedule.endValue = periodEnd
							}
						}

						const datesOutOfBounds : boolean=
						!(
							(
								(modifiedSchedule.startValue >= periodStart && modifiedSchedule.startValue < periodEnd) &&
								(modifiedSchedule.endValue <= periodEnd && modifiedSchedule.endValue > periodStart) 
							)
								&&
							(modifiedSchedule.startValue < modifiedSchedule.endValue)
						)

						const limitMonthsVal : number = datesOutOfBounds ? 4 : 1 +
						(
							(modifiedSchedule.endValue.getFullYear() - modifiedSchedule.startValue.getFullYear()) * 12) + 
								((modifiedSchedule.endValue.getMonth() + 1) - (modifiedSchedule.startValue.getMonth() + 1)
						)

						if(modifiedSchedule.billable_months != limitMonthsVal)
						{
							modifiedSchedule.billable_months = (limitMonthsVal > 4 ? 4 : limitMonthsVal)
						}

						setLimitMonths(limitMonthsVal)
						setEditableSchedule({...modifiedSchedule})
						hourFillingCompliance(datesOutOfBounds, modifiedSchedule)
					}
				}
			}
		},
		[editableSchedule?.endValue]
	)

	useEffect
	(
		()=>
		{
			if(editableSchedule)
			{
				if(!statusLoaded)
				{
					setStatusLoaded(true)
				}
				else
				{
					if(editableSchedule.period && editableSchedule.period.id)
					{
						const periodStart       : Date = new Date(editableSchedule.period.start + " 23:00:00")
						const periodEnd         : Date = new Date(editableSchedule.period.end   + " 23:00:00")
						const  datesOutOfBounds : boolean=
						!(
							(
								(editableSchedule.startValue >= periodStart && editableSchedule.startValue < periodEnd) &&
								(editableSchedule.endValue <= periodEnd && editableSchedule.endValue > periodStart) 
							)
								&&
							(editableSchedule.startValue < editableSchedule.endValue)
						)

						hourFillingCompliance(datesOutOfBounds, editableSchedule)
					}
				}
			}
		},
		[editableSchedule?.status_id]
	)

	useEffect
	(
		()=>
		{	
			if(editableSchedule)
			{
				if(!classroomLoaded && editableSchedule.id)
				{
					setClassroomLoaded(true)
				}
				else
				{
					if(editableSchedule.period && editableSchedule.period.id)
					{
						const periodStart      : Date = new Date(editableSchedule.period.start + " 23:00:00")
						const periodEnd        : Date = new Date(editableSchedule.period.end   + " 23:00:00")
						const datesOutOfBounds : boolean=
						!(
							(
								(editableSchedule.startValue >= periodStart && editableSchedule.startValue < periodEnd) &&
								(editableSchedule.endValue <= periodEnd && editableSchedule.endValue > periodStart) 
							)
								&&
							(editableSchedule.startValue < editableSchedule.endValue)
						)

						hourFillingCompliance(datesOutOfBounds, editableSchedule)
					}
				}
			}
		},
		[editableSchedule?.classroom]
	)

	useEffect
	(
		()=>
		{
			if(hourFillingRequested)
			{
				reservedHoursFilling(editableSchedule)
			}
		},
		[hourFillingRequested]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const matchingHoursSetting = (matchingHoursData : any)=>
	{
		let  agenda      : any = {}
		let scheduleMap  : any = {}
		let startMinutes : number
		let startHours   : number
		let endMinutes   : number
		let endHours     : number
		let weekSessions : any
		let currentHours : number

		matchingHoursData.map
		(
			(schedule : any)=>
			{
				weekSessions = []

				schedule.sessions.map
				(
					(session : any)=>
					{
						currentHours= session.start_hours

						while(currentHours < session.end_hours)
						{
							startMinutes = (session.start_hours % 10)
							startHours   = (session.start_hours - startMinutes) / 10
							endMinutes   = (session.end_hours % 10)
							endHours     = (session.end_hours - endMinutes) / 10


							agenda[sprintf("DH%d%03d", session.week_day, currentHours)] = schedule.code
							currentHours                                               += 5
						}

						weekSessions.push
						(
							weekDays[session.week_day].substr(0, 2) + sprintf
							(
								" %02d:%02d-%02d:%02d",
								startHours, startMinutes * 6, endHours  , endMinutes * 6
							)
						)
					}
				)

				scheduleMap[schedule.code] = {...schedule, weekSessions : weekSessions}
			}
		)

		let conflictHours : boolean = false
		let hoursToRemove : any     = []

		Object.keys(agenda).map
		(
			(hour : any)=>
			{
				if(props.selectedSchedule.hours[hour])
				{
					conflictHours = true
				}

				if(editableSchedule && (editableSchedule.hours && editableSchedule.hours[hour]))
				{
					hoursToRemove.push(hour)
				}
			}
		)

		if(editableSchedule && hoursToRemove.length > 0)
		{
			let adjustedHours = editableSchedule.hours

			hoursToRemove.map
			(
				(hour : any)=>
				{
					delete adjustedHours[hour]
				}
			)

			setEditableSchedule({...editableSchedule, hours : adjustedHours.hours})
		}

		setScheduleRef(scheduleMap)
		setConflictingHours(conflictHours)
		setReservedHours(agenda)
		setUIStatus(UIState.NORMAL)
	}

	const closeSubDetailPrompt = ()=>
	{
		setEditableSchedule(null)
		props.closeFn()
	}

	const evalSchedHourToggle = (hours : any)=>
	{
		let proceed  : boolean = false
		let hourList : any     = Object
			.keys(hours)
			.map((hour : any) => parseInt(hour.substring(2)))
			.sort((a : number, b: number) => (a - b))

		if(hourList.length < 1)
		{
			proceed = true
		}
		else
		{
			let sessions                : any     = []
			let last                    : any     = sessions.length - 1
			let currentSequence         : any     = null
			let sequence                : any     = null
			let doubleDailySessionFound : boolean = false
			let hourNumber              : number

			for(let hour of hourList)
			{
				sequence = hour;

				if((sequence - 5) != currentSequence)
				{
					if(currentSequence != null && ((sequence - (sequence % 1000)) == (currentSequence - (currentSequence % 1000))))
					{
						doubleDailySessionFound = true
						break
					}

					sequence        = hour;
					currentSequence = sequence;
					hourNumber      = hour % 100

					sessions.push
					({
						weekDay    : (hour - (hour % 1000)),
						startHours : hourNumber,
						endHours   : hourNumber + 5
					})

					last++
				}
				else
				{
					sessions[last].endHours += 5;
					currentSequence         += 5;
				}
			}

			proceed = !doubleDailySessionFound
		}

		return proceed
	}

	//Toggles schedule hours in a particular way since half hours can be handled:--------------------------------------------
	const toggleScheduleHour = (indexPair : any)=>
	{
		let hours = {...editableSchedule.hours}
	////if some of the halves are reserved for another schedule it will just toggle the available half-----------------------
		if(reservedHours[indexPair[0]] || reservedHours[indexPair[1]])
		{
			if(reservedHours[indexPair[0]])
			{
				if(hours[indexPair[1]])
				{
					delete hours[indexPair[1]]
				}
				else
				{
					hours[indexPair[1]] = true
				}
			}
			else
			{
				if(hours[indexPair[0]])
				{
					delete hours[indexPair[0]]
				}
				else
				{
					hours[indexPair[0]] = true
				}
			}
		}
		else
		{
		////Otherwise it will cycle-fill in the next order on each click of the button: Both halves, left half(~:00), right(~:30), empty
			if(hours[indexPair[0]] == null && hours[indexPair[1]] == null)
			{
				hours[indexPair[0]] = true
				hours[indexPair[1]] = true
			}
			else
			{
				if(hours[indexPair[0]] && hours[indexPair[1]])
				{
					delete hours[indexPair[1]]

					if(!evalSchedHourToggle(hours))
					{
						hours = {...editableSchedule.hours}

						delete hours[indexPair[0]]

						if(!evalSchedHourToggle(hours))
						{
							hours = {...editableSchedule.hours}

							delete hours[indexPair[0]]
							delete hours[indexPair[1]]
						}
					}
				}
				else
				{
					if(hours[indexPair[0]])
					{
						delete hours[indexPair[0]]

						hours[indexPair[1]] = true

						if(!evalSchedHourToggle(hours))
						{
							hours = {...editableSchedule.hours}

							delete hours[indexPair[1]]

							if(hours[indexPair[0]])
							{
								delete hours[indexPair[0]]
							}
							else
							{
								hours[indexPair[0]] = true
							}
						}
					}
					else
					{
						delete hours[indexPair[1]]
					}
				}
			}
		}
		////////Instructions like these are meant to evaluate the toggle, restricting multiple (separated) sessions on any single day.
		if(!evalSchedHourToggle(hours))
		{
			hours = {...editableSchedule.hours}
		}

		if(!_.isEqual(hours, editableSchedule.Hours))
		{
			setEditableSchedule({...editableSchedule, hours : hours})
		}
	}

	const secondAction : any = ((props.courseRef && props.selectedSchedule.id == null) && (() => (saveRecord(true)))) || null

	return<DataDisplayModal dataEditInit={editSchedule} saveDataFn={saveRecord} entityName="Horario" UIStatus={UIStatus}
	  promptCloseFn={closeSubDetailPrompt} uneditFn={unedit} headIcon={<CustomIcon name={"schedule"} />} processingError={saveError}
	  editableRecord={editableSchedule} unchangedRecord={unchangedRecord} secondAction={secondAction} saveSuccess={saveSuccess}
	  cornerDismissalCallback={oneBatchSaved ? props.afterCloseFn : null} unfilledFields={unfilledFields} form={recordFormRef}
	  saveLock={state.isButtonDisabled} allowedUpdate={props.allowedUpdate}
	><form ref={recordFormRef} className="form" onSubmit=
	  {
		(event : FormEvent)=>
		{
			event.preventDefault()
			saveRecord()
		}
	  }>
		<div className="container">
			<div className="row">
				<div className="col-md-4 text-center">
					<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
						<img className="relatedPicture" src=
						  {
							(
								editableSchedule == null
										?
									(
										(props.courseRef && props.courseRef["C_" + props.selectedSchedule.course_id].image)
											|| props.selectedSchedule.course.image
									)
										:
									(
										(props.courseRef && props.courseRef["C_" + editableSchedule.course_id].image)
											|| editableSchedule.course.image
									)
							)
								|| courseImage
						  }
						/>
					</div>
					<br />
					<br />
					<div>
						<label style={{position : "relative", textTransform : "uppercase"}}>
							código curso
						</label>
						<div>{
							(props.courseRef && (editableSchedule && props.courseRef["C_" + editableSchedule.course_id].code))
								|| (props.selectedSchedule.course.code || "")
						}</div>
					</div>
					<div>
						<label style={{position : "relative", textTransform : "uppercase"}}>
							código módulo
						</label>
						<div>{
							((props.courseRef && editableSchedule) && (props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id].code))
								|| (props.selectedSchedule.level.code || "")
						}</div>
					</div>
					<div className="card card-default" style={{padding : "15px", marginTop : "15px"}}>
						<div className="selectContainer">
							<label htmlFor="course_id">
								Curso
							</label>
							{
								editableSchedule == null
										?
									<div className="roView">
										<br />
										{
											(props.courseRef && props.courseRef["C_" + props.selectedSchedule.course_id].name)
													|| (props.selectedSchedule.course.name || "")
										}
									</div>
										:
									(
										props.courseRef == null
												?
											<div className="roView">
												<br />
												{
													(props.courseRef && props.courseRef["C_" + props.selectedSchedule.course_id].name)
															|| (props.selectedSchedule.course.name || "-")
												}
											</div>
												:
											<>
												<select name="course_id" value={editableSchedule.course_id} disabled={idleLocked}
												  style={{height : "30px"}} className="btn btn-primary text-start" id="course_id"
												  onChange=
												  {
													(event : any)=>
													{
														setEditableSchedule
														({
															...editableSchedule, level_id : props.courseRef["C_" + event.target.value].levels[0].id,
															course_id                     : event.target.value
														})
													}
												  }
												>{
													Object.values(props.courseRef).map
													(
														(record : any, i : any) => <option key={i} value={record.id}
														  disabled={Object.keys(record.levelRef).length < 1}
														>
															{record.name}
														</option>
													)
												}</select>
												<div className="iconRight" style={{pointerEvents : "none"}}>
													<CustomIcon name="rowDown" />
												</div>
											</>
									)
							}
						</div>
						<div className="selectContainer">
							<label htmlFor="course_id">
								Módulo
							</label>
							{
								editableSchedule == null
										?
									<div className="roView">
										<br />
										{
											(
												props.courseRef && props
													.courseRef["C_" + props.selectedSchedule.course_id]
													.levelRef["L_" + props.selectedSchedule.level_id]
													.name
											)
												|| (props.selectedSchedule.level.name || "-")
										}
									</div>
										:
									(
										props.courseRef == null
											?
											<div className="roView">
												<br />
												{props.selectedSchedule.level.name || "-"}
											</div>
												:
											<>
												<select onChange={handleRegistryChange} id="level_id"
												  style={{height : "30px"}} className="btn btn-primary text-start" name="level_id"
												  value={editableSchedule.level_id} disabled={idleLocked}
												>{
													Object
														.values
														(
															props
																.courseRef["C_" + editableSchedule.course_id]
																.levelRef
														)
														.map
														(
															(record : any, i : any)=>
															<option key={i} value={record.id}>
																{record.name}
															</option>
														)
												}</select>
												<div className="iconRight" style={{pointerEvents : "none"}}>
													<CustomIcon name="rowDown" />
												</div>
											</>
									)
							}
						</div>
						<div className="selectContainer">
							<label htmlFor="status_id">
								Estatus
							</label>
							{
								editableSchedule == null
										?
									<div className="roView">
										<br />
										{props.selectedSchedule.status?.name}
									</div>
										:
									<>
										<select onChange={handleRegistryChange} disabled={idleLocked}
										  className="btn btn-primary text-start" style={{height : "30px", marginTop : "20px"}}
										  name="status_id" value={editableSchedule.status_id} id="status_id"
										>{
											props.scheduleStatuses.map
											(
												(record : any, i : any) => <option key={i} value={record.id}>
													{record.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
							}
						</div>
					</div>
				</div>
				<div className="col-md-8">
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableSchedule == null ? "" : "*"}
								Nombre
							</label>
							{
								editableSchedule == null
										?
									<div className="roView">
										<br />
										{props.selectedSchedule.name}
									</div>
										:
									<input onBlur={validateField} ref={nameRef} id="name"
									  name="name" onChange={handleRegistryChange} placeholder="Nombre"
									  onFocus={validateField} maxLength={50} type="text"
									  value={editableSchedule.name} style={{width : "100%"}} disabled={idleLocked} required
									/>
							}
						</div></div>
						<div className="col-md-6">{
							props.selectedSchedule.id == null ? "" : <div className="inputContainer">
								<label htmlFor="code">
									Código
								</label>
								<div className="roView">
									<br />
									{props.selectedSchedule.code}
								</div>
							</div>
						}</div>
						<div className="col-md-6"><div className="selectContainer" style={{marginBottom : "unset", height : "63px"}}>
							<label htmlFor="country_id">
								Edificio
							</label>
							{
								editableSchedule == null
										?
									<div className="roView">
										<br />
										{
											(props.selectedSchedule.classroom && props.selectedSchedule.classroom.building.name)
												|| "-"
										}
									</div>
										:
									(
										props.buildingRef["B_" + editableSchedule.building_id] == null ? "" : <div
										  style={{position : "relative", width : "100%"}}
										>
											<select
											  id="building_id"
											  name="building_id"
											  ref={campusSelRef}
											  disabled={idleLocked}
											  className="btn btn-primary text-start"
											  value={editableSchedule.building_id}
											  style={{height : "30px", marginTop : "31px"}}
											  onChange=
											  {
												(event : any)=>
												{
													setEditableSchedule
													({
														...editableSchedule, classroom : null,
														building_id                    : event.target.value
													})
													
												}
												
											  }
											>{
												Object.values(props.buildingRef).map
												(
													(building : any) => <option disabled={building.classrooms.length < 1} key={building.id}
													  value={building.id}
													>
														{building.name}
													</option>
												)
											}</select>
											<div className="iconRight" style={{pointerEvents : "none"}}>
												<CustomIcon name="rowDown" />
											</div>
										</div>
									)
							}
						</div></div>
						<div className="col-md-6">
							<label htmlFor="classroom" style={{position : "unset"}}>
								{editableSchedule == null ? "" : "*"}
								Aula
							</label>
							{
								editableSchedule == null
										?
									<div className="roView">
										{props.selectedSchedule.classroom == null ? "-" : props.selectedSchedule.classroom.name}
									</div>
										:
									(
										props.buildingRef["B_" + editableSchedule.building_id] == null ? "" : <Popover
										  positions={["bottom"]} isOpen={noClassroomProvided} content=
										  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
											<div className="requirementAlert">
												<FontAwesomeIcon icon={solid("exclamation")} />
											</div>
											&nbsp;
											Requerido: Elija una opción
										  </div>}
										><div>
											<SelectReact value={editableSchedule.classroom} disabled={idleLocked} id="classroom"
											  placeholder="Aula" className="btn btn-primary text-start" style={{height : "30px"}}
											  children={props.buildingRef["B_" + editableSchedule.building_id].classrooms}
											  onChange={handleRegistryChange} name="classroom"
											/>
										</div></Popover>
									)
							}
							</div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="students_nimimum">
								{editableSchedule == null ? "" : "*"}
								Mínimo de estudiantes
							</label>
							{
								editableSchedule == null
									?
								<div className="roView">
									<br />
									{props.selectedSchedule.students_minimum}
								</div>
									:
								<input value={editableSchedule.students_minimum} type="number" id="students_minimum" step="1"
								  onChange={handleRegistryChange} placeholder="Mínimo de estudiantes" disabled={idleLocked}
								  onFocus={validateField} style={{width : "100%"}} onBlur={validateField} name="students_minimum"
								  min="1" ref={studentsMinimumRef} required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="students_limit">
								{editableSchedule == null ? "" : "*"}
								Máximo de estudiantes
							</label>
							{
								editableSchedule == null
									?
								<div className="roView">
									<br />
									{props.selectedSchedule.students_limit}
								</div>
									:
								<input onChange={handleRegistryChange} name="students_limit" step="1" ref={studentsLimitRef}
								  value={editableSchedule.students_limit} disabled={idleLocked} min="1" style={{width : "100%"}}
								  onFocus={validateField} placeholder="Máximo de estudiantes" onBlur={validateField} type="number"
								  id="students_limits" required
								/>
							}
						</div></div>
						<div className="col-md-6">
							<label htmlFor="period_id" style={{position : "relative"}}>
								Profesor
							</label>
							{
								editableSchedule == null
									?
								<div className="roView">{
									(
										props.selectedSchedule.mentor &&
										((
											props.selectedSchedule.mentor.first_name + " " +
												props.selectedSchedule.mentor.last_name
										))
									)
										||
									"-"
								}</div>
									:
								(
									(
										(
											props.courseRef == null || 
											(
												props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id] == null
													||
												props
													.courseRef["C_" + editableSchedule.course_id]
													.levelRef["L_" + editableSchedule.level_id]
													.mentors
													.length < 1
											)
										)
											&&
										(
											props.selectedSchedule.id && props
												.selectedSchedule
												.level
												.mentors
												.length < 1
										)
									)
											?
										<div className="roView">
											Sin registros
										</div>
											:
										(
											(editableSchedule.classroom == null || editableSchedule.classroom.id) == null
												?
											<div className="roView">&lt;
												Seleccione aula
											&gt;</div>
												:
											(
												editableSchedule.period == null
													?
												<div className="roView">
													&lt;
														Seleccione periodo
													&gt;
												</div>
													:
												(
													(
														props.courseRef && props.courseRef["C_" + editableSchedule.course_id] &&
															props.courseRef["C_" + editableSchedule.course_id].levelRef && 
															props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id] && 
															props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id].mentors &&
															props.courseRef["C_" + editableSchedule.course_id].levelRef["L_" + editableSchedule.level_id].mentors.length > 0
													)
														?
													<SelectReact className="btn btn-primary text-start" clearable={true}
													  style={{height : "30px"}} value={editableSchedule.mentor} id="mentor"
													  onChange={(e : any) => collateMentorSchedule(e)} disabled={idleLocked}
													  placeholder="Profesor" name="mentor" children=
													  {
														props
															.courseRef["C_" + editableSchedule.course_id]
															.levelRef["L_" + editableSchedule.level_id]
															.mentors
													  }
													/>
														:
													(
														(
															props.courseRef == null && props
																.selectedSchedule
																.level
																.mentors
																	&& props
																.selectedSchedule
																.level
																.mentors
																.length > 0
														)
															?
														<SelectReact className="btn btn-primary text-start" clearable={true}
														  value={editableSchedule.mentor} disabled={idleLocked} name="mentor"
														  onChange={(e : any) => collateMentorSchedule(e)} placeholder="Profesor"
														  style={{height : "30px"}} id="mentor" children=
														  {
															props
																.selectedSchedule
																.level
																.mentors
														  }
														/>
															:
														<div className="roView">
															Sin registros
														</div>
													)
												)
										)
									)
								)
							}
						</div>
						<div className="col-md-6">
							<label htmlFor="period_id" style={{position : "relative"}}>
								{editableSchedule == null ? "" : "*"}
								Periodo
							</label>
							{
								editableSchedule == null
									?
								<div className="roView">
									{props.selectedSchedule?.period?.name}
								</div>
									:
								<Popover positions={["bottom"]} isOpen={noPeriodProvided} content=
								  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
									<div className="requirementAlert">
										<FontAwesomeIcon icon={solid("exclamation")}/>
									</div>
									&nbsp;
									Requerido: Elija una opción
								  </div>}
								><div>
									<SelectReact children={props.periods} id="period" className="btn btn-primary text-start"
									  placeholder="Periodo" disabled={idleLocked} style={{height : "30px"}} name="period"
									  onChange={handleRegistryChange} value={editableSchedule.period}
									/>
								</div></Popover>
							}
						</div>
					</div>
					<div className="row">
						<div className="col-md-6"><div className="inputContainer" style={{paddingTop : "23px", textAlign : "left"}}>
							<label htmlFor="start" style={{position : "relative", bottom : "22px"}}>
								Inicio
							</label>
							{
								editableSchedule == null
										?
									<div style={{position : "relative"}}>
										{props.selectedSchedule.start_date == null ? "-" : revertStringDate(props.selectedSchedule.start_date)}
									</div>
										:
									<Popover positions={["bottom"]} isOpen={wrongInterval != null} content=
									  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
										<div className="requirementAlert">
											<FontAwesomeIcon icon={solid("exclamation")}/>
										</div>
										&nbsp;
										{wrongInterval}
									  </div>}
									><div>{
										editableSchedule.period == null || editableSchedule.period.id == null
											? <>&lt;Seleccione periodo&gt;</>
												: <LeDatePicker onChange={handleRegistryChange} value={editableSchedule.startValue}
												  onBlur={validateField} months={monthRef} onFocus={validateField} id="startValue"
												  years={basicInfo.futureRange} name="startValue" required
												/>
									}</div></Popover>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{paddingTop : "23px", textAlign : "left"}}>
							<label htmlFor="end" style={{position : "relative", bottom : "22px"}}>
								Fin
							</label>
							{
								editableSchedule == null
										?
									<div style={{position : "relative"}}>
										{props.selectedSchedule.end_date == null ? "-" : revertStringDate(props.selectedSchedule.end_date)}
									</div>
										:
									(
										editableSchedule.period == null || editableSchedule.period.id == null
											? <>&lt;Seleccione periodo&gt;</>
												: <LeDatePicker onChange={handleRegistryChange} value={editableSchedule.endValue}
													  onBlur={validateField} id="endValue" onFocus={validateField} name="endValue"
													  years={basicInfo.futureRange} months={monthRef} required
													/>
									)
							}
						</div></div>
					</div>
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="billable_months">
								{editableSchedule == null ? "" : "*"}
								Meses a facturar
							</label>
							{
								editableSchedule == null
									?
								<div className="roView">
									<br />
									{props.selectedSchedule.billable_months}
								</div>
									:
								(
									editableSchedule.period == null
											?
										<div className="roView">
											<br />
											&lt;
												Seleccione periodo
											&gt;
										</div>
											:
										<input value={editableSchedule.billable_months} type="number" style={{width : "100%"}}
										  onChange={handleRegistryChange} placeholder="Meses a facturar" id="billable_months"
										  disabled={idleLocked} name="billable_months" step="1" ref={billableMonthsRef}
										  max={limitMonths} onBlur={validateField} min="1" onFocus={validateField} required
										/>
								)
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
							<label htmlFor="subscription_price">
								{editableSchedule == null ? "" : "*"}
								Precio de inscripción
							</label>
							<br />
							<div style={{display : "flex", alignItems : "stretch"}}>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.symbol}
								</div>
								<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
									editableSchedule == null
										?
									<div className="roView" style={{textAlign : "right"}}>
										{sprintf("%.02f", props.selectedSchedule.subscription_price)}
									</div>
										:
									<input onBlur={validateField} name="subscription_price"
									  value={editableSchedule.subscription_price} disabled={idleLocked} id="subscription_price"
									  placeholder="Precio de inscripción" onFocus={validateField}
									  onChange={handleRegistryChange} min={0} type="number" style=
									  {{textAlign : "right", position : "relative", display : "block", height : "23px"}}
									  ref={subscriptionPriceRef} required
									/>
								}</div>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.code}
								</div>
							</div>
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
							<label htmlFor="monthly_billing_price">
								{editableSchedule == null ? "" : "*"}
								Precio de mensualidad
							</label>
							<br />
							<div style={{display : "flex", alignItems : "stretch"}}>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.symbol}
								</div>
								<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
									editableSchedule == null
										?
									<div className="roView" style={{textAlign : "right"}}>
										{sprintf("%.02f", props.selectedSchedule.monthly_billing_price)}
									</div>
										:
									<input onFocus={validateField} type="number"
									  id="monthly_billing_price" onChange={handleRegistryChange}
									  onBlur={validateField} disabled={idleLocked}
									  name="monthly_billing_price" placeholder="Precio de mensualidad" min={0} style=
									  {{textAlign : "right", position : "relative", display : "block", height : "23px"}}
									  value={editableSchedule.monthly_billing_price} ref={monthlyBillingPriceRef} required
									/>
								}</div>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.code}
								</div>
							</div>
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
							<label htmlFor="subscription_surcharge_cost">
								{editableSchedule == null ? "" : "*"}
								Recargo de inscripción
							</label>
							<br />
							<div style={{display : "flex", alignItems : "stretch"}}>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.symbol}
								</div>
								<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
									editableSchedule == null
										?
									<div className="roView" style={{textAlign : "right"}}>
										{sprintf("%.02f", props.selectedSchedule.subscription_surcharge_cost)}
									</div>
										:
									<input placeholder="Costo por recargo de inscripción" id="subscription_surcharge_cost"
									  onBlur={validateField} name="subscription_surcharge_cost"
									  value={editableSchedule.subscription_surcharge_cost} ref={subscriptionSurchargeCostRef}
									  type="number" onChange={handleRegistryChange} min={0} style=
									  {{textAlign : "right", position : "relative", display : "block", height : "23px"}}
									  onFocus={validateField} disabled={idleLocked}
									  required
									/>
								}</div>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.code}
								</div>
							</div>
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
							<label htmlFor="monthly_billing_surcharge_cost">
								{editableSchedule == null ? "" : "*"}
								Recargo de mensualidad
							</label>
							<br />
							<div style={{display : "flex", alignItems : "stretch"}}>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.symbol}
								</div>
								<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
									editableSchedule == null
										?
									<div className="roView" style={{textAlign : "right"}}>
										{sprintf("%.02f", props.selectedSchedule.monthly_billing_surcharge_cost)}
									</div>
										:
									<input onChange={handleRegistryChange} type="number" min={0}
									  value={editableSchedule.monthly_billing_surcharge_cost} id="monthly_billing_surcharge_cost"
									  placeholder="Costo por recargo de mensualidad" name="monthly_billing_surcharge_cost"
									  onBlur={validateField} disabled={idleLocked} style=
									  {{textAlign : "right", position : "relative", display : "block", height : "23px"}}
									  onFocus={validateField} ref={monthlyBillingSurchargeCostRef}
									  required
									/>
								}</div>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.code}
								</div>
							</div>
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
							<label htmlFor="monthly_billing_surcharge_cost">
								{editableSchedule == null ? "" : "*"}
								Segundo recargo
							</label>
							<br />
							<div style={{display : "flex", alignItems : "stretch"}}>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.symbol}
								</div>
								<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
									editableSchedule == null
										?
									<div className="roView" style={{textAlign : "right"}}>
										{sprintf("%.02f", props.selectedSchedule.second_surcharge_cost)}
									</div>
										:
									<input onChange={handleRegistryChange} type="number" min={0}
									  onFocus={validateField} ref={secondSurchargeCostRef}
									  onBlur={validateField} disabled={idleLocked} style=
									  {{textAlign : "right", position : "relative", display : "block", height : "23px"}}
									  value={editableSchedule.second_surcharge_cost} id="second_surcharge_cost"
									  placeholder="Costo de segundo recargo" name="second_surcharge_cost" required
									/>
								}</div>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.code}
								</div>
							</div>
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="first_surcharge_month_day">
								{editableSchedule == null ? "" : "*"}
								Día de primer recargo
							</label>
							{
								editableSchedule == null
									?
								<div className="roView">
									<br />
									{props.selectedSchedule.first_surcharge_month_day}
								</div>
									:
								<input value={editableSchedule.first_surcharge_month_day} min="1" id="first_surcharge_month_day"
								  onChange={handleRegistryChange} name="first_surcharge_month_day" ref={firstSurchargeDayRef}
								  onFocus={validateField} style={{width : "100%"}} placeholder="Día de primer recargo" step="1"
								  max="28" onBlur={validateField} disabled={idleLocked} type="number" required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="second_surcharge_month_day">
								{editableSchedule == null ? "" : "*"}
								Día de segundo recargo
							</label>
							{
								editableSchedule == null
									?
								<div className="roView">
									<br />
									{props.selectedSchedule.second_surcharge_month_day}
								</div>
									:
								<input value={editableSchedule.second_surcharge_month_day} onChange={handleRegistryChange}
								  onFocus={validateField} style={{width : "100%"}} type="number" ref={secondSurchargeDayRef}
								  placeholder="Día de segundo recargo" disabled={idleLocked} id="second_surcharge_month_day"
								  max="28" onBlur={validateField} name="second_surcharge_month_day" step="1" min="1" required
								/>
							}
						</div></div>
					</div>
					<div style={{padding : "15px"}}>
						<UnderlineHeading name={"Notas"} />
						{
							editableSchedule == null
									?
								<p>
									{props.selectedSchedule.notes || "-"}
								</p>
									:
								<textarea onChange={handleRegistryChange} disabled={idleLocked} name="notes" maxLength={255}
								  ref={notesRef} value={editableSchedule.notes} className="notesField form-control" id="notes"
								  onBlur={validateField}
								/>
						}
					</div>
				</div>
			</div>
		</div>
		<UnderlineHeading name={"Gestión"} />
		<div style={{position : "relative"}}>
			{
				!(noHoursProvided || doubleDailySessionProvided) ? "" : <div
				  style={{position : "absolute", zIndex : 1, width : "100%", height : "100%", minWidth : "136px"}}><div
				  className="usageEmphasis"><div style=
				  {{
					position : "relative", width : "100%", height : "100%", top : "0px", display : "flex", flexWrap : "wrap",
					alignContent : "center", padding : "15px", textAlign : "center"
				  }}
				><h4 style={{color : "#FFFFFF", width : "100%"}}>
					[!] Requerido:
					<br />
					{
						noHoursProvided
							?
						"Marque al menos una casilla para generar un horario"
							:
						"No se permiten horarios de clases conformados por sesiones multiples (horas separadas) en un sólo día"
					}
				</h4></div></div></div>
			}
			{
				editableSchedule == null ? "" :
				(
					(editableSchedule.period == null || (editableSchedule.classroom == null || editableSchedule.classroom.id == null))
						?
					<div style=
					  {{
						width         : "100%",
						height        : "413px",
						background    : "#EEEEFE",
						textAlign     : "center",
						verticalAlign : "middle",
						borderRadius  : "15px",
						paddingTop    : "25px"
					  }}
					>
						&lt;
							Seleccione periodo y aula para especificar las horas
						&gt;
					</div>
						:
					<>
						<label style={{position : "relative", wordBreak : "break-word", overflow : "unset", textOverflow : "unset", whiteSpace : "unset"}}>
							Marque las casillas en la semana según corresponda
						</label>
						<hr />
					</>
				)
			}
			{
				!(
					(props.selectedSchedule && editableSchedule == null)
						||
					(editableSchedule && (editableSchedule.period && (editableSchedule.classroom && editableSchedule.classroom.id)))
				) ? "" : <table style={{width : "100%", fontSize : "12px"}}>
					<tbody><tr style={{color : "#FFFFFF", fontWeight : "200"}}>
						<td className="text-end" />
						<td className="d-none d-sm-table-cell" />
						<td className="d-none d-lg-table-cell" />
						{
							weekDays.map
							(
								(item :  any, index : number) => <td key={"d" + index} style=
								  {{
									textAlign : "center", borderWidth : "1px",
									borderColor : "#FFFFFF"
								  }}
								><div style={{background : "#8F91DA", borderRadius : "3px", display : "flex"}}>
									<span>
										{item.substr(0, 2)}
									</span>
									<span className="d-none d-lg-inline-block">
										{item.substr(2)}
									</span>
								</div></td>
							)
						}
					</tr></tbody>
					{
						dayHours.map
						(
							(hour : number) => <tbody key={"h" + hour}><tr>
								<td className="text-end scheduleHeading" style=
								  {{
									borderWidth : "1px 0px 1px 1px", color : "#4A4B80", padding : "5px 0px 0px 5px"
								  }}
								>
									<div style={{borderRadius : "5px 0px 0px 5px"}}>
										{sprintf("%02d", hour)}
									</div>
								</td>
								<td className="d-none d-sm-table-cell scheduleHeading" style=
								  {{
									borderWidth : "1px 0px 1px 0px", color : "#4A4B80", paddingTop : "5px", width : "1px"
								  }}
								>
									<div>
										:00
									</div>
								</td>
								<td className="d-none d-lg-table-cell scheduleHeading" style=
								  {{
									borderWidth : "1px 1px 1px 0px", color : "#4A4B80", padding : "5px 5px 0px 0px"
								  }}
								>
									<div style={{borderRadius : "0px 5px 5px 0px"}}>
										{sprintf("-%02d:00", hour + 1)}
									</div>
								</td>
								{
									weekDays.map
									(
										(day : string, dayIndex : number)=>
										{
											let timeMarks : any=
											[
												sprintf("DH%d%03d", dayIndex,  hour * 10),
												sprintf("DH%d%03d", dayIndex, (hour * 10) + 5)
											]

											return <td
											  style={{border : "1px solid #F9F9F9", width : "13%"}}
											  key={"x" + timeMarks[0]}
											>{
												editableSchedule == null || (reservedHours[timeMarks[0]] && reservedHours[timeMarks[1]])
													?
												timeMarks.map
												(
													(timeMark : string, half : number)=>
													(
														reservedHours[timeMark] == null
																?
															(
																props.selectedSchedule.hours[timeMark] == null ? "" : <div
																  key={"d" + timeMark}
																  className={"chosen sideSlot " + side[half]}
																/>
															)
																:
															(
																(
																	props.selectedSchedule.mentor_id &&
																	(
																		(
																			props.selectedSchedule.level.mentorRef
																				["M_" + props.selectedSchedule.mentor_id]
																					[timeMark]
																				&&
																			props.selectedSchedule.level.mentorRef
																				["M_" + props.selectedSchedule.mentor_id]
																					[timeMark]
																		)
																			&&
																		props.selectedSchedule.level.mentorRef
																			["M_" + props.selectedSchedule.mentor_id]
																				[timeMark] != props.selectedSchedule.id
																	)
																)
																		?
																	<div
																	  key={"d" + timeMark}
																	  className={"mixed sideSlot " + side[half]}
																	/>
																		:
																	<OverlayTrigger overlay=
																	  {<Tooltip id="tooltip-activos" className="matchingSchedule">
																		<div>
																			{scheduleRef[reservedHours[timeMark]].level.course.name}
																			<br />
																			{scheduleRef[reservedHours[timeMark]].level.name}
																			<br />
																			{scheduleRef[reservedHours[timeMark]].name}
																			&nbsp;
																			[{scheduleRef[reservedHours[timeMark]].code}]
																			<div style={{paddingTop : "8px"}}>
																				{scheduleRef[reservedHours[timeMark]].classroom.building.name}
																				<br />
																				{scheduleRef[reservedHours[timeMark]].classroom.name}
																			</div>
																			<div style={{paddingTop : "8px"}}>{
																				scheduleRef[reservedHours[timeMark]].mentor == null
																						?
																					"-Sin profesor asignado-"
																						:
																					"Profesor: " +  scheduleRef[reservedHours[timeMark]].mentor.first_name + " "+
																					scheduleRef[reservedHours[timeMark]].mentor.last_name
																			}</div>
																			<div style={{paddingTop : "8px"}}>{
																				scheduleRef[reservedHours[timeMark]].sessions == null ? "" : scheduleRef[reservedHours[timeMark]].weekSessions.map
																				(
																					(session : any) => <div key={"S_" + session}>
																						{session}
																					</div>
																				)
																			}</div>
																		</div>
																	  </Tooltip>}
																	>
																		<div
																		  key={"d" + timeMark}
																		  className=
																		  {
																			"reserved sideSlot " + side[half] +
																			((half > 0 || reservedHours[0] == reservedHours[1]) ? "" : " end")
																		  }
																		/>
																	</OverlayTrigger>
															)
													)
												)
													:
												<button
												  type="button"
												  disabled={idleLocked}
												  style={{width : "100%", padding : "unset"}}
												  onClick={() => toggleScheduleHour(timeMarks)}
												  className="btn btn-default"
												>{
													timeMarks.map
													(
														(timeMark : string, half : number) => <span
														  key={"s" + timeMark}
														  style=
														  {{
															display : "inline-block", width : "50%", height : "25px",
															verticalAlign : "bottom"
														  }}
														  className=
														  {
															"sideSlot " + side[half] + " " +
															(
																reservedHours[timeMark] == null
																	?
																(
																	editableSchedule == null ||
																		(editableSchedule.hours == null || editableSchedule.hours[timeMark] == null)
																		?
																	""
																		:
																	"chosen"
																)
																	:
																(
																	editableSchedule.mentor_id &&
																	(
																		(
																			editableSchedule.level.mentorRef
																				["M_" + editableSchedule.mentor_id]
																					&&
																			editableSchedule.level.mentorRef
																				["M_" + editableSchedule.mentor_id]
																					[timeMark]
																		)
																			&&
																		editableSchedule.level.mentorRef
																			["M_" + editableSchedule.mentor_id]
																				[timeMark] != props.selectedSchedule.id
																	)
																		? "mixed" : "reserved"
																)
															)
														  }
														/>
													)
												}</button>
											}</td>
										}
									)
								}
							</tr></tbody>
						)
					}
				</table>
			}
		</div>
	</form></DataDisplayModal>
}

export default SchedulePrompt
