const container =
{
    flexGrow: "1",
    minWidth: "100px",
    maxWidth: "100%",
    flexFlow: "wrap"
}

const card =
{
    borderRadius: 5,
    padding: "20px 0px",
    marginBottom: 0,
    minWidth: "100%",
    width: "100%"
}

export const styles =
{
    container, 
    card
}